@import '../../../../variables';

.detectiveCore {
  overflow-x: hidden;
  overflow-y: visible;
  height: 100vh;

  @media screen and (pointer: coarse) {
    height: calc(100vh - 60px);
  }

  header {
    height: 100px;
    background-color: $lightbackground;
  }
}

.margeSign {
  border: 1px solid $margecolor;
  height: 100vh;
  position: fixed;
  left: 3%;
}

.mainLayout {
  position: relative;
  top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  height: 80vh;
}

.artwork {
  flex: 0 0 65%;
  z-index: 2;
}

@keyframes showElement {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.show {
  animation: showElement 3s;
}

.rightColumn {
  flex: 0 0 30%;
  font-family: "Leftover Crayon", sans-serif;
  font-size: 26px;
  box-sizing: border-box;
  padding: 30px;
  text-transform: uppercase;
  color: $crayondarkgrey;
  height: 100%;
}

.counterComponent {
  position: absolute;
  top: 40px;
  left: 10%;
  font-size: 48px;
}

.image {
  width: 185px;
}

.imageFish {
  width: 200px;
}

.imageDog1 {
  width: 240px;
}

.imageSnail {
  margin: 20px 0;
  width: 300px;
}

.imageSnake {
  width: 170px;
}

.imageHorse {
  width: 320px;
  margin: 20px 0 10px;
}

.imageGoat {
  width: 220px;
}

.imagePig {
  width: 340px;
  margin: 10px 0;
}

.imageDog2 {
  width: 220px;
  margin-bottom: 10px;
}

.imageRat {
  width: 320px;
  margin: 20px 0;
}