@import '../../../../variables';

.wrapper {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 100vh;

  header {
    height: 100px;
    background-color: $lightbackground;
  }

  // We use coarse to determine tablet here and use corrected viewport height for the browser's chrome
  @media screen and (max-width: $desktopWidth) and (pointer: coarse) {
    height: calc(100vh - 65px);
  }
}

.margeSign {
  position: absolute;
  left: 3%;
  height: 100vh;
  border: 1px solid $margecolor;
}

.hideLayer {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: hide-layer 4s;
  opacity: 0;
  background-color: $black;
}

.layerText {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 28px;
  position: absolute;
  top: 26%;
  left: 10%;
  text-transform: uppercase;
  color: $lightbackground;

  @media screen and (min-width: $desktopWidth) {
    font-size: 44px;
  }
}

@keyframes hide-layer {
  0% {
    opacity: 0;
    z-index: 999;
  }
  100% {
    opacity: .9;
  }
}
