@import '../../variables';

.wrapper {
  background-color: $mediumBackColor;
  padding: $navigtaionTopBarHeight 0 100px;
  @media screen and (min-width: $desktopWidth) {
    padding: $navigtaionTopBarHeight 0 140px;
    background-image: url('./assets/ThreadFAQ.png');
    background-repeat: no-repeat;
    background-size: 86% 80%;
    background-position: 0 80%;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    padding: $navigtaionTopBarHeight 60px 140px;
  }
}

.faqContent {
  max-width: 1440px;
  margin: 0 auto;
}

.bubble {
  font-family: "Leftover Crayon", sans-serif;
  text-transform: uppercase;
  color: $crayondarkgrey;
  font-size: 18px;
  margin: 0;
  box-sizing: border-box;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    font-size: 28px;
    line-height: 31px;
  }
  @media screen and (min-width: $tabletWidth) {
    font-size: 24px;
  }
}

.card {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}

.box {
  width: 320px;
  margin: 0 auto;
  @media screen and (min-width: $tabletWidth) {
    width: 760px;
  }
}

.navigationBox {
  height: 80px;
}

.headerText {
  font-size: 64px;
  line-height: 69px;
  font-family: "Leftover Crayon", sans-serif;
  font-weight: normal;
}

.boarBox {
  @extend .box;
  padding-top: 20px;
  @media screen and (min-width: $tabletWidth) {
    padding-top: 40px;
    width: 760px;
  }
  @media screen and (min-width: $desktopWidth) {
    position: relative;
    padding-top: 100px;
    display: inline-block;
    width: 50%;
  }
}

.boar {
  width: 320px;
  height: 280px;
  margin-top: -40px;
  @media screen and (min-width: $tabletWidth) {
    width: 570px;
    height: 500px;
  }
  @media screen and (min-width: $desktopWidth) {
    display: none;
  }
}

.boarBig {
  display: none;
  @media screen and (min-width: $desktopWidth) {
    display: block;
    position: absolute;
    bottom: 0;
    right: -170px;
    width: 290px;
  }
}

.boarBubble {
  @extend .bubble;
  width: 206px;
  height: 160px;
  position: absolute;
  margin-left: 110px;
  padding: 0 10px 20px 104px;
  @media screen and (min-width: $tabletWidth) {
    margin-left: 250px;
    padding: 36px 0 20px 140px;
    width: 306px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 280px;
    height: 280px;
    padding-top: 66px;
    padding-left: 110px;
    position: absolute;
    right: -180px;
    bottom: 230px;
    background-image: url('assets/BoarBubble.png');
  }
}

.boarCard {
  @extend .card;
  background-image: url('assets/BoarCard.svg');
  width: 310px;
  height: 666px;
  margin-top: -124px;
  padding: 0 40px;
  h2 {
    padding-top: 70px;
  }
  @media screen and (min-width: $tabletWidth) {
    background-image: url('assets/BoarCardBig.svg');
    width: 760px;
    height: 716px;
    padding: 0 120px;
  }
  @media screen and (min-width: $desktopWidth) {
    background-image: url('assets/BoarCardBig.svg');
    width: 700px;
    height: 716px;
    padding: 0 120px;
    h2 {
      padding-top: 90px;
    }
  }
}

.horseBox {
  @extend .box;
  position: relative;
  @media screen and (min-width: $tabletWidth) {
    display: flex;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 50%;
    display: inline-block;
  }
}

.horse {
  width: 244px;
  height: 328px;
  @media screen and (min-width: $tabletWidth) {
    width: 340px;
    height: 456px;
    z-index: 1;
    margin-left: 40px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 280px;
    height: 406px;
    position: absolute;
    left: 100px;
    bottom: -290px;
  }
}

.horseBubble {
  @extend .bubble;
  width: 180px;
  position: absolute;
  top: 30px;
  left: 40px;
  @media screen and (min-width: $tabletWidth) {
    width: 270px;
    top: 44px;
    left: 94px;
    z-index: 2;
  }
  @media screen and (min-width: $desktopWidth) {
    top: -80px;
    left: 190px;
    width: 210px;
  }
}

.horseCard {
  background-image: url('./assets/HorseCard.svg');
  @extend .card;
  width: 310px;
  height: 270px;
  margin-top: -122px;
  padding: 0 44px;
  h2 {
    padding-top: 34px;
    margin-bottom: -8px;
  }
  @media screen and (min-width: $tabletWidth) {
    width: 470px;
    height: 458px;
    padding: 0 60px;
    margin-top: 50px;
    margin-left: -170px;
    z-index: 0;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 440px;
    height: 418px;
    margin-right: 0;
    margin-left: 0;
    position: absolute;
    right: 0;
    bottom: -340px;
  }
}

.goatBox {
  @extend .box;
  @media screen and (min-width: $tabletWidth) {
    display: flex;
    padding-top: 100px;
    margin-right: 40px;
    margin-left: -40px;
  }
  @media screen and (min-width: $desktopWidth) {
    display: inline-block;
    position: relative;
  }
}

.goat {
  width: 210px;
  height: 372px;
  @media screen and (min-width: $tabletWidth) {
    width: 290px;
    height: 514px;
    margin-left: 60px;
    z-index: 1;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 260px;
    height: 480px;
    position: absolute;
    top: 130px;
    left: 76px;
  }
}

.goatBubble {
  @extend .bubble;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 200px;
  height: 114px;
  padding: 26px 0 0 10px;
  position: absolute;
  @media screen and (min-width: $tabletWidth) {
    z-index: 2;
    padding: 26px 0 0 80px;
    width: 330px;
  }
  @media screen and (min-width: $desktopWidth) {
    padding: 50px 0 0 160px;
    width: 380px;
  }
}

.goatCard {
  background-image: url('assets/GoatCard.svg');
  @extend .card;
  width: 298px;
  height: 314px;
  margin: -154px auto 0;
  padding: 0 20px;
  h2 {
    padding-top: 98px;
  }
  @media screen and (min-width: $tabletWidth) {
    width: 458px;
    height: 504px;
    padding: 0 40px;
    margin-top: -60px;
    margin-right: 46px;
    margin-left: -46px;
    h2 {
      padding-top: 120px;
    }
  }
  @media screen and (min-width: $desktopWidth) {
    width: 418px;
    height: 460px;
    margin-top: -10px;
    margin-left: 350px;
    margin-right: 0;
  }
}

.dogBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: $desktopWidth) {
    display: inline-block;
    position: relative;
  }
}

.dogs {
  width: 310px;
  height: 380px;
  background-image: url('./assets/Dogs_mobile.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/Dogs_tablet.png');
    width: 584px;
    height: 474px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 534px;
    height: 424px;
    position: absolute;
    bottom: -840px;
    left: -100px;
  }
}

.dogsBubbleLeft {
  @extend .bubble;
  position: absolute;
  top: 148px;
  left: auto;
  right: auto;
  width: 310px;
  padding: 0 120px 0 26px;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    width: 584px;
    padding: 56px 330px 0 0;
  }
  @media screen and (min-width: $desktopWidth) {
    font-size: 22px;
    line-height: 26px;
    top: 578px;
    left: -68px;
    z-index: 11;
    padding: 12px 120px 0 0;
    width: 300px;
  }
}

.dogsBubbleRight {
  @extend .bubble;
  position: absolute;
  top: 34px;
  left: auto;
  right: auto;
  width: 296px;
  padding: 0 36px 0 136px;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    width: 584px;
    padding: 40px 46px 0 282px;
  }
  @media screen and (min-width: $desktopWidth) {
    font-size: 22px;
    line-height: 26px;
    top: 442px;
    left: -120px;
    z-index: 11;
    width: 554px;
  }
}

.dogsCard {
  background-image: url('./assets/DogsCard.svg');
  @extend .card;
  width: 310px;
  height: 324px;
  padding: 0 40px 0 34px;
  h2 {
    margin-top: 0;
    margin-bottom: -10px;
    padding-top: 48px;
  }
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/DogsCardBig.svg');
    width: 608px;
    height: 504px;
    padding: 0 88px 0 80px;
    h2 {
      padding-top: 100px;
    }
  }
  @media screen and (min-width: $desktopWidth) {
    width: 558px;
    height: 454px;
    position: absolute;
    bottom: -400px;
    left: 100px;
  }
}

.postitCard {
  background-image: url('./assets/PostitCard.svg');
  @extend .card;
  width: 310px;
  height: 494px;
  padding: 0 32px 0 40px;
  h2 {
    margin-top: 10px;
    padding-top: 60px;
  }
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/PostitCardBig.svg');
    width: 708px;
    height: 714px;
    padding: 0 82px 0 94px;
    h2 {
      padding-top: 130px;
    }
  }
  @media screen and (min-width: $desktopWidth) {
    margin-left: 0;
    margin-top: 100px;
    width: 668px;
    height: 674px;
  }
}

.mapBoxFaq {
  @media screen and (min-width: $tabletWidth) {
    margin-top: 20px;
  }
}

.topLinkSection {
  text-align: center;
  margin-top: 5em;
  padding-bottom: 0;
}

.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}
