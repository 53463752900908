@import '../../variables.scss';

.button {
  font-family: 'Leftover Crayon', sans-serif;
  font-size: 1.2rem;
  width: 250px;
  height: 71px;
  padding-bottom: 2%;
  text-transform: uppercase;
  color: $crayonblue;
  border: none;
  background-color: transparent;
  background-image: url('./button.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  // retina:
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    background-image: url('./button@2x.png');
  }
}

.button:not(.active) {
  opacity: 0.5;
}