@import '../../variables';

// commons
$wrapperPadding: 40px;
$maxTabletWidth: $desktopWidth - 1;
$bigMobileWidth: 520px;

.imgBackground {
  background-color: transparent;
  background-image: url('./assets/mobile/card-and-tape-mobile.svg');
  background-position: bottom right;
  background-repeat: no-repeat;
}

// html elements
.container {
  background-color: $lightyellowbackground;
}

.introText {
  box-sizing: border-box;
  padding: 30px 10px 10px 25px;
  position: relative;
  line-height: 1.5em;
  text-align: center;
  background-color: transparent;
  background-image: url('./assets/mobile/bgd-mobile.svg');
  background-position: top center;
  background-repeat: no-repeat;
  width: 303px;
  height: 301px;
  margin: 0 auto 1.4em auto;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/bgd.svg');
    padding: 35px 20px 20px 60px;
    font-size: 18px;
    width: 746px;
    height: 230px;
  }
}

.subTitle {
  @extend .headerBase;
}

.subpages {
  @extend .displayFlex;
  width: 100%;

  @media screen and (min-width: $desktopWidth) {
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 96%;
  }
}

.subpages > div {
  width: 100%;
  margin-bottom: 10px;

  position: relative;

  @media screen and (min-width: $desktopWidth) {
    flex-basis: 30%;
  }
}

.imgContainer {
  @extend .imgBackground;
  background-size: 80% auto;
  text-align: center;
  padding-right: 3em;
  padding-bottom: 30%;
  position: relative;
  max-width: 455px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $bigMobileWidth) {
    padding-right: 4em;
    padding-bottom: 24%;
  }
  @media screen and (min-width: $tabletWidth) {
    padding-right: 4em;
    padding-bottom: 17%;
  }
  @media screen and (min-width: $desktopWidth) {
    padding-right: 3em;
    padding-bottom: 30%;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 140px;
  }
}

.subpages img {
  max-width: 100%;
  width: 420px;
  height: auto;
  object-fit: contain;

  @media screen and (min-width: $tabletWidth) {
    width: 510px;
  }
}

.imgLink {
  @extend .headerBase;
  position: absolute;
  display: block;
  width: fit-content;
  right: 22%;
  bottom: 18%;
  background-image: url('./assets/mobile/button-background.svg');
  background-size: 143px auto;
  background-position: top left;
  background-repeat: no-repeat;
  font-size: 18px;
  padding: 10px 20px;
  text-decoration: none;

  @media screen and (min-width: $bigMobileWidth) {
    right: 25%;
  }
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/button-background.svg');
    background-size: 171px auto;
    right: 22%;
    font-size: 20px;
  }
  @media screen and (min-width: $desktopWidth) {
    right: 22%;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    right: 6em;
    bottom: 70px;
  }
}
