@import '../../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}


.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  z-index: 100;
}

.bubble1 {
  position: absolute;
  z-index: 40;
  display: block;
  left: 50%;
  top: 50%;
}

.bubble {
  left: 45%;
  bottom: 52%;
  z-index: 60;
  position: absolute;
  width: 60%;
  display: flex;
  align-items: center;
  min-width: 150px;

  @media (min-width: $tabletWidth) {
    bottom: 65%;
    left: 65%;
    width: 60% !important;
  }
}


.blanketArea {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  min-height: 800px;
  max-height: 800px;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 50%;
    height: auto;
    min-height: unset;
    max-height: unset;
  }

  .blanket {
    background-repeat: no-repeat;
    background-image: url('../assets/bg.png');
    background-position: center center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    max-height: 800px;

    @media screen and (min-width: $tabletWidth) {
      height: 100%;
      width: 100%;
      background-image: url('../assets/desktop/bg.png');
      background-size: cover;
      max-height: unset;
    }
  }

  &.Intro {
    .rhino {
      width: 60%;
      left: 50%;
      transform: translateX(-15%) translateY(52%);
      bottom: 48%;
      position: absolute;
      z-index: 30;
      min-width: 300px;

      @media screen and (min-width: $tabletWidth) {
        width: 35%;
        left: 50%;
        bottom: 4.5%;
        transform: translateX(calc(-50% + 52%)) ;
        max-width: unset;
      }
    }

    .bird {
      width: 32%;
      left: 50%;
      transform: translateX(-150%) translateY(150%);
      bottom: 47.5%;
      position: absolute;
      z-index: 30;
      min-width: 150px;

      @media screen and (min-width: $tabletWidth) {
        bottom: 14%;
        left: 27.5%;
        top: auto;
        width: 20%;
        transform: none;
      }
    }

  }

  &.Outro {
    .rhino {
      width: 176px;
      bottom: 40%;

      @media screen and (max-width: $mobileWidth) {
        bottom: 50%;
      }
      @media screen and (min-width: $tabletWidth) {
        left: 12%;
        width: 30%;
        max-width: 474px;
      }
    }

    .puppy {
      width: 140px;
      bottom: 20%;

      @media screen and (min-width: $tabletWidth) {
        left: 65%;
        bottom: 40%;
        width: 17.5%;
        max-width: 262px;

      }
    }
  }
}

.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}
