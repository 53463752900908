@import '../../../../../../variables';

.pointsCounter {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 24px;
  position: relative;
  flex: 0 0 85px;
  height: 55px;
  text-align: center;
  color: $crayondarkgrey;

  @media screen and (min-width: $tabletWidth) {
    height: 88px;
  }

  @media screen and (min-width: $desktopWidth) {
    flex: 0 0 190px;
    height: 110px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    &.cpu {
      flex-flow: row-reverse;
    }
  }
}

.pointsCounter:not(.active) {
  opacity: .5;
}

.avatar {
  position: absolute;
  top: 4px;
  left: 0;
  width: 44px;
  height: 54px;
  transform: scale(1.075);

  @media screen and (min-width: $tabletWidth) {
    height: 86px;
    width: 70px;
    transform: scale(1.075) scaleX(1.015);
  }

  @media screen and (min-width: $desktopWidth) {
    width: 88px;
    height: 108px;
    transform: scale(1.077) scaleX(1.025);
  }

  &.user {
    left: 6px;
    @media screen and (min-width: $desktopWidth) {
      left: 37px;
      top: 2px;
    }
  }
  &.cpu {
    right: 0;
    left: auto;
    @media screen and (min-width: $desktopWidth) {
      right: 25px;
      top: 3px;
    }
  }
}

.avatarStatic {
  position: absolute;
  left: 0;
  top: 2px;
  width: 40px;
  height: 54px;
  background-position: 0 0;
  background-size: 89px 106px;
  background-image: url('./avatars_static.png');

  @media screen and (min-width: $tabletWidth) {
    width: 64px;
    height: 86px;
    background-size: 142px 168px;
  }

  @media screen and (min-width: $desktopWidth) {
    width: 80px;
    height: 108px;
    background-size: 178px 212px;
  }

  &.user {
    left: 5px;

    @media screen and (min-width: $desktopWidth) {
      left: 35px;
      top: -1px;
    }

    &.active {
      background-position: -44px 0;

      @media screen and (min-width: $tabletWidth) {
        background-position: -71px 0;
      }

      @media screen and (min-width: $desktopWidth) {
        background-position: -88px 0;
      }
    }
  }

  &.cpu {
    left: unset;
    right: 5px;
    background-position: 0 53px;

    @media screen and (min-width: $tabletWidth) {
      background-position: 0 85px;
    }

    @media screen and (min-width: $desktopWidth) {
      background-position: 0 106px;
      right: 35px;
      top: -1px;
    }

    &.active {
      background-position: -44px 53px;

      @media screen and (min-width: $tabletWidth) {
        background-position: -71px 0;
      }

      @media screen and (min-width: $desktopWidth) {
        background-position: -88px 106px;
      }
    }
  }
}

.score {
  width: 35px;
  height: 35px;
  background-image: url('./empty_frames_spritesheet.png');
  background-position: 0 0;
  background-size: 140.4px 35px;
  position: absolute;

  @media screen and (min-width: $tabletWidth) {
    width: 57px;
    height: 57px;
    background-size: 228.6px 57px;
    font-size: 48px;
    line-height: 1;
  }

  @media screen and (min-width: $desktopWidth) {
    width: 70px;
    height: 70px;
    background-size: 280.8px 70px;
    line-height: 1.4;
  }

  &.user {
    top: 14px;
    right: 5px;

    @media screen and (min-width: $tabletWidth) {
      top: 18px;
      right: -42px;
    }

    @media screen and (min-width: $desktopWidth) {
      top: unset;
      right: 5px;
    }
  
    &.active {
      background-position: -35px 0;
      padding-top: 6px;
      box-sizing: border-box;

      @media screen and (min-width: $tabletWidth) {
        background-position: -57px 0;
      }

      @media screen and (min-width: $desktopWidth) {
        background-position: -70px 0;
      }
    }
  }

  &.cpu {
    top: 12px;
    left: 4px;
    background-position: 70px 0;
    padding-top: 6px;
    box-sizing: border-box;


    @media screen and (min-width: $tabletWidth) {
      top: 17px;
      left: -44px;
      background-position: 114px 0;
    }

    @media screen and (min-width: $desktopWidth) {
      top: unset;
      left: 4px;
      background-position: 140px 0;
    }

    &.active {
      background-position: 35px 0;

      @media screen and (min-width: $tabletWidth) {
        background-position: 57px 0;
      }

      @media screen and (min-width: $desktopWidth) {
        background-position: 70px 0;
      }
    }
  }
}