@import '../../../../../../variables.scss';

.quizPeep {
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 0;
  box-sizing: border-box;
  width: 50vw;
  height: auto;
  opacity: 1;


  @media screen and (min-width: $tabletWidth) {
    width: 17vw;
    left: unset;
    right: 0;
    transform: scaleX(-1) translateY(-50%);
    top: 60%;
  }
}