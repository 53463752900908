@import '../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}

.bubble {
  position: absolute;
  right: 5%;
  top: 10%;
  display: flex;
  align-items: center;
  z-index: 50;
  height: 200px !important;
  max-height: 200px !important;

  @media screen and (min-width: $tabletWidth) {
    top: auto;
    left: 55%;
    right: auto;
    bottom: 55%;
    height: 250px !important;
    max-height: 250px !important;
  }
}

.backgroundArea {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 150%;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 100%;
    height: auto;
    min-height: unset;
    max-height: unset;
    overflow: hidden;
  }

  @media screen and (min-width: $desktopWidth) {
    padding-bottom: 48%;
  }

  .background {
    background-repeat: no-repeat;
    background-image: url('./assets/bg.png');
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 5;
    margin: 0 auto;


    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/bg.png');
      background-position: center top;
      background-size: contain;
      height: 100%;
      width: 100%;
      max-height: unset;
    }
  }

  &.Outro {
    .rhino {
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 20;

      @media screen and (min-width: $tabletWidth) {
        width: 67.2%;
        left: 50%;
        transform: translateX(-50%);
      }

      @media screen and (min-width: $desktopWidth) {
        width: 32.3%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}

