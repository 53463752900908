@import '../../../../../variables';

.spritesheet {
  width: 700px;
  position: absolute;
  margin: auto;
  bottom: 100px;
  right: 0;
  left: 0;
  @media screen and (min-width: $bigDesktopWidth) {
    left: -260px;
    & div{
      width: 980px;
      & div {
        width: 800px!important;
      }
    }
  }
}

.bubble { // TODO use Bubble component
    left: 230px;
    top: -10px;
    transform: translateY(-50%);
    z-index: 8;

    @media screen and (min-width: $tabletWidth) {
      left: 350px;
    }

    @media screen and (min-width: $bigDesktopWidth) {
      top: 0;
      left: 500px;
    }
  position: absolute;
}

// drawings clouds:
.cloudTiny {
  position: absolute;
  width: 130px;
  top: 20%;
  left: 34%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 184px;
  }
}

.cloudSmall {
  position: absolute;
  width: 116px;
  top: 14%;
  left: 20%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 156px;
  }
}

.cloudMedium {
  position: absolute;
  width: 180px;
  top: 14%;
  right: 10%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 250px;
  }
}

.cloudLarge {
  position: absolute;
  width: 170px;
  top: 26%;
  left: 4%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 240px;
  }
}

// drawings grass:

.grassSmall {
  position: absolute;
  width: 160px;
  bottom: 15%;
  left: 5%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 200px;
  }
}

.grassMedium {
  position: absolute;
  width: 90px;
  bottom: 26%;
  right: 8%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 140px;
    bottom: 24%;
    right: 6%;
  }
}

.grassLarge {
  position: absolute;
  width: 90px;
  bottom: 5%;
  left: 31%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 120px;
  }
}

// drawings flowers:

.flowerTulip {
  position: absolute;
  right: 33%;
  bottom: 6%;
  width: 41px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 61px;
    bottom: 4%;
  }
}

.flowerViolet {
  position: absolute;
  right: 4%;
  bottom: 12%;
  width: 43px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 61px;
  }
}

.flowerBig {
  position: absolute;
  bottom: 4%;
  left: 17%;
  width: 69px;
  transform: rotateY(180deg);
  @media screen and (min-width: $bigDesktopWidth) {
    width: 95px;
  }
}

.flowerDaisy {
  position: absolute;
  width: 23px;
  left: 22%;
  bottom: 23%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 35px;
    bottom: 21%;
    left: 20%;
  }
}

.flowerLavender {
  position: absolute;
  width: 31px;
  bottom: 0;
  right: 18%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 45px;
    right: 16%;
  }
}

.flowerSowThistle {
  position: absolute;
  width: 63px;
  bottom: 30%;
  right: 16%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 85px;
    right: 15%;
    bottom: 27%;
  }
}

.flowerMarigold {
  position: absolute;
  bottom: 4%;
  width: 41px;
  left: 46%;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 55px;
  }
}