@import '../../../../../../variables';

.helperSign {
  background-image: url("./circlered.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 0;
}

.artwork1 {
  bottom: -14px;
  right: 70px;
  width: 110px;
  height: 90px;
  @media screen and (min-width: $bigDesktopWidth) {
    right: 90px;
    bottom: -20px;
    width: 150px;
    height: 150px;
  }
}

.artwork2 {
  left: 39%;
  top: 31%;
  width: 90px;
  height: 90px;
  @media screen and (min-width: $bigDesktopWidth) {
    left: 40%;
    width: 120px;
    height: 120px;
  }
}

.artwork3 {
  bottom: 45%;
  left: 34%;
  width: 80px;
  height: 80px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 100px;
    height: 100px;
  }
}

.artwork4 {
  bottom: 2%;
  left: 16%;
  width: 350px;
  height: 250px;
  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 4%;
    left: 19%;
    width: 400px;
    height: 300px;
  }
}

.artwork5 {
  bottom: 8%;
  right: 16%;
  width: 130px;
  height: 110px;
  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 7%;
    right: 17%;
    width: 150px;
    height: 150px;
  }
}

.artwork6 {
  left: 20%;
  bottom: -1%;
  width: 80px;
  height: 80px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 100px;
    height: 100px;
  }
}

.artwork7 {
  left: 35%;
  bottom: 30%;
  height: 120px;
  width: 200px;
  @media screen and (min-width: $bigDesktopWidth) {
    height: 150px;
    width: 250px;
    left: 37%;
  }
}

.artwork8 {
  left: 2%;
  bottom: 12%;
  height: 110px;
  width: 200px;
  @media screen and (min-width: $bigDesktopWidth) {
    height: 150px;
    width: 250px;
    left: 4%;
  }
}

.artwork9 {
  left: 19%;
  top: 1%;
  height: 90px;
  width: 150px;
  @media screen and (min-width: $bigDesktopWidth) {
    left: 22%;
    top: -1%;
    height: 150px;
  }
}

.artwork10 {
  right: 25%;
  bottom: 17%;
  height: 120px;
  width: 200px;
  transform: rotate(20deg);
  @media screen and (min-width: $bigDesktopWidth) {
    height: 150px;
    width: 250px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .9;
  }
  60% {
    opacity: .3;
  }
  70% {
    opacity: .9;
  }
  80% {
    opacity: .3;
  }
  100% {
    opacity: 1;
  }
}

.showHelp {
  animation: show 2s;
  opacity: 1;
}