@import '../../../../../../variables.scss';

.questionNumber {
  position: absolute;
  left: calc(50% - 15px);
  top: 10px;
  font-family: 'Leftover Crayon', sans-serif;
  font-size: 1.4rem;
  color: white;
  text-shadow: #898989 0 2px 4px;

  @media screen and (min-width: $tabletWidth) {
    position: fixed;
    color: $black;
    font-size: 48px;
    text-shadow: none;
    left: 8%;
    top: 3vmin;
  }
}