@import '../../variables.scss';

.outerContainer {
  background-color: $mediumBackColor;
  margin-top: $navigtaionTopBarHeight;
}

.wrapper {
  box-sizing: border-box;
  background-color: $mediumBackColor;
  font-family: 'Montserrat', sans-serif;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $desktopWidth) {
    margin-top: 3em;
  }
}