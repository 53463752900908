@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import '../../../../variables';

.checkboxSize {
  top: 0 !important;
  width: 1.25vw;
  height: 1.25vw;

  @media screen and (max-width: $tabletWidth) {
    width: 25px;
    height: 25px;
  }
}

.checkContainer {
  @extend .pretty;
  @extend .p-image;
  @extend .p-plain;
  @extend .p-bigger;

  .styledCheckbox {
    @extend .state;

    img {
      transform: unset;
      width: 2.05vw;
      height: 2.1vw;
      left: -0.75vw;
      top: -0.75vw;

      @media screen and (max-width: $tabletWidth) {
        width: 38px;
        height: 38px;
        left: -13px;
        top: -15px;
      }
    }

    label {
      font-size: 1.2vw;
      word-wrap: break-word;
      white-space: pre-wrap;
      text-indent: 0;
      padding-left: 2vw;

      @media screen and (max-width: $tabletWidth) {
        font-size: 22px;
        line-height: 25px;
        padding-left: 35px;
      }

      &:before {
        border: 0;
        @extend .checkboxSize;
      }
      &:after {
        border-color: #979797;
        @extend .checkboxSize;
      }
    }
  }
}
