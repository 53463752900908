@import '../../../../../../variables';

.funFact {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 6vw;
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  padding: 0 10vw;
  text-transform: uppercase;
  color: $crayondarkgrey;

  @media screen and (min-width: $tabletWidth) {
    font-size: 1.75vw;
    width: 30vw;
    min-height: 25vw;
    left: -20px;
  }

  @media screen and (min-width: $desktopWidth) {
    left: -40px;
  }
}

.funFact.withBubble {
  padding: 0 14vw;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    display: flex;
    align-items: center;
    padding: 0 5vw 5vw;

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.bubble {
  position: absolute;
  z-index: -1;
  top: -100px;
  right: -30px;
  bottom: -40px;
  left: -10px;
  background-image: url('./assets/bubble.png');
  background-size: 100% 100%;

  @media screen and (min-width: $tabletWidth) {
    background-image: unset;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-size: 100% 100%;
      background-image: url('./assets/desktop/bubble.png');
    }
  }
}