@import '../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}

.bubble {
  position: absolute;
  bottom: 60%;
  left: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 400px !important;
  width: 70% !important;
  height: 200px !important;
  max-height: 200px !important;
  z-index: 60;

  @media screen and (min-width: $tabletWidth) {
    bottom: 60%;
    left: auto;
    right: 0;
    height: 250px !important;
    max-height: 250px !important;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    right: 50%;
    transform: translateX(calc(50% + 110%));
  }
}

.backgroundArea {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 130%;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 80%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 50%;
  }

  .background {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./assets/bg.png');
    background-position: top center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 5;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/bg.png');
      background-size: contain;
    }
  }

  &.Outro {
    .rhino {
      width: 70%;
      bottom: 5%;
      position: absolute;
      z-index: 15;
      right: 50%;
      transform: translateX(calc(50% + 35%));

      @media screen and (min-width: $tabletWidth) {
        bottom: 5%;
        width: 40%;
      }

      @media screen and (min-width: $bigDesktopWidth) {
        bottom: 3%;
        width: 25%;
        transform: translateX(calc(50% + 50%));
      }

    }

    .puppy {
      width: 100%;
      bottom: 10%;
      left: 50%;
      transform: translateX(calc(-50% - 35%));
      position: absolute;
      z-index: 10;

      @media screen and (min-width: $tabletWidth) {
        transform: translateX(calc(-50% - 30%));
        width: 60%;
        bottom: 10%;
      }

      @media screen and (min-width: $bigDesktopWidth) {
        width: 40%;
        bottom: 5%;
      }
    }
  }
}


.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}

