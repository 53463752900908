@import './../../variables';

.mapBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  @media screen and (min-width: $tabletWidth) {
    flex-direction: row;
    margin-top: 200px;
  }
  @media screen and (min-width: $desktopWidth) {
    margin-top: 80px;
  }
  h2 {
    text-transform: uppercase;
    text-align: center;
    padding: 10px 30px 0;
    font-family: 'Leftover Crayon', sans-serif;
    line-height: 28px;
    font-size: 26px;
    font-weight: normal;
    margin-top: 50px;
    @media screen and (min-width: $tabletWidth) {
      padding-top: 16px;
      font-size: 36px;
      line-height: 40px;
      margin-top: 40px;
    }
  }
}