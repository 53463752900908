.thiefGame {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/background_desktop.png');
}

.iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 960px;
  height: 540px;
  transform: translate3d(-50%, -50%, 0);
  border: none;
}