
.no_image {
  justify-content: center;
  flex-direction: initial;

  &>div {
    left: unset;
    margin: 0;
  }
}


.postitContainer{
  &.postitContainerVideo {
    justify-content: space-evenly;

    .postitNew {
      max-width: 45%;

      @media(max-width: 1679px) {
        max-width: 100%;
        z-index: 99;
      }
    }

    .video {
      cursor: pointer;

      @media(max-width: 1679px) {
        margin-right: 0;
      }
    }

    @media(max-width: 1679px) {
      justify-content: center;
      max-width: 650px;
      width: calc(100% - 129px);
      align-items: center;
      flex-direction: column-reverse;
    }
  }
}
.postitContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 1510px;
  margin: 0 auto;
  line-height: 1.5em;
  text-align: center;
  font-size: 18px;
  padding: 70px 0;
  align-items: center;

  @media(max-width: 1679px) {
    justify-content: center;
    max-width: 650px;
    width: calc(100% - 129px);
    align-items: center;
  }

  &.postitContainerLeft {
    @media(max-width: 1679px) {
      flex-direction: column;
    }
  }
  &.postitContainerRight {
    @media(max-width: 1679px) {
      flex-direction: column-reverse;
    }
  }

  .postitNew {
    -webkit-box-shadow: 7px 7px 0px 1px rgba(187,188,190,1);
    -moz-box-shadow: 7px 7px 0px 1px rgba(187,188,190,1);
    box-shadow: 7px 7px 0px 1px rgba(187,188,190,1);
    background: #fdfdfa;
    padding: 20px;
    max-width: 50%;

    @media(max-width: 1679px) {
      max-width: 100%;
    }

    &.postitNewRight {
      transform: rotate(2deg);
    }

    &.postitNewLeft {
      transform: rotate(-2deg);
    }
  }

  .image {
    width: auto;
    max-width: 50%;
    height: auto;

    @media(max-width: 1679px) {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }

  .tape {
    box-shadow: inset 0 0 1em 0.5em rgba(255, 255, 255, 0.1);
    height: 3em;
    position: absolute;
    transform: rotate(-130deg);
    width: 9em;
    -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
    right: -60px;

    @media(max-width: 753px) {
      height: 2em;
      width: 6em;
    }

    &.tapeRed {
      background-color: rgba(249, 26, 26, 0.58);
    }

    &.tapeGrey {
      background-color: rgba(64, 77, 73, 0.88);
    }

    &.tapeBlue {
      background-color: rgba(0, 87, 113, 0.85);
      transform: rotate(-180deg);
      right: 35%;
      top: -27px;
      width: 10em;
    }

    &.tapeRight {
      right: 7px;
      top: -40px;
      left: initial;
      transform: rotate(-89deg);
      width: 7em;

      @media(max-width: 753px) {
        top: -6vw;
        width: 18vw;
        height: 7vw;
      }
    }
  }
  .tape:after,
  .tape:before {
    background-size: .4em .4em;
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    width: .2em;
  }
  .tape:after {
    background-image: linear-gradient(45deg, transparent 50%, hsla(0,0%,100%,.3) 50%),
    linear-gradient(-45deg, transparent 50%, hsla(0,0%,100%,.3) 50%);
    background-position: 0 100%;
    left: -.2em;
  }
  .tape:before {
    background-image: linear-gradient(135deg, transparent 50%, hsla(0, 0%, 100%, .3) 50%),
    linear-gradient(-135deg, transparent 50%, hsla(0, 0%, 100%, .3) 50%);
    background-position: 100% 100%;
  }

  .tvClass {
    z-index: 9;
  }
}
