@import '../../variables';

.postitTitle {
  font-family: 'Leftover Crayon', sans-serif;
  line-height: 28px;
  font-size: 26px;
  font-weight: normal;
  padding-top: 10px;
  margin-top: 50px;
  text-align: center;
  text-transform: uppercase;
  @media screen and (min-width: $tabletWidth) {
    padding-top: 60px;
    font-size: 36px;
    line-height: 40px;
    margin-top: 40px;
  }
}

.plainText {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    font-size: 18px;
    line-height: 36px;
  }
  @media screen and (min-width: $desktopWidth) {
    line-height: 27px;
  }
}

.subtext {
  font-weight: bolder;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    font-size: 18px;
    line-height: 36px;
  }
  @media screen and (min-width: $desktopWidth) {
    line-height: 27px;
  }
}