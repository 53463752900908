@import '../../variables';

.score {
  font-size: 24px;
  line-height: .9;
  height: 1em;
  color: $crayondarkgrey;
  font-family: "Leftover Crayon", sans-serif;
  flex: 0 0 100%;
  margin: 0;
  padding: 0;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    font-size: 28px;
  }

  @media screen and (min-width: $desktopWidth) {
    font-size: 48px;
  }
}