@import '../../variables';

$footerWrapWidth: 1168px;

.footer {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: $crayondarkgrey;

  a {
    transition: color 140ms linear;
    text-decoration: none;
    color: $crayondarkgrey;

    &:hover {
      color: $halftransparentdark;
    }
  }

  h1 {
    font-size: 11px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  p {
    margin-top: 0;
  }

  address {
    font-style: normal;
  }

  footer {
    font-weight: bold;
    width: 270px;
    margin: 43px auto 55px;
    text-align: center;

    img {
      margin-bottom: -3px;
    }
  }
}

.newsletter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 345px;
  margin: 86px auto 68px;

  @media screen and (max-width: $tabletWidth) {
    width: 270px;
  }
}

.newsletterCopy {
  font-size: 30px;
  font-weight: bold;
  line-height: 34px;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $tabletWidth) {
    font-size: 21px;
    line-height: 26px;
  }
}

.newsletterSubscribeLink {
  font-weight: 600;
  position: relative;
  overflow: hidden;
  padding: 0 5% 20px 5%;

  &::after {
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;
    height: 90px;
    content: "";
    transition: all 130ms linear;
    background-image: url('./assets/line_newsletter.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &:hover::after {
    opacity: 0;
  }
}

.middleSectionWrapper {
  box-sizing: border-box;
  width: 100vw;
  padding: 38px 25px;
  background: $mediumBackColor;
}

.middleSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1660px;
  margin: 0 auto;
}

.middleSectionLeft {
  display: flex;
  flex: 0 0 33%;

  @media screen and (max-width: $footerWrapWidth) {
    flex: 0 0 50%;
    order: 2;
  }

  @media screen and (max-width: $tabletWidth) {
    flex-wrap: wrap;
    text-align: center;
  }

  section {
    flex: 0 0 33%;
    box-sizing: border-box;
    padding-right: 10px;

    @media screen and (max-width: $tabletWidth) {
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }
}

.middleSectionCenter {
  flex: 0 1 28%;

  @media screen and (max-width: $footerWrapWidth) {
    flex: 0 0 100%;
    order: 1;
    padding-bottom: 40px;
  }
}

.middleSectionCenter {
  text-align: center;

  h1 {
    font-size: 14px;
  }
}

.socialMediaIcon {
  margin: 6px 7px;

}

.middleSectionRight {
  font-size: 12px;
  line-height: 21px;
  display: flex;
  flex: 0 0 33%;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;

  a {
    font-weight: bold;
    display: block;
    width: 190px;
    padding-left: 10px;
    text-transform: uppercase;
  }

  @media screen and (max-width: $footerWrapWidth) {
    flex: 0 0 50%;
    order: 3;
  }

  @media screen and (max-width: $tabletWidth) {
    align-items: center;
    flex: 0 0 100%;
    height: unset;
    margin-bottom: 20px;
    text-align: center;

    a {
      padding-left: 0;
    }
  }
}

.poweredBy {
  p {
    margin-bottom: 14px;
  }
}

.ergoLogo {
  width: auto;
  height: 24px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: contents;

  li {
    p {
      margin: 0;
      padding: 0;

      a {
        font-weight: 700;
        display: block;
        width: 190px;
        padding-left: 10px;
        text-transform: uppercase;
        font-family: inherit;
        text-align: left;
        font-size: 12px;
        line-height: 21px;

        @media (max-width: 767px) {
          text-align: center;
        }
      }
    }
  }
}

section {
  p {
    a {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
    }
  }
}
