@import '../../../../variables.scss';

.spritesheet {
  position: fixed;
  left: 50%;
  height: auto;
  transform: translateX(-50%);
  bottom: -10px;
  max-width: 100%;

  @media screen and (min-width: $tabletWidth) {
    bottom: 30%;
    max-width: 22vw;
  }
}

.desk {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 172vw;
  max-width: 957px;
  height: auto;
  transform: translate3d(-53%, 43%, 0);

  @media screen and (min-width: $tabletWidth) {
    width: 58vw;
    bottom: 30%;
  }
}

.frame {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 1.15rem;
  color: $crayondarkgrey;
  transform: translateX(-50%);
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: $tabletWidth) {
    font-size: 1.2vw;
  }
}

.bubble {
  position: fixed;
  bottom: 48%;
  transform: translateX(-50%);
  left: 73%;

  @media screen and (min-width: $tabletWidth) {
    left: 28%;
    bottom: 44%;
  }

  @media screen and (min-width: $desktopWidth) {
    left: 29%;
    bottom: 49%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    left: 34%;
    bottom: 52%;
  }
}

.frame {
  width: 196px;
  position: absolute;
  top: 10%;
  font-size: 32px;

  @media screen and (min-width: $tabletWidth) {
    width: 17vw;
    top: 15%;
    right: 1vw;
    font-size: 2.2vw;
  }

  .imageFrame {
    width: 196px;
    height: 140px;
    margin-bottom: 20px;

    @media screen and (min-width: $tabletWidth) {
      width: 17vw;
      height: 12vw;
    }

    .imageSmall {
      @media screen and (min-width: $tabletWidth + 1) {
        display: none;
      }
    }

    .imageBig {
      width: 17vw;
      max-width: 308px;
      height: 12vw;
      max-height: 220px;
      @media screen and (max-width: $tabletWidth) {
        display: none;
      }
    }
  }
}