@import "../../variables";

@keyframes show-layer {
  0% {
    opacity: .9;
    z-index: 999;
  }
  100% {
    opacity: 0;
  }
}

.showLayer {
  animation: show-layer 3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  z-index: -1;
  opacity: 0;
}