.DayPicker {
  display: flex;
  z-index: 99;
  text-transform: uppercase;
}

.DayPicker .CalendarMonth_caption {
  font-family: "Leftover Crayon", sans-serif;
}

.DayPicker .CalendarMonth_caption strong,
.DayPicker .DayPicker_weekHeader,
.DayPicker .CalendarDay {
  font-weight: normal;
}

.DayPicker .CalendarMonth_table {
  margin-top: 0.5vw;
}

.DayPicker .CalendarDay {
  border: none;
}

.DayPicker .CalendarDay__today {
  border-bottom: 4px #E7AE53 solid;
}

.DayPicker .CalendarMonth_table tr td.CalendarDay__default:nth-of-type(6),
.DayPicker .CalendarMonth_table tr td.CalendarDay__default:nth-of-type(7) {
  background-color: #FAEADC;
  background-clip: padding-box;
}

.DayPicker .CalendarDay__selected {
  background-color: #E7AE53 !important;
  background-clip: unset !important;
}

.DayPicker .CalendarDay__selected_span {
  background-color: #FDD18D !important;
  background-clip: unset !important;
}
.DayPicker .CalendarDay__hovered_span {
  background-color: #E7AE53 !important;
  color: unset;
  opacity: 0.33;
}

.DayPicker .DayPickerKeyboardShortcuts_show {
  display: none;
}

.DayPicker .DayPickerNavigation_button {
  width: 24px;
  height: 26px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
}

.DayPicker .DayPickerNavigation_button svg {
  display: none;
}

.DayPicker .DayPickerNavigation_button.DayPickerNavigation_leftButton__horizontalDefault {
  background-image: url('./containers/Calendar/assets/arrow_left.png');
  left: 20%;
}

.DayPicker .DayPickerNavigation_button.DayPickerNavigation_rightButton__horizontalDefault {
  background-image: url('./containers/Calendar/assets/arrow_right.png');
  right: 20%;
}