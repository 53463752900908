@import '../../variables';

// commons
$mobileContentWidth: 310px;
$tabletContentWidth: 650px;
$sectionDesktopWidth: 1510px;
$sectionTextSidePadding: 32px;
$cardParagraphVerticalMargin: 1.6em;

@mixin sectionTexts($mobileBackgroundImgUrl, $tabletBackgroundImgUrl) {
  position: relative;
  background-image: url($mobileBackgroundImgUrl);
  background-size: 305px auto;
  background-repeat: no-repeat;
  line-height: 1.5em;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    background-image: url($tabletBackgroundImgUrl);
    font-size: 18px;
    line-height: 1.8em;
  }
}

.sections {
  @extend .displayFlex;
  width: $mobileContentWidth;
  margin-bottom: 3em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;

  @media screen and (min-width: $tabletWidth) {
    width: $tabletContentWidth;
    margin-bottom: 4em;
    & img {
      width: $tabletContentWidth - 10px;
    }
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row;
    width: $sectionDesktopWidth;
    margin-left: auto;
    margin-right: auto;
  }

  & img {
    width: $mobileContentWidth - 10px;
  }

  & h2,
  & h3 {
    width: inherit;
  }
}

.cardSidePaddings {
  padding-left: $sectionTextSidePadding;
  padding-right: $sectionTextSidePadding;
}
.cardSidePaddingsAsymetric {
  padding-left: $sectionTextSidePadding + 10px;
  padding-right: $sectionTextSidePadding + 20px;
}

.rules {
  margin-bottom: $cardParagraphVerticalMargin;
}

.subTitle {
  @extend .headerBase;
  @media screen and (min-width: $tabletWidth) {
    font-size: 36px;
  }
}

.calendarLink {
  margin-top: $cardParagraphVerticalMargin;
  width: inherit;

  & a {
    @extend .linksBase;
  }
}

// html elements

.container {
  background-color: $lightyellowbackground;
}

.introText {
  @extend .displayFlex;
  margin-bottom: 3em;
  flex-direction: column-reverse;
  /*padding-top: 75px;*/

  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row;
    width: $sectionDesktopWidth;
    margin-left: auto;
    margin-right: auto;
  }

  & .calendarLink {
    width: initial;
  }
}

.postit {
  @extend .cardSidePaddings;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 47px;
  position: relative;
  line-height: 1.5em;
  text-align: center;
  background-color: transparent;
  background-image: url('./assets/mobile/introCard-mobile.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 305px auto;
  width: $mobileContentWidth;
  height: auto;
  margin: -3em auto 0 auto;
  min-height: 563px;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/introCard.svg');
    background-size: 574px auto;
    margin-top: -4em;
    padding-top: 80px;
    padding-right: 80px;
    padding-left: 80px;
    font-size: 18px;
    width: $tabletContentWidth;
    height: auto;

    & > p {
      margin-bottom: 2em;
    }
  }
  @media screen and (min-width: $bigDesktopWidth) {
    margin-top: 0;
    margin-right: 0;
  }

  & .subTitle {
    @extend .headerBase;
    margin-top: 0.8em;

    @media screen and (min-width: $tabletWidth) {
      font-size: 36px;
    }
  }
}

.introImg {
  width: $mobileContentWidth;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $tabletWidth) {
    width: $tabletContentWidth;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    margin-left: 20px;
    margin-top: 30px;
    width: 610px;
  }
}

.animal_pic,
img.familyWorkshop_pic,
img.kidsWorshop_pic,
img.kidCourse_pic,
img.artClub_pic,
img.mickeyMouse_pic {
  width: 100%;
}

img.stare_pic {
  @media screen and (min-width: $tabletWidth) {
    width: 400px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    left: 13em;
    top: 5em;
    position: relative;
  }
}

img.familyWorkshop_pic {
  @media screen and (min-width: $bigDesktopWidth) {
    width: 636px;
    left: -10em;
    top: 6em;
    position: relative;
  }
}

img.kidCourse_pic {
  @media screen and (min-width: $bigDesktopWidth) {
    left: 16.5em;
    top: 7em;
    position: relative;
  }
}

img.artClub_pic {
  @media screen and (min-width: $bigDesktopWidth) {
    width: 611px;
    left: 7em;
    top: 11em;
    position: relative;
  }
}

img.mickeyMouse_pic {
  @media screen and (min-width: $bigDesktopWidth) {
    left: -10em;
    top: 5em;
    position: relative;
    width: 644px;
  }
}

.familySection,
.familyWorkshopSection,
.babyArtText,
.kidsWorshopSection,
.kidCourseSection,
.holidaysText,
.artClubSection,
.mickeyMouseSection {
  @extend .sections;
}

.familySection {
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row;
    width: $sectionDesktopWidth;
    margin-left: auto;
    margin-right: auto;
  }
}
.familyWorkshopSection {
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row-reverse;
    margin-top: 10em;
  }
}

.familyText {
  @include sectionTexts(
    './assets/mobile/familyCard-mobile.svg',
    './assets/tablet/familyCard.svg'
  );
  background-size: 305px 530px;
  padding-left: 24px;
  padding-right: 36px;
  margin-top: -3em;
  margin-left: 3px;
  padding-top: 34px;
  padding-bottom: 57px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 638px auto;
    margin-top: -3em;
    margin-right: -17px;
    padding-top: 70px;
    padding-left: 90px;
    padding-right: 88px;
    padding-bottom: 88px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 622px;
    left: -10em;
    top: 0;
    margin-top: 0;
    padding-top: 61px;
    padding-left: 60px;
    padding-right: 0;

    & > p {
      margin-left: 1em;
      margin-right: 8em;
    }
  }

  & h2,
  & p {
    width: inherit;
  }
}

.familyWorkshopText {
  @include sectionTexts(
    './assets/mobile/familyCard-mobile.svg',
    './assets/tablet/familyCard.svg'
  );
  background-size: 305px 540px;
  @extend .cardSidePaddings;
  margin-top: -2em;
  margin-left: 5px;
  padding-top: 40px;
  padding-bottom: 77px;
  padding-left: 24px;
  padding-right: 36px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 638px auto;
    margin-top: -4em;
    margin-right: -17px;
    padding-top: 48px;
    padding-left: 90px;
    padding-right: 88px;
    padding-bottom: 94px;

    & h2 {
      margin-left: 50px;
      margin-right: 30px;
    }
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 462px;
    left: 144px;
    top: 0;
    margin-top: 0;
  }
}

.babyArtSection {
  width: $mobileContentWidth;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $tabletWidth) {
    width: $tabletContentWidth;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    width: $sectionDesktopWidth;
    display: flex;
    flex-direction: row;
  }
}

.contentWrapper {
  margin-left: 5px;

  @media screen and (min-width: $tabletWidth) {
    margin-left: 0;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    width: 560px;
    left: 90px;
    top: 5em;
    position: relative;
  }
}

.babyArtText {
  @include sectionTexts(
    './assets/mobile/babyArtCard-mobile.svg',
    './assets/tablet/babyArtCard.svg'
  );
  background-size: 305px 688px;
  @extend .cardSidePaddings;
  margin-bottom: $cardParagraphVerticalMargin;
  padding-top: 60px;
  padding-bottom: 87px;
  width: 242px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 582px auto;
    margin-top: 0;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 80px;
    width: 482px;

    & .rules {
      margin-left: 15px;
      margin-top: 0;
    }
  }
}

.coursesSection {
  @media screen and (min-width: $bigDesktopWidth) {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: $sectionDesktopWidth;

    & .kidCourseSection {
      margin-left: -38px;
      width: 800px;
    }
  }
}
.kidsWorshopSection {
  padding-left: 0;
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 600px;
    left: 70px;
  }
}

.kidsWorshop_pic {
  margin-left: 10px;
}

.kidsWorshopText {
  @include sectionTexts(
    './assets/mobile/kidsWorkshopCard-mobile.svg',
    './assets/tablet/kidsWorkshopCard.svg'
  );
  @extend .cardSidePaddings;
  background-size: ($mobileContentWidth - 20px) auto;
  margin-left: 22px;
  margin-top: -2.4em;
  padding-top: 40px;
  padding-bottom: 77px;
  padding-left: 26px;
  padding-right: 26px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 533px auto;
    margin-top: -4.4em;
    padding-top: 54px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 57px;
    margin-left: 60px;
    width: 414px;
  }
}

.kidCourseText {
  @include sectionTexts(
    './assets/mobile/kidsCourseCard-mobile.svg',
    './assets/tablet/kidsCourseCard.svg'
  );
  @extend .cardSidePaddingsAsymetric;
  background-size: ($mobileContentWidth + 10px) auto;
  margin-left: -10px;
  margin-top: -2.2em;
  padding-top: 40px;
  padding-bottom: 50px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 628px auto;
    margin-top: -4.4em;
    margin-left: 10px;
    padding-top: 65px;
    padding-left: 90px;
    padding-right: 100px;
    padding-bottom: 58px;
    width: 438px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    left: 2em;
  }
}

.holidaysText {
  @include sectionTexts(
    './assets/mobile/holidaysCard-mobile.svg',
    './assets/tablet/holidaysCard.svg'
  );
  background-size: 305px 588px;
  @extend .cardSidePaddings;
  margin-bottom: $cardParagraphVerticalMargin;
  padding-top: 60px;
  padding-bottom: 87px;
  width: $mobileContentWidth - (2 * $sectionTextSidePadding);

  @media screen and (min-width: $tabletWidth) {
    background-size: 512px auto;
    margin-top: 0em;
    margin-bottom: 4em;
    padding-top: 75px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 64px;
    width: 444px;

    .rules {
      width: inherit;
    }
  }

  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 444px;
    top: 19em;
    left: 3em;
  }
}

.artClubText {
  @include sectionTexts(
    './assets/mobile/artClubCard-mobile.svg',
    './assets/tablet/artClubCard.svg'
  );
  @extend .cardSidePaddingsAsymetric;
  background-size: ($mobileContentWidth + 10px) auto;
  margin-left: -10px;
  margin-top: -2em;
  padding-top: 40px;
  padding-bottom: 77px;

  @media screen and (min-width: $tabletWidth) {
    background-size: $tabletWidth auto;
    margin-left: -60px;
    margin-top: -3.2em;
    padding-top: 55px;
    padding-left: 105px;
    padding-right: 115px;
    padding-bottom: 50px;
    width: 540px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    left: -5em;
  }
}

.mickeyMouseSection {
  @media screen and (min-width: $tabletWidth) {
    margin-bottom: 4em;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row-reverse;
    margin-top: 7em;
  }
}
.mickeyMouseText {
  @include sectionTexts(
    './assets/mobile/mickeyCard-mobile.svg',
    './assets/tablet/mickeyCard.svg'
  );
  background-size: 305px 470px;
  @extend .cardSidePaddingsAsymetric;
  margin-left: -10px;
  margin-top: -2em;
  padding-top: 40px;
  padding-bottom: 98px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 631px auto;
    margin-left: 0;
    margin-top: -2.4em;
    padding-top: 65px;
    padding-left: 135px;
    padding-right: 115px;
    padding-bottom: 94px;
    width: 380px;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    left: 8em;
    margin-top: 0;
  }
}

.topLinkSection {
  margin-top: 0;
  padding-bottom: 2em;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    margin-top: 2em;
    padding-bottom: 4em;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    width: $sectionDesktopWidth;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
  }
}
.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}

@import "Postits.module";
