@import "../../variables";

.arrow {
  background-image: url('../../assets/arrow_for_bubbles.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 50px;
  height: 30px;
  position: fixed;
  bottom: 5%;
  z-index: 120;
  cursor: pointer;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./ArrowDesktop.png');
    width: 15vmin;
    max-width: 144px;
    height: 7vmin;
    max-height: 61px;
    bottom: 5vmin;
  }
}

.arrowNext {
  @extend .arrow;
  right: 8%;
  @media screen and (min-width: $tabletWidth) {
    right: 5vmin;
  }
}

.arrowPrevious {
  @extend .arrow;
  left: 8%;
  transform: scaleX(-1);
  @media screen and (min-width: $tabletWidth) {
    left: 5vmin;
  }
}

.firstView {
  display: none;
}
