@import '../../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.bubble {
  position: absolute;
  right: 5%;
  bottom: 50%;
  display: flex;
  z-index: 60;
  align-items: center;

  @media screen and (min-width: $tabletWidth) {
    top: auto;
    bottom: 50%;
    left: 60%;
    right: auto;
    width: 40%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: $bigDesktopWidth) {
    top: auto;
    bottom: 70%;
    left: 70%;
    width: 40%;
    transform: translateX(-50%);
  }
}

.blanketArea {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 150%;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 80%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 50%;
  }

  .blanket {
    background-repeat: no-repeat;
    background-image: url('../assets/bg.png');
    background-size: contain;
    background-position: top 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    margin: 0 auto;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('../assets/desktop/bg.png');
      background-position: center top;
      background-size: contain;
      height: 100%;
      width: 100%;
      max-height: unset;
    }
  }

  &.Intro {

    .bird {
      position: absolute;
      z-index: 50;
      left: 0;
      width: 20%;
      bottom: 68%;

      @media screen and (min-width: $tabletWidth) {
        bottom: 65%;
        width: 15%;
        left: 50%;
        transform: translateX(calc(-50% - 150%));
      }

      @media screen and (min-width: $bigDesktopWidth) {
        transform: translateX(calc(-50% - 150%));
        width: 10%;
        bottom: 62%;
      }
    }

    .statue {
      position: absolute;
      bottom: 20%;
      z-index: 50;
      right: 0;
      width: 25%;

      @media screen and (min-width: $tabletWidth) {
        right: 50%;
        transform: translateX(250%);
        width: 15%;
      }

      @media screen and (min-width: $bigDesktopWidth) {
        width: 12%;
        bottom: 12%;
      }
    }

    .goat {
      position: absolute;
      left: 0;
      bottom: 35%;
      width: 30%;
      z-index: 30;

      @media screen and (min-width: $tabletWidth) {
        left: 0;
        transform: none;
        width: 15%;
      }

      @media screen and (min-width: $bigDesktopWidth) {
        left: 50%;
        transform: translateX(calc(-50% - 374%));
        bottom: 18%;
        width: 8%;
      }
    }

    .rhino {
      width: 50%;
      bottom: 10%;
      left: 50%;
      transform: translateX(-40%);
      position: absolute;
      z-index: 30;

      @media screen and (min-width: $tabletWidth) {
        width: 25%;
        transform: translateX(-20%);
      }
    }

    .puppy {
      width: 35%;
      bottom: 0;
      left: 50%;
      transform: translateX(calc(-50% - 70%));
      position: absolute;
      z-index: 30;

      @media screen and (min-width: $tabletWidth) {
        width: 20%;
      }
    }
  }
}


.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}


.arrowNext {
  z-index: 500 !important;
}
