@import '../../../../../../variables';

.gameScore {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 48px;
  height: 35px;

  @media screen and (min-width: $tabletWidth) {
    width: 100px;
    height: 40px;

    transform: translateX(150px);

    &:first-of-type {
      transform: translateX(-150px);
    }
  }

  @media screen and (min-width: $desktopWidth) {
    padding-top: 10px;
    width: 100px;
    height: 65px;
    transform: translateX(175px);

    &:first-of-type {
      transform: translateX(-175px);
    }
  }
}

.header {
  font-size: 14px;
  height: 1em;
  text-transform: uppercase;
  color: $crayonlightgray;
  font-family: "Leftover Crayon", sans-serif;
  flex: 0 0 100%;
  margin: 0;
  padding: 0;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    font-size: 16px;
  }

  @media screen and (min-width: $desktopWidth) {
    font-size: 18px;
  }
}