@import '../../variables';

.generalPostit {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
}

.generalImage {
  margin: 0 auto;
  display: block;
}

.isBold {
  font-weight: bold;
}

.postitTitle {
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: "Leftover Crayon", sans-serif;
  font-weight: normal;
  opacity: .75;
  padding: 50px 25px 0;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    font-size: 36px;
    line-height: 40px;
  }
}

.postitParagraph {
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding: 0 24px;
  font-family: "Montserrat", sans-serif;
  @media screen and (min-width: $tabletWidth) {
    font-size: 18px;
    line-height: 32px;
    padding: 0 40px;
  }
}

.linkBox {
  display: flex;
  justify-content: space-evenly;
  padding-right: 10px;
}

.postitLink {
  font-family: "Leftover Crayon", sans-serif;
  text-transform: uppercase;
  color: $black;
  margin-top: 20px;
  cursor: pointer;
  font-size: 18px;
  @media screen and (min-width: $tabletWidth) {
    font-size: 22px;
  }
}

.linkLeft {
  padding-left: 36px;
  float: left;
}

.linkRight {
  float: right;
  padding-right: 34px;
}

.sectionBox {
  @media screen and (min-width: $bigDesktopWidth) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.email {
  color: $black;
}

// sections:
.childrenBirthdaySection {
  .postit {
    background-image: url('assets/mobile/cardBirthdayChildren.svg');
    @extend .generalPostit;
    width: 286px;
    height: 880px;
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/cardBirthdayChildren.svg');
      width: 724px;
      height: 744px;
    }
  }
  .postitParagraph,
  .postitTitle {
    padding-left: 34px;
    @media screen and (min-width: $tabletWidth) {
      padding-right: 98px;
      padding-left: 26px;
    }
  }
  .postitTitle{
    @media screen and (min-width: $tabletWidth) {
      padding: 80px 248px 0 176px;
    }
  }
  img {
    width: 234px;
    height: 154px;
    @extend .generalImage;
    position: relative;
    top: -20px;
    right: -14px;
    @media screen and (min-width: $tabletWidth) {
      width: 602px;
      height: 396px;
      top: -32px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      top: 100px;
      right: -150px;
    }
  }
  .tape {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('./assets/photoTape.svg');
    width: 40px;
    height: 56px;
    transform: rotate(180deg);
    margin: 0 auto;
    position: relative;
    left: -92px;
    top: -58px;
    @media screen and (min-width: $tabletWidth) {
      width: 134px;
      height: 134px;
      left: -250px;
      top: -132px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      transform: initial;
      top: -331px;
      left: 414px;
    }
  }
  @media screen and (min-width: $tabletWidth) {
    margin-top: 20px;
  }
}

.birthdaySection {
  position: relative;
  height: 1300px;
  .postit {
    background-image: url('assets/mobile/cardBirthday.svg');
    @extend .generalPostit;
    width: 305px;
    height: 1100px;
    margin-top: -26px;
    position: absolute;
    left: 0;
    right: 0;
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/cardBirthday.svg');
      width: 576px;
      height: 1040px;
      right: -100px;
      margin-top: -80px;
    }
  }
  .postitTitle{
    @media screen and (min-width: $tabletWidth) {
      padding-top: 80px;
    }
  }
  img {
    width: 290px;
    height: 190px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    @extend .generalImage;
    @media screen and (min-width: $tabletWidth) {
      width: 660px;
      height: 420px;
      left: -30px;
    }
  }
  @media screen and (min-width: $tabletWidth) {
    height: 1440px;
  }
}

.familySection {
  position: relative;
  height: 660px;
  .postit {
    background-image: url('./assets/mobile/cardFamily.svg');
    @extend .generalPostit;
    width: 314px;
    height: 466px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -44px;
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/cardFamily.svg');
      width: 548px;
      height: 490px;
      left: 130px;
      bottom: 60px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      bottom: initial;
      position: initial;
      margin: initial;
    }
  }
  .postitParagraph,
  .postitTitle {
    padding-left: 34px;
    padding-right: 36px;
  }
  .postitTitle{
    @media screen and (min-width: $tabletWidth) {
      padding: 50px 100px 0 100px;
    }
  }
  .postitParagraph {
    @media screen and (min-width: $tabletWidth) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  img {
    width: 300px;
    height: 200px;
    position: relative;
    top: 0;
    left: 0;
    @extend .generalImage;
    @media screen and (min-width: $tabletWidth) {
      width: 618px;
      height: 412px;
      left: -40px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      margin: initial;
      margin-left: 100px;
      left: initial;
    }
  }
  @media screen and (min-width: $tabletWidth) {
    height: 900px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    height: 580px;
  }
}

.schoolSection {
  position: relative;
  height: 520px;
  .postit {
    background-image: url('./assets/mobile/cardSchool.svg');
    @extend .generalPostit;
    width: 276px;
    height: 320px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -60px;
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/cardSchool.svg');
      width: 392px;
      height: 400px;
      left: 240px;
      transform: rotate(2deg);
    }
    @media screen and (min-width: $bigDesktopWidth) {
      bottom: initial;
      position: initial;
      margin: initial;
      margin-left: -40px;
      margin-top: 30px;
    }
  }
  img {
    width: 300px;
    height: 230px;
    position: relative;
    top: 0;
    left: 0;
    @extend .generalImage;
    @media screen and (min-width: $tabletWidth) {
      width: 618px;
      height: 478px;
      left: -40px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      margin: initial;
      margin-left: 100px;
      left: initial;
    }
  }
  @media screen and (min-width: $tabletWidth) {
    height: 840px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 560px;
  }
}

.linkSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding-right: 10px;
}

@import "../AgainAndAgain/Postits.module";
