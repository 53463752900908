@import '../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.bubble {
  left: 40%;
  bottom: 60%;
  z-index: 60;
  position: absolute;
  width: 60%;
  height: 200px !important;
  max-height: 200px !important;
  display: flex;
  align-items: center;

  @media screen and (min-width: $tabletWidth) {
    left: 65%;
    bottom: 65%;
    height: 250px !important;
    max-height: 250px !important;
  }
}

.backgroundArea {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  min-height: 800px;
  max-height: 800px;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 70%;
    height: auto;
    min-height: unset;
    max-height: unset;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 48%;
    height: auto;
    min-height: unset;
    max-height: unset;
  }

  .background {
    background-repeat: no-repeat;
    background-image: url('./assets/bg.png');
    background-position: center center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/bg.png');
      background-position: center top;
      background-size: contain;
      height: 100%;
      width: 100%;
      max-height: unset;
    }
  }


  &.Outro {
    .rhino {
      width: 70%;
      left: 45%;
      transform: translateX(-15%) translateY(50%);
      bottom: 55%;
      position: absolute;
      z-index: 30;
      min-width: 350px;

      @media screen and (min-width: $tabletWidth) {
        right: 10%;
        left: auto;
        bottom: 0;
        width: 50%;
        transform: none;
        min-width: unset;
      }

      @media screen and (min-width: $bigDesktopWidth) {
        right: 20%;
        left: auto;
        bottom: 5%;
        width: 30%;
        transform: none;
        min-width: unset;
      }
    }
  }
}

.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}

