@import '../../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  position: fixed;
  height: 100%;
}

.margeSign {
  border: 1px solid $margecolor;
  height: 100vh;
  position: absolute;
  left: 4.4%;
  z-index: 0;
  top: 0;
  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.decorationHorizontal {
  background-image: url('../assets/sling_2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 320px;
  height: 163px;
  position: absolute;
  left: calc(50% - 160px);
  z-index: -5;
  top: 0;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('../assets/desktop/decorations.png');

    @include desktop-image(82%, 47vmin, unset, 433px, 9%, 0);
    left: 50%;
    transform: translateX(calc(-50% - 60px));
    top: 0;
  }
}

.decorationVertical {
  background-image: url('../assets/curl_6.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 56%;
  width: 53px;
  height: 274px;
  z-index: -5;
  @media screen and (min-width: $tabletWidth) {
    display: none;
  }
}

.bubble {
  position: absolute;
}

.bubble1 {
  left: 65px;
  bottom: 100%;

  @media screen and (min-width: $tabletWidth) {
    left: 42%;
    bottom: 75%;
  }

  @media screen and (min-width: $desktopWidth) {
    bottom: 82%;
    left: 42%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 95%;
    left: calc(16% + 450px);
  }
}

.bubble2, .bubble3 {
  left: 155px;
  bottom: 85%;

  @media screen and (max-width: $mobileWidth) {
    left: 130px;
  }

  @media screen and (min-width: $tabletWidth) {
    left: 42%;
    bottom: 102%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 105%;
    left: calc(16% + 450px);
  }
}

.bubble4 {
  transform: translateX(calc(100% - 220px));
  bottom: 51vh;

  @media screen and (min-width: $tabletWidth) {
    transform: translateX(77vmin) translateY(10vmin);
  }
}

.blanketArea {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  height: 235px;
  background-color: #e6ffe6;
  width: 100%;

  @media screen and (min-width: $tabletWidth) {
    max-height: 400px;
    height: 52vh;
  }

  @media screen and (min-width: $desktopWidth) {
    max-height: 540px;
    height: 52vh;
  }

  .blanket {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets/blanket.png');
    height: 80%;
    width: 100%;
    margin-top: 8%;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('../assets/desktop/blanket.png');
      @include desktop-image(80%, 105%, 1562px, 540px, null, null);
      margin: 0;
      display: flex;
      bottom: 0;
    }
  }

  .rhino {
    position: absolute;
    left: 0;
    z-index: 2;

    @media screen and (max-width: $mobileWidth) {
      left: -22px;
    }
  }

  .bird {
    position: absolute;
    right: 0;
    z-index: 1;

    @media screen and (max-width: $mobileWidth) {
      right: -22px;
    }
  }

  &.Intro {
    .rhino {
      width: 153px;
      bottom: 40%;

      @media screen and (min-width: $tabletWidth) {
        left: 16%;
        width: 25%;
        max-width: 400px;
      }
    }
    .bird {
      width: 160px;
      top: 0;

      @media screen and (min-width: $tabletWidth) {
        left: 65%;
        bottom: 40%;
        top: unset;
        width: 20%;
        max-width: 300px;
      }
    }
  }

  &.Outro {
    .rhino {
      width: 176px;
      bottom: 40%;

      @media screen and (max-width: $mobileWidth) {
        bottom: 50%;
      }
      @media screen and (min-width: $tabletWidth) {
        left: 12%;
        width: 30%;
        max-width: 474px;
      }
    }
    .bird {
      width: 140px;
      bottom: 20%;

      @media screen and (min-width: $tabletWidth) {
        left: 65%;
        bottom: 40%;
        width: 17.5%;
        max-width: 262px;

      }
    }
  }

  .cake {
    background-image: url('../assets/desktop/cake.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 108px;
    height: 58px;
    position: absolute;
    bottom: 17%;
    left: 13%;

    @media screen and (min-width: $tabletWidth) {
      @include desktop-image(17%, auto, 280px, 150px, 61%, 1, 5vmin);
    }
  }

  .apples {
    background-image: url('../assets/desktop/apples.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 55px;
    height: 34px;
    position: absolute;
    bottom: 16%;
    left: 5%;
    @media screen and (min-width: $tabletWidth) {
      @include desktop-image(8%, auto, 146px, 150px, 15%, 2, 27%);
    }
  }
  
  .cards {
    @include desktop-image(25%, 38%, 453px, 211px, 39%, 1, 48%);
  }
  
  .plates {
    @include desktop-image(12%, auto, 179px, 144px, 47%, 2, 5vmin);
  }
  
  .pot {
    @include desktop-image(12%, auto, 182px, 153px, 33%, 2, 8vmin);
  }

  .saucer {
    @include desktop-image(10%, auto, 130px, 88px, 45%, 1, 14vmin);
  }

  .flower {
    @include desktop-image(6%, auto, 57px, 80px, 6%, 0, 42%);
  }

  .grass {
    @include desktop-image(15%, auto, 240px, 71px, 2%, 0, 41%);
  }
}

.hideOnMobile {
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}
