@import '../../variables';

.wrapper {
  background-color: $mediumBackColor;
  display: flex;
  flex-direction: column;
  padding: 100px 30px 100px;
  @media screen and (min-width: $desktopWidth) {
    padding: $navigtaionTopBarHeight 60px 140px;
    background-image: url('./assets/Thread.png');
    background-repeat: no-repeat;
    background-size: 72% 70%;
    background-position: 0 80%;
  }
  h2, h3, p {
    text-transform: uppercase;
    text-align: center;
    padding: 0 30px;
  }
  h2 {
    font-family: 'Leftover Crayon', sans-serif;
    line-height: 28px;
    font-size: 26px;
    font-weight: normal;
    padding-top: 10px;
    margin-top: 50px;
    @media screen and (min-width: $tabletWidth) {
      padding-top: 60px;
      font-size: 36px;
      line-height: 40px;
      margin-top: 40px;
    }
  }
  h3 {
    @extend .textPostit;
    font-weight: bold;
  }
  h4 {
    text-align: center;
    margin-bottom: 0;
    padding: 0 50px;
  }
  p {
    @extend .textPostit;
    margin-top: 0;
  }
}

.independentLine {
  display: block;
}

.carouselWrapper {
  background-image: url('./assets/Card_slider_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/Card_slider.svg');
  }
}

.textPostit {
  line-height: 22px;
  font-size: 14px;
  margin-bottom: 0;
  @media screen and (min-width: $tabletWidth) {
    line-height: 36px;
    font-size: 18px;
  }
}

.registrationText {
  margin-top: 50px !important;
}

.postitBoxTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: $desktopWidth) {
    flex-direction: row;
  }
}

.baseDataTopLeft {
  width: 308px;
  height: 320px;
  margin-right: 0;
  background-image: url('./assets/Card_corner.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    width: 500px;
    height: 500px;
  }
  @media screen and (min-width: $desktopWidth) {
    margin-right: 60px;
  }
}

.baseDataTopRight {
  width: 320px;
  height: 494px;
  margin-top: 88px;
  background-image: url('./assets/Card_tickets_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/Card_tickets.svg');
    width: 600px;
    height: 600px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 682px;
    height: 532px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    height: 621px;
  }
  & h2 {
    padding-top: 0;
    margin-bottom: 10px;
    @media screen and (min-width: $tabletWidth) {
      padding-top: 50px;
      margin-bottom: 10px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding-top: 40px;
      margin-bottom: 0;
    }
  }
  & h3, p {
    padding: 0 14px;
    @media screen and (min-width: $tabletWidth) {
      padding: 0 60px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding: 0 120px 0 110px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      padding: 0 100px 0 80px;
    }
  }
  & h3 {
    margin-top: 10px;
  }
  & h4 {
    margin-top: 12px;
    @media screen and (min-width: $desktopWidth) {
      padding: 0 120px 0 110px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      padding: 0 100px 0 80px;
    }
  }
}

.imageBoxTop {
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  position: relative;
  @media screen and (min-width: $desktopWidth) {
    height: 500px;
  }
}

.linkImage {
  width: 178px;
  display: inline-block;
  position: absolute;
  top: 180px;
  left: 10%;
  @media screen and (min-width: $tabletWidth) {
    width: 301px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 374px;
    top: 220px;
  }
}

.thinkBubble {
  background-image: url('./assets/bubble_think1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  width: 260px;
  height: 201px;
  left: 10%;
  @media screen and (min-width: $tabletWidth) {
    width: 371px;
    height: 272px;
    left: 280px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 459px;
    height: 337px;
    left: 350px;
  }
  span {
    display: block;
    width: 160px;
    padding-top: 60px;
    padding-left: 80px;
    font-size: 14px;
    line-height: 22px;
    @media screen and (min-width: $tabletWidth) {
      font-size: 18px;
      line-height: 27px;
      padding-top: 80px;
      padding-left: 120px;
      width: 220px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding-top: 120px;
      padding-left: 160px;
      width: 290px;
    }
  }
  h2 {
    padding-top: 10px;
  }
}

.sign {
  cursor: pointer;
  font-size: 28px;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  font-family: 'Leftover Crayon', sans-serif;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
}

.faqSign {
  @extend .sign;
  background-image: url('./assets/FAQ.svg');
  width: 82px;
  height: 31px;
  bottom: 60px;
  right: 34px;
  font-size: 22px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  @media screen and (min-width: $tabletWidth) {
    width: 154px;
    height: 56px;
    font-size: 28px;
    right: 60px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 177px;
    height: 56px;
    bottom: 86px;
    right: 70px;
  }
}

.linkBox {
  display: none;
  @media screen and (min-width: $mobileWidth) {
    display: block;
    width: 490px;
    margin: 0 auto;
    position: absolute;
    bottom: 20px;
    left: 0;
    height: 80px;
  }
  @media screen and (min-width: $desktopWidth) {
    bottom: 30px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 50px;
  }
}

.ticketSign {
  @extend .sign;
  text-decoration: none;
  text-align: center;
  padding-top: 5px;
  color: $black;
  box-sizing: border-box;
  background-image: url('./assets/Card_button.png');
  width: 178px;
  height: 31px;
  bottom: 48px;
  right: 0;
  left: 76px;
  font-size: 22px;
  line-height: 24px;
  @media screen and (min-width: $tabletWidth) {
    display: block;
    bottom: 20px;
    font-size: 28px;
    width: 238px;
    height: 56px;
    right: 20px;
    left: initial;
    padding-top: 16px;
  }
  @media screen and (min-width: $desktopWidth) {
    bottom: 0;
  }
}

.ticketLink {
  text-transform: uppercase;
  color: $black;
  font-family: 'Leftover Crayon', sans-serif;
  font-size: 19px;
  line-height: 24px;
  margin-top: 50px;
  margin-left: 20px;
  position: absolute;
  bottom: 8px;
  left: 58px;
  @media screen and (min-width: $tabletWidth) {
    font-size: 22px;
    line-height: 39px;
    display: block;
    bottom: 24px;
    left: 0;
  }
  @media screen and (min-width: $desktopWidth) {
    bottom: 0;
  }
}

.postitBoxBottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 510px;
  @media screen and (min-width: $desktopWidth) {
    justify-content: center;
    margin-top: -310px;
    height: 950px;
    flex-direction: row;
    min-width: 1180px;
    width: 100%;
  }
}

.baseDataBottomLeft {
  display: inline-block;
  margin-top: 290px;
  margin-right: 0;
  width: 306px;
  height: 370px;
  background-image: url('./assets/Card_small_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  font-size: 18px;
  line-height: 33px;
  @media screen and (min-width: $tabletWidth) {
    width: 634px;
    height: 586px;
    max-height: 586px;
    margin-top: 500px;
    background-image: url('./assets/Card_small.svg');
    padding: 0 40px;
  }
  @media screen and (min-width: $desktopWidth) {
    margin-top: 410px;
    max-width: 500px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    position: initial;
  }
  & h2 {
    padding: 20px 40px 0;
    @media screen and (min-width: $tabletWidth) {
      padding: 90px 80px 0;
    }
    @media screen and (min-width: $desktopWidth) {
      margin-bottom: 10px;
      padding: 90px 30px 0;
    }
  }
  & p {
    text-transform: inherit;
    padding: 0 30px;
    @media screen and (min-width: $desktopWidth) {
      padding: 0;
    }
  }
}

.baseDataBottomRight {
  width: 320px;
  height: 800px;
  margin-left: 0;
  display: inline-block;
  box-sizing: border-box;
  background-image: url('./assets/Card_big2_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
  margin-top: -1230px;
  @media screen and (min-width: $tabletWidth) {
    width: 780px;
    max-width: 780px;
    height: 800px;
    background-image: url('./assets/Card_big2.svg');
    padding: 0 70px;
    margin-left: 0;
    margin-top: -1550px;
  }
  @media screen and (min-width: $desktopWidth) {
    margin-top: 0;
    width: 1100px;
    height: 950px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    position: initial;
  }
  & h2 {
    padding: 40px 0 0;
    margin-top: 54px;
    @media screen and (min-width: $tabletWidth) {
      padding-top: 90px;
      margin-top: 40px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding-top: 160px;
    }
  }
  & p {
    margin-bottom: 10px;
    text-transform: initial;
    padding: 0 50px;
    @media screen and (min-width: $tabletWidth) {
      margin-bottom: 30px;
    }
  }
}

.bottomImage {
  width: 260px;
  margin: -80px auto 420px;
  @media screen and (min-width: $tabletWidth) {
    width: 574px;
    margin: -80px 60px 600px 130px;
  }
  @media screen and (min-width: $desktopWidth) {
    margin: -100px 360px 0;
  }
}

.topLinkSection {
  text-align: center;
  margin-top: 5em;
  padding-bottom: 0;
}

.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}
