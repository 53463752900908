@import '../../../../../../variables';

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.taskReminder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: $yellowCardColor;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
  width: 446px;
  transform: translateX(-70px);
  position: relative;
  padding-top: 59px;
  box-sizing: border-box;

  &::before {
    content: "";
    background-image: url('../assets/circle_inverted.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 12px;
    height: 11px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 27px;
  }
}

.copy {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 26px;
  flex: 0 0 100%;
  max-width: 15em;
  text-align: center;
  text-transform: uppercase;
  color: $crayondarkgrey;
  animation: showElement 2s;
  margin-top: 0;
  padding-top: 10px;
  height: auto;
}

.bottom {
  display: flex;
  align-content: center;
  flex: 0 0 100%;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  justify-content: space-between;
}

.subText {
  box-sizing: border-box;
  text-align: center;
  flex: 0 0 50%;
  margin-top: 0;
}

.imgWrapper {
  flex: 0 0 50%;
  animation: showElement 2s;
  position: relative;

  img {
    display: block;
    width: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.bold {
  font-weight: bold;
}