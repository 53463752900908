@import "../../variables.scss";

.muteButton {
  position: absolute;
  top: 47px;
  right: 99px;
  height: 33px;
  width: auto;
  cursor: pointer;
  z-index: 99999;

  @media screen and (max-width: $tabletWidth) {
    top: 15px;
    right: unset;
    left: 16px;
    height: 25px;
  }
}