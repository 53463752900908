@import '../../../../variables';

.eventPostit {
  background-repeat: no-repeat;
  background-size: cover;
  width: 305px;
  margin: 0 auto 220px;
  position: relative;
  padding-right: 14px;
  padding-left: 20px;
  padding-bottom: 80px;
  box-sizing: border-box;
  height: auto;
  display: table;
  @media screen and (min-width: $tabletWidth) {
    min-width: 720px;
    padding-right: 34px;
    padding-left: 34px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    width: 996px;
    margin: 0;
  }
}

.smallPostit {
  margin-bottom: 120px;
}

.bold {
  font-weight: bold;
}

.title {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  padding-top: 50px;
  @media screen and (min-width: $tabletWidth) {
    font-size: 36px;
    line-height: 40px;
    padding-top: 80px;
  }
}

.date {
  color: $dateColor;
  font-family: "Leftover Crayon", sans-serif;
  font-size: 18px;
  line-height: 19px;
  @media screen and (min-width: $tabletWidth) {
    font-size: 28px;
    line-height: 31px;
  }
}

.paragraph {
  font-size: 16px;
  line-height: 25px;
  @media screen and (min-width: $tabletWidth) {
    font-size: 18px;
    line-height: 22px;
  }
}

.primaryText {
  color: $crayondarkgrey;
  font-weight: bold;
}