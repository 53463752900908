@import '../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  background: transparent;
}

.bubble {
  position: absolute;
  left: 40%;
  top: auto;
  bottom: 60%;
  z-index: 30;
  display: flex;
  justify-content: center;
  width: 60% !important;
  max-width: 400px !important;
  height: 200px !important;
  max-height: 200px !important;
  align-items: center;

  @media screen and (min-width: $tabletWidth) {
    left: auto;
    right: 5%;
    top: auto;
    bottom: 60%;
    height: 250px !important;
    max-height: 250px !important;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    left: 70%;
    top: auto;
    bottom: 60%;
    height: 250px !important;
    max-height: 250px !important;
  }
}

.backgroundArea {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 175%;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 70%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 50%;
  }

  .background {
    background-repeat: no-repeat;
    background-image: url('./assets/bg.png');
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    margin: 0 auto;

    @media screen and (min-width: $tabletWidth) {
      background-size: contain;
    }
  }

  &.Outro {
    .rhino {
      width: 90%;
      bottom: 10%;
      right: 0;
      position: absolute;
      z-index: 30;

      @media screen and (min-width: $tabletWidth) {
        width: 30%;
        right: 50%;
        transform: translateX(calc(50% + 50%));
      }
    }
  }
}

.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}

