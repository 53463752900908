@import '../../variables';

.homeBody {
  background-image: url('../../assets/background.png');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('../../assets/bckg.png');
  }
}

.homeNavigation {
  background-color: $lightbackground;
}

@keyframes animated-logo {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -42px;
  }
}

@keyframes animated-cover {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logoSpritesheet {
  display: none;
  @media screen and (min-width: $desktopWidth) {
    display: block;
    z-index: 9999;
    width: 164px;
    animation: animated-logo 9s;
    opacity: 0;
    top: 0;
    left: calc(50% - 86px);
    position: absolute;
  }
}

.logoCover {
  display: none;
  @media screen and (min-width: $desktopWidth) {
    display: block;
    width: 200px;
    height: 100px;
    position: absolute;
    z-index: 999;
    top: 0;
    left: auto;
    right: auto;
    background-color: white;
    animation: animated-cover 9s;
    opacity: 0;
  }
}

.flowerBasic {
  position: relative;
  @extend .hideOnMobile;
  @media screen and (max-width: $desktopWidth) {
    transform: scale(.65, .65);
  }
}

.background100 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hideOnMobile {
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  height: 100%;
  background: transparent;

  .cloud2 {
    position: absolute;
    width: 130px;
    top: 15%;
    right: 10%;
    @media screen and (max-width: $desktopWidth) {
      display: none;
    }
  }

  .cloud3 {
    position: absolute;
    width: 116px;
    top: 12%;
    right: 6%;
    @media screen and (max-width: $desktopWidth) {
      display: none;
    }
    @media screen and (min-width: $desktopWidth) {
      width: 126px;
    }
  }

  .cloud5 {
    position: absolute;
    width: 170px;
    top: 166px;
    left: 4%;
    display: none;

    @media screen and (min-width: $tabletWidth) {
      width: 80px;
      display: block;
      top: 120px;
    }

    @media screen and (min-width: $desktopWidth) {
      width: 164px;
      top: 140px;
    }
  }

  .grassArea {
    width: 100vw;
    height: 150px;
    margin-top: 200px;

    @media screen and (min-width: $tabletWidth) {
      margin-top: 240px;
      height: 170px;
    }

    @media screen and (min-width: $desktopWidth) {
      height: 422px;
      margin-top: 360px;
    }

    .grassBg {
      @extend .background100;
      width: 100vw;
      height: 100%;
      position: relative;
    }

    .grassField {
      @media screen and (min-width: $tabletWidth) {
        max-width: 800px;
        margin: 0 auto;
        position: relative;
      }
      @media screen and (min-width: $desktopWidth) {
        position: initial;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        max-width: 1400px;
        position: relative;
      }
    }

    .thread {
      @extend .background100;
      width: 100vw;
      height: 4vw;
      max-height: 89px;
      background-image: url('./assets/desktop/thread.png');
      position: absolute;
      top: 342px;

      @media screen and (min-width: $tabletWidth) {
        top: 392px;
      }

      @media screen and (min-width: $desktopWidth) {
        top: 748px;
      }
    }

    .museum {
      @extend .background100;
      width: 240px;
      height: 90px;
      background-image: url('./assets/Museum.png');
      position: absolute;
      top: 150px;
      left: -90px;

      @media screen and (min-width: $tabletWidth) {
        width: 350px;
        height: 120px;
        top: -246px;
        left: 10px;
      }

      @media screen and (min-width: $desktopWidth) {
        width: 644px;
        height: 186px;
        top: 240px;
      }

      @media screen and (min-width: $bigDesktopWidth) {
        bottom: 350px;
        top: initial;
      }
    }

    .bassin {
      @extend .background100;
      width: 140px;
      height: 112px;
      background-image: url('./assets/desktop/bassin.png');
      position: absolute;
      top: 246px;
      left: 44px;
      display: none;
      @media screen and (min-width: $tabletWidth) {
        display: block;
        top: -180px;
      }
      @media screen and (min-width: $desktopWidth) {
        top: 326px;
        width: 264px;
        height: 198px;
        left: 84px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        bottom: 220px;
        top: initial;
      }
    }

    .flower4 {
      @extend .flowerBasic;
      top: 100px;
      left: 200px;
      width: 41px;
      @media screen and (min-width: $desktopWidth) {
        top: 200px;
        left: 400px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        width: 61px;
      }
    }

    .flower9 {
      @extend .flowerBasic;
      top: 100px;
      left: -30px;
      width: 69px;
      transform: rotateY(180deg);
      @media screen and (max-width: $desktopWidth) {
        transform: rotateY(180deg) scale(.65, .65);
      }
      @media screen and (min-width: $desktopWidth) {
        top: 270px;
        left: 20px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        width: 95px;
      }
    }

    .flower13 {
      @extend .flowerBasic;
      top: 20px;
      left: 120px;
      width: 23px;
      @media screen and (min-width: $desktopWidth) {
        left: 320px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        width: 35px;
      }
    }

    .flower14 {
      @extend .flowerBasic;
      top: 70px;
      left: 30px;
      width: 43px;
      @media screen and (min-width: $desktopWidth) {
        top: 160px;
        left: 180px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        width: 61px;
      }
    }

    .flower16 {
      @extend .flowerBasic;
      top: 80px;
      left: 180px;
      width: 43px;
      @media screen and (min-width: $desktopWidth) {
        top: 260px;
        left: 400px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        width: 62px;
      }
    }

    .blanket {
      @extend .background100;
      background-image: url('./assets/desktop/blanket.png');
      width: 320px;
      height: 100px;
      position: absolute;
      right: calc(50% - 160px);
      top: 240px;
      @media screen and (min-width: $tabletWidth) {
        right: 50px;
        top: -130px;
        width: 336px;
        height: 112px;
      }
      @media screen and (min-width: $desktopWidth) {
        width: 680px;
        height: 304px;
        top: 440px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        bottom: 50px;
        top: initial;
      }
    }

    .rhino {
      @extend .background100;
      background-image: url('./assets/Rhino.png');
      width: 100px;
      height: 188px;
      position: absolute;
      left: calc(50% - 160px);
      top: 130px;
      @media screen and (min-width: $tabletWidth) {
        width: 108px;
        height: 200px;
        left: inherit;
        top: -250px;
        right: 270px;
      }
      @media screen and (min-width: $desktopWidth) {
        width: 220px;
        height: 400px;
        top: 240px;
        right: 500px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        bottom: 160px;
        top: initial;
      }
    }

    .dog {
      @extend .background100;
      background-image: url('assets/muffin.png');
      width: 110px;
      height: 98px;
      position: absolute;
      right: calc(50% - 160px);
      top: 230px;
      z-index: 5;
      @media screen and (min-width: $tabletWidth) {
        right: 70px;
        top: -150px;
        width: 120px;
        height: 110px;
      }
      @media screen and (min-width: $desktopWidth) {
        width: 240px;
        height: 220px;
        top: 460px;
      }
      @media screen and (min-width: $bigDesktopWidth) {
        bottom: 120px;
        top: initial;
      }
    }

    .grass {
      @extend .background100;
      background-image: url('./assets/desktop/grass.png');
      width: 156px;
      height: 54px;
      position: absolute;
      right: calc(50% - 220px);
      top: 68px;
      transform: scale(.5);
      @media screen and (max-width: $tabletWidth) {
        display: none;
      }
      @media screen and (min-width: $desktopWidth) {
        transform: scale(1);
        top: 240px;
        right: calc(50% - 370px);
      }
    }
  }
}

.bubble {
  @extend .background100;
  background-image: url('./assets/desktop/bubble.png');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100px;
  right: calc(50% - 160px);
  padding: 18px 20px 0 64px;
  box-sizing: border-box;
  width: 240px;
  height: 130px;
  z-index: 4;

  @media screen and (min-width: $tabletWidth) {
    right: 50px;
    top: -310px;
  }
  @media screen and (min-width: $desktopWidth) {
    right: 280px;
    top: 230px;
    width: 240px;
    height: 173px;
    padding: 20px 22px 14px 72px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 400px;
    top: initial;
  }

  .bubbleText {
    font-family: "Leftover Crayon", sans-serif;
    color: $crayonmidgrey;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    width: 100%;

    @media screen and (min-width: $desktopWidth) {
      font-size: 18px;
    }
  }

  .startButton {
    background-image: url('./assets/desktop/pink_button.svg');
    color: $black;
    width: 100px;
    font-size: 22px;
    position: relative;
    outline: none;
    cursor: pointer;
  }
}

.videoTeaser {
  box-sizing: border-box;
  @extend .background100;
  background-image: url('./assets/card.svg');
  width: 320px;
  height: 360px;
  margin: 40px auto 20px;
  padding: 1px 40px 0;
  font-family: 'Montserrat', sans-serif;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/card.svg');
    width: 500px;
    height: 240px;
    padding: 1px 40px 0 50px;
    margin-top: 20px;
  }

  @media screen and (min-width: $desktopWidth) {
    width: 660px;
    height: 320px;
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  h3 {
    font-family: "Leftover Crayon", sans-serif;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 100;
    text-align: center;
    margin: 50px 0 0 0;
    @media screen and (min-width: $tabletWidth) {
      margin-top: 40px;
      font-size: 20px;
    }
    @media screen and (min-width: $desktopWidth) {
      font-size: 28px;
      margin-top: 60px;
    }
  }

  p {
    @media screen and (max-width: $tabletWidth) {
      padding: 0 20px;
    }
    @media screen and (min-width: $desktopWidth) {
      font-size: 18px;
      line-height: 1.4;
      padding: 0 20px;
    }
  }
}

.movieBox {
  @media screen and (min-width: $desktopWidth) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.footerWrapper {
  border: 1px solid $lightbackground;
  display: block;
  background-color: $lightbackground;
  position: absolute;
}

.noHeader{
  margin-top: 105px;
}
