@import '../../variables';

.container {
  max-width: 900px;
  width: 95%;
  margin: 0 auto;
  padding-bottom: 40px;

  img {
    max-width: 100%;
    width: 200px;
    height: auto;
  }
}

.imageContainer {
  justify-content: center;
  display: flex;

  div:nth-child(2) {
    margin-left: 10px;

    @media screen and (min-width: $tabletWidth) {
      margin-left: 20px;
    }
  }
}
