@import '../../../../../../variables.scss';

.quizImage {
  width: 100vw;
  overflow: hidden;

  @media screen and (min-width: $tabletWidth) {
    flex: 0 1 35vw;
    padding: 3vw;
    position: relative;

    img {
      box-shadow: #898989 5px 5px 18px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 12vw;
      max-width: 310px;
      height: 3vw;
      max-height: 69px;
      top: 1vw;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('tape2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
    }
  }
}

.img {
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: appear 220ms linear;
  z-index: 1;
}

@keyframes appear {
  from {
    opacity: 0;
    filter: blur(4px);
  }
  to {
    opacity: 1;
  }
}
