@import '../../../../../../variables.scss';

.quizQuestion {
  font-family: 'Leftover Crayon', sans-serif;
  font-size: 1.1rem;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 64vw;
  padding: 10px;
  margin: 0 auto;
  animation: textAnimation 400ms linear;
  text-align: center;
  text-transform: uppercase;
  color: $crayondarkgrey;

  &::before {
    position: absolute;
    z-index: -1;
    bottom: 60%;
    left: 52%;
    width: 66vw;
    max-width: 280px;
    height: 45vw; // this it to preserve aspect ratio
    max-height: 190px;
    content: "";
    transform: perspective(100px) translate3d(-50%, 50%, 0);
    animation-name: bubbleAnimation;
    animation-duration: 8s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    background-image: url('./assets/bubble.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/bubble.png');
      width: 32vw;
      max-width: 652px;
      height: 12vw;
      max-height: 256px;
      left: 58%;
    }
  }
}

@keyframes bubbleAnimation {
  0% {
    display: block;
    opacity: 0;
    transform: perspective(100px) translate3d(-3000px, 50%, -1000px);
  }
  4% {
    opacity: 1;
    transform: perspective(100px) translate3d(-45%, 55%, 0);
  }
  6% {
    opacity: 1;
    transform: perspective(100px) translate3d(-50%, 50%, 0);
  }
  7% {
    opacity: 1;
    transform: perspective(100px) translate3d(-47%, 52%, 0);
  }
  9% {
    opacity: 1;
    transform: perspective(100px) translate3d(-50%, 50%, 0);
  }
  30% {
    opacity: 1;
  }
  32% {
    opacity: 0;
    display: block;
  }
  33% {
    display: none;
  }

}

@keyframes textAnimation {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}