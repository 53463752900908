@import '../../../../variables';

.background100 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;



  @media screen and (min-width: $desktopWidth) {

    /*flex-direction: row;*/

    /*position: relative;*/

  }

  @media screen and (min-width: 1462px) {
    max-width: 1462px;
    display: inline-block;
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
  }
}

h2 {
  font-family: Leftover Crayon,sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  line-height: 28px;
  font-weight: normal;
}

.redBox {
  display: inline-block;
  margin-bottom: 60px;
  width: 320px;
  height: 494px;
  margin-top: 88px;
  background-image: url('./assets/Card_red_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/Card_red.svg');
    width: 600px;
    height: 600px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 682px;
    height: 532px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    height: 621px;
  }
  & h2 {
    line-height: 40px;
    font-size: 36px;
    text-align: center;
    margin-top: 40px;
    padding-top: 0;
    margin-bottom: 10px;
    @media screen and (min-width: $tabletWidth) {
      padding-top: 85px;
      margin-bottom: 10px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding-top: 40px;
      margin-bottom: 0;
    }
  }
  & p {
    font-size: 14px;
    line-height: 22px;

    @media screen and (min-width: $desktopWidth) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  & h3, p {
    padding: 0 14px;
    @media screen and (min-width: $tabletWidth) {
      padding: 0 60px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding: 0 120px 0 110px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      padding: 0 100px 0 80px;
    }
  }
  & h3 {
    margin-top: 10px;
  }
  & h4 {
    margin-top: 12px;
    @media screen and (min-width: $desktopWidth) {
      padding: 0 120px 0 110px;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      padding: 0 100px 0 80px;
    }
  }
}

.blueBox {
  width: 320px;
  height: 800px;
  margin-left: 0;
  display: inline-block;
  box-sizing: border-box;
  background-image: url('./assets/Card_blue_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
  @media screen and (min-width: $tabletWidth) {
    width: 780px;
    max-width: 780px;
    height: 800px;
    background-image: url('./assets/Card_blue.svg');
    padding: 0 70px;
    margin-left: 0;
  }
  @media screen and (min-width: $desktopWidth) {
    margin-top: 0;
    width: 1100px;
    height: 950px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    position: initial;
  }
  & h2 {
    font-size: 32px;
    text-align: center;
    padding: 40px 0 0;
    margin-top: 54px;
    @media screen and (min-width: $tabletWidth) {
      padding-top: 60px;
      margin-top: 40px;
    }
    @media screen and (min-width: $desktopWidth) {
      padding-top: 118px;
    }
  }
  & p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 10px;
    text-transform: initial;
    padding: 0 50px;
    @media screen and (min-width: $tabletWidth) {
      margin-bottom: 30px;
      font-size: 16px;
    }
    @media screen and (min-width: $desktopWidth) {
      font-size: 22px;
    }
  }
}

.movieBox {
  @media screen and (min-width: $desktopWidth) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}


.videoTeaser {
  box-sizing: border-box;
  @extend .background100;
  background-image: url('./assets/card.svg');
  width: 320px;
  height: 360px;
  margin: 40px auto 20px;
  padding: 1px 40px 0;
  font-family: 'Montserrat', sans-serif;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/card.svg');
    width: 500px;
    height: 240px;
    padding: 1px 40px 0 50px;
    margin-top: 20px;
  }

  @media screen and (min-width: $desktopWidth) {
    width: 660px;
    height: 320px;
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  h3 {
    font-family: "Leftover Crayon", sans-serif;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 100;
    text-align: center;
    margin: 50px 0 0 0;
    @media screen and (min-width: $tabletWidth) {
      margin-top: 40px;
    }
    @media screen and (min-width: $desktopWidth) {
      font-size: 36px;
      margin-top: 60px;
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    padding: 0 20px;

    @media screen and (min-width: $desktopWidth) {
      font-size: 18px;
      line-height: 27px;
      padding: 0 20px;
    }
  }
}

.newsletterSubscribeLink {
  font-weight: 600;
  position: relative;
  overflow: hidden;
  padding: 0 5% 20px 5%;
  transition: color 140ms linear;
  text-decoration: none;
  color: #4a4a4a;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 18px;

  &::after {
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;
    height: 90px;
    content: "";
    transition: all 130ms linear;
    background-image: url('./assets/line_newsletter.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &:hover::after {
    opacity: 0;
  }
}

.movieBoxWrapper {
  display: inline-block;
  width: 100%;
}

.linkWrapper {
  display: none;
  justify-content: center;
}

p {
  font-size: 18px;
  line-height: 27px;
}

.event_title {
  margin-bottom: 0;
}

.event_text {
  p {
    font-size: 14px;
    line-height: 22px;

    @media screen and (min-width: $desktopWidth) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.movieBoxWrapper~.movieBoxWrapper .linkWrapper {
  display: flex;
}

.birdBoxWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

  .birdBox {
    @media screen and (min-width: $desktopWidth) {
      flex-direction: row-reverse;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .wrapperImage {
    margin: 40px auto 0;
    position: relative;
    @media screen and (min-width: $tabletWidth) {

    }
    @media screen and (min-width: $desktopWidth) {
      left: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .frame {
    background-image: url('assets/box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    position: relative;
    width: 267px;
    height: 184px;
    padding: 11px 10px 10px 8px;
    margin: 0 auto;
    @media screen and (min-width: $desktopWidth) {
      width: 404px;
      height: 278px;
      padding: 17px 10px 10px 12px;
    }
  }

  .imageContainer {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 250px;
    height: 166px;

    @media screen and (min-width: $desktopWidth) {
      width: 376px;
      height: 250px;
    }
  }


  .birdBoxTeaser {
    box-sizing: border-box;
    @extend .background100;
    background-image: url('./assets/card.svg');
    width: 320px;
    height: 360px;
    margin: 40px auto 20px;
    padding: 1px 40px 0;
    font-family: 'Montserrat', sans-serif;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/card.svg');
      width: 500px;
      height: 240px;
      padding: 1px 40px 0 50px;
      margin-top: 20px;
    }

    @media screen and (min-width: $desktopWidth) {
      width: 660px;
      height: 320px;
      font-size: 20px;
      margin-left: 0;
      margin-right: 0;
    }

    h3 {
      font-family: "Leftover Crayon", sans-serif;
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 100;
      text-align: center;
      margin: 50px 0 0 0;
      @media screen and (min-width: $tabletWidth) {
        margin-top: 40px;
      }
      @media screen and (min-width: $desktopWidth) {
        font-size: 36px;
        margin-top: 60px;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
      padding: 0 20px;

      @media screen and (min-width: $desktopWidth) {
        font-size: 18px;
        line-height: 27px;
        padding: 0 20px;
      }
    }
  }

  .magpie {
    width: 104px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -37px;
    bottom: -24px;
    background-image: url('./assets/magpie.png');

    @media screen and (min-width: $desktopWidth) {
      width: 150px;
      height: 115px;
      right: -53px;
      bottom: -27px;
    }
  }
}
