@import '../../variables';

$mobileFontSize: 18px;
$desktopFontSize: 24px;
$bigDesktopFontSize: 28px;

// We use font size factor to make the bubble padding always in line with the font size
$tabletSizeFactor: $mobileFontSize / $bigDesktopFontSize;
$desktopSizeFactor: $desktopFontSize / $bigDesktopFontSize;


.bubble {
  z-index: 9;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .bubbleText {
    font-family: "Leftover Crayon", sans-serif;
    font-size: $mobileFontSize;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $crayondarkgrey;

    @media screen and (min-width: $desktopWidth) {
      font-size: $desktopFontSize;
    }
  }
}

.bubble1 {
  width: 268px;
  max-width: 268px;
  height: 232px;
  max-height: 232px;
  padding: 50px 46px 85px 27px;
  background-image: url('./assets/bubble1.png');


  // we do smaller viewports in relation to the large desktop to keep proportions
  @media screen and (min-width: $tabletWidth) {
    width: 377px * .64;
    max-width: 377px * .64;
    height: 381px * .64;
    max-height: 381px * .64;
    padding: (96px * .64) (40px * .64) (125px *.64) (56px * .64);
    background-image: url('./assets/desktop/bubble1.png');

    &.mirroredOnDesktop {
      background-image: url('./assets/desktop/bubble1-mirrored.png');
    }
  }

  @media screen and (min-width: $desktopWidth) {
    width: 377px * .82;
    max-width: 377px * .82;
    height: 381px * .82;
    max-height: 381px * .82;
    padding: (96px * .82) (40px * .82) (125px *.82) (56px * .82);
  }
}

.bubble2 {
  width: 206px;
  max-width: 206px;
  height: 225px;
  max-height: 225px;
  padding: 58px 30px 60px 22px;
  background-image: url('./assets/bubble2.png');

  @media screen and (min-width: $tabletWidth) {
    width: 273px;
    max-width: 273px;
    height: 158px;
    max-height: 158px;
    padding: (60px * $tabletSizeFactor) (46px * $tabletSizeFactor) (62px * $tabletSizeFactor) (99px * $tabletSizeFactor);
    background-image: url('./assets/tablet/bubble2.png');

    &.mirroredOnDesktop {
        background-image: url('./assets/desktop/bubble2-mirrored.png');
        padding: (60px * $tabletSizeFactor) (120px * $tabletSizeFactor) (62px * $tabletSizeFactor) (35px * $tabletSizeFactor);
    }
  }

  @media screen and (min-width: $desktopWidth) {
    width: 410px * $desktopSizeFactor;
    max-width: 410px * $desktopSizeFactor;
    height: 237px * $desktopSizeFactor;
    max-height: 237px * $desktopSizeFactor;
    padding: (60px * $desktopSizeFactor) (46px * $desktopSizeFactor) (62px * $desktopSizeFactor) (99px * $desktopSizeFactor);
    background-image: url('./assets/desktop/bubble2.png');

    &.mirroredOnDesktop {
      background-image: url('./assets/desktop/bubble2-mirrored.png');
      padding: (60px * $desktopSizeFactor) (120px * $desktopSizeFactor) (62px * $desktopSizeFactor) (35px * $desktopSizeFactor);
    }
  }
}

.bubble3 {
  width: 290px * .75;
  max-width: 290px * .75;
  height: 163px * .75;
  max-height: 163px * .75;
  padding: 28px;
  background-image: url('./assets/bubble3.png');

  @media screen and (min-width: $tabletWidth) {
    width: 273px;
    max-width: 273px;
    height: 158px;
    max-height: 158px;
    padding: 80px * $tabletSizeFactor;
    background-image: url('./assets/desktop/bubble3.png');

    &.mirroredOnDesktop {
        background-image: url('./assets/desktop/bubble3-mirrored.png');
    }
  }

  @media screen and (min-width: $desktopWidth) {
    width: 440px * $desktopSizeFactor;
    max-width: 440px * $desktopSizeFactor;
    height: 248px * $desktopSizeFactor;
    max-height: 248px * $desktopSizeFactor;
    padding: 80px * $desktopSizeFactor;
  }
}

.playSoundButton {
  position: absolute;
  bottom: 18%;
  left: 50%;
  transform: translateX(-50%);
}