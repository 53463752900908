@import '../../variables';

.wrapper {
  height: 100px;
  position: absolute;
  width: 100px;
  right: 0;
  top: 0;
  z-index: 10;
  & > div > div {
    padding-top: 4%!important;
    padding-bottom: 250px!important;
    @media screen and (max-width: $tabletWidth) {
      padding-bottom: 126px!important;
    }
  }
  & button:first-of-type {
    background-image: url('./assets/Pause_button1.png');
    bottom: 36%;
    @media screen and (max-width: $tabletWidth) {
      background-image: url('./assets/Pause_button1_mobile.png');
      padding: 16px;
      width: 112%;
      left: -8%;
    }
  }
  & button:nth-of-type(2) {
    background-image: url('./assets/Pause_button2.png');
    bottom: 10%;
    padding-top: 14px;
    width: 356px;
    left: calc(50% - (356px/2));
    @media screen and (max-width: $tabletWidth) {
      background-image: url('./assets/Pause_button2_mobile.png');
      padding: 16px;
      width: 113%;
      left: -8%;
    }
  }
}

.pauseIcon {
  position: absolute;
  top: 46px;
  right: 50px;
  cursor: pointer;
  height: 36px;
  width: auto;
  @media screen and (max-width: $tabletWidth) {
    top: 15px;
    right: 16px;
    height: 25px;
  }
}

.pauseTitle {
  @media screen and (max-width: $tabletWidth) {
    font-weight: normal;
    margin-top: 36px!important;
  }
}

.closeIcon {
  width: 40px;
  top: 48px;
  right: 38px;
  position: absolute;
  cursor: pointer;
  z-index: 99999;
  @media screen and (max-width: $tabletWidth) {
    width: 22px;
    top: 34px;
    right: 20px;
  }
}