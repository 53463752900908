@import '../../variables.scss';

.scrollTo {
  @extend .headerBase;
  background-image: url('./assets/button-background.svg');
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  padding: 10px 45px;
  font-size: 1.2em;
}
