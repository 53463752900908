@import '../../../../variables';

.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: $halftransparentdark;
  z-index: 9999;
}

.modal {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 32px;
  position: relative;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  max-width: 1000px;
  max-height: 820px;
  padding: 0;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  background-color: $lightbackground;
  overflow-x: auto;
  overflow-y: hidden;
  width: 90%;
  height: 100%;

  @media screen and (min-width: $tabletWidth) {
    width: 85vw;
    height: 85vh;
  }

  @media screen and (min-width: $desktopWidth) {
    padding: 0 40px;
    height: 95vh;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding: 50px;
  }

  h2 {
    font-size: 32px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    padding: 40px 0 0;
    width: 100vw;

    @media screen and (min-width: $tabletWidth) {
      font-size: 42px;
      padding: 30px 0 0;
      width: 100%;
    }
  }
}

.closeIcon {
  position: absolute;
  top: 40px;
  right: 30px;
  cursor: pointer;
  @media screen and (min-width: $tabletWidth) {
    top: 35px;
    right: 50px;
    left: initial;
  }
}

.bigMap {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  min-width: 853px;
  height: 100%;
  min-height: 570px;
  margin-top: -50px;
}

.museum {
  background-image: url('../../assets/Museum_map.jpg');
}

.area {
  background-image: url('../../assets/Area_map.png');
}