@import '../../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}

.decorationHorizontal {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 320px;
  height: 163px;
  position: absolute;
  left: calc(50% - 160px);
  z-index: -5;
  top: 0;
  @media screen and (min-width: $tabletWidth) {

    @include desktop-image(82%, 47vmin, unset, 433px, 9%, 0);
    left: 50%;
    transform: translateX(calc(-50% - 60px));
    top: 0;
  }
}

.bubble {
  position: absolute;
  bottom: 60%;
  left: 20%;
  display: flex;
  align-items: center;
  z-index: 70;

  @media screen and (min-width: $tabletWidth) {
    bottom: 65%;
    top: auto;
    left: 35%;
    right: auto;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 75%;
    top: auto;
    left: 40%;
    right: auto;
  }
}

.blanketArea {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 175%;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 70%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 50%;
  }

  .blanket {
    background-repeat: no-repeat;
    background-image: url('../assets/desktop/bg.png');
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    margin: 0 auto;

    @media screen and (min-width: $tabletWidth) {
      background-size: contain;
    }
  }

  &.Intro {

    .door {
      position: absolute;
      z-index: 50;
      width: 60%;
      right: 0;
      bottom: 15%;

      @media screen and (min-width: $tabletWidth) {
        width: 25%;
        right: 50%;
        transform: translateX(calc(50% + 45%));
      }
    }

    .flask {
      position: absolute;
      z-index: 50;
      left: 0;
      bottom: 48.5%;
      transform: translateY(calc(50% + 2%));
      width: 11%;
      display: none;

      @media(min-width: $tabletWidth) {
        width: 6%;
        display: block;
        left: 50%;
        bottom: 49%;
        transform: translateY(calc(50% + 6%))  translateX(calc(-50% - 500%));
      }

    }

    .rhino {
      width: 80%;
      bottom: 13%;
      right: 55%;
      position: absolute;
      z-index: 30;

      @media screen and (min-width: $tabletWidth) {
        width: 35%;
        right: 50%;
        left: 0;
        transform: translateX(calc(50% - 5%));
      }
    }

    .puppy {
      width: 35%;
      top: 55%;
      left: 72%;
      position: absolute;
      z-index: 30;

      @media(min-width: $tabletWidth) {
        left: 82%;
        width: 20%;
        top: 37%;
        z-index: 30;
      }
    }
  }
}

.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}

