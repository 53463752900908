@import '../../../../variables';

.modalButton {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 22px;
  box-sizing: border-box;
  padding: 25px;
  text-transform: uppercase;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    font-size: 32px;
    padding: 15px;
  }

  &.primary {
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/desktop/button_blue.png');
      width: 356px;
      height: 86px;
      left: calc(50% - (356px/2));
    }
  }
  &.secondary {
    background-image: url('assets/button_white.png');
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/desktop/button_white.png');
      width: 338px;
      height: 93px;
      left: calc(50% - (338px/2));
      padding-top: 0;
    }
  }
  &.primary,
  &.tertiary {
    background-image: url('assets/button_blue.png');
  }
  &.tertiary {
    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/desktop/button_blue.png');
      width: 356px;
      height: 86px;
      left: calc(50% - (356px/2));
    }
  }
  &:active, &:focus {
    outline: 0;
    outline-style: none;
    border: none;
  }
}