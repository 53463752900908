@import '../../variables';

.wrapper {
  background-color: $mediumBackColor;
  padding-top: 60px;
  // it is to prevent collapse data at footer
  border: 1px solid transparent;
}

.exhibitionBox {
  max-width: 1742px;
  margin: 0 auto;
  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 160px;
    &:nth-child(even) {
      .eventBox {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

.smallElement {
  padding-bottom: 50px;
}

.eventBox {
  @media screen and (min-width: $bigDesktopWidth) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.sectionTitle {
  font-size: 28px;
  line-height: 31px;
  text-transform: uppercase;
  font-family: "Leftover Crayon", sans-serif;
  font-weight: normal;
  opacity: .75;
  padding: 20px 25px 0;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    font-size: 40px;
    line-height: 44px;
    padding-top: 70px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    font-size: 64px;
    line-height: 69px;
  }
}

.topPostit {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('./assets/mobile/postitTop.svg');
  width: 305px;
  height: 384px;
  font-size: 16px;
  line-height: 25px;
  margin: 0 auto;
  padding: 50px 30px 0 40px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/postitTop.svg');
    width: 744px;
    height: 296px;
    font-size: 18px;
    line-height: 27px;
    padding: 70px 40px 0 60px;
  }
  & a {
    width: 104px;
    height: 40px;
    padding: 9px 0;
    box-sizing: border-box;
    @media screen and (min-width: $tabletWidth) {
      width: 175px;
      height: 50px;
      font-size: 20px;
      line-height: 21px;
      padding: 16px 0;
    }
  }
}

.scrollBox {
  width: 238px;
  position: absolute;
  bottom: 24px;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: $tabletWidth) {
    width: 472px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 36px;
  }
}

.cartTopSectionMiddle {
  background-image: url('./assets/mobile/NorbertTadeusz_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/NorbertTadeusz_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/NorbertTadeusz_card.svg');
  }
}

.cartMiddleSectionMiddle {
  background-image: url('./assets/mobile/PierreCardin_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/PierreCardin_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/PierreCardin_card.svg');
  }
}

.cartBottomSectionMiddle {
  background-image: url('./assets/mobile/WolfgangMattheuer_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/WolfgangMattheuer_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/WolfgangMattheuer_card.svg');
  }
}

.cartTopSectionBottom {
  background-image: url('./assets/mobile/AngelikaKauffmann_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/AngelikaKauffmann_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/AngelikaKauffmann_card.svg');
  }
}

.cartMiddleSectionBottom {
  background-image: url('./assets/mobile/LudwigWindstosser_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/LudwigWindstosser_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/LudwigWindstosser_card.svg');
  }
}

.cartBottomSectionBottom {
  background-image: url('./assets/mobile/PeterLindbergh_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/PeterLindbergh_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/PeterLindbergh_card.svg');
  }
}

.topLinkSection {
  text-align: center;
  margin-top: 5em;
  padding-bottom: 0;
}

.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}
