.imageContainer {
  position: relative;
}

.image, .image img {
  z-index: 9999;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.image > div {
  left: 50%;
  transform: translateX(-50%);
  animation: show 2s;
}

.image > div::before {
  position: absolute;
  z-index: -1;
  top: -40px;
  left: 50%;
  width: 269px;
  height: 74px;
  content: "";
  transform: translateX(-50%);
  background-image: url("./hook.png");
}

area {
  cursor: default;
}