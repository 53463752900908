@import '../../variables';

.calendarBody {
  background-color: $mediumBackColor;
}

.link {
  text-transform: uppercase;
  text-decoration: underline;
  color: $linkcolor;
}

.calendar {
  font-family: "Leftover Crayon", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-bottom: 2vw;
  margin-top: $navigtaionTopBarHeight;

  .arrowPrevious {
    background-image: url('./assets/arrow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50px;
    height: 30px;
    z-index: 5;
    position: absolute;
    margin: 10vw 0 0 15vw;
    cursor: pointer;

    @media screen and (max-width: $tabletWidth) {
      width: 30px;
      height: 20px;
      margin: 7.5vw 0 0 5vw;
    }

    span {
      @extend .link;
      font-size: 24px;
      margin-left: 60px;

      @media screen and (max-width: $tabletWidth) {
        display: none;
      }
    }
  }

  .showMore {
    cursor: pointer;

    @media screen and (max-width: $tabletWidth) {
      width: 100%;
      text-align: center;
      margin-top: 2vw;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    span {
      @extend .link;
      font-size: 1.5vw;
      margin-left: 10vw;

      @media screen and (max-width: $tabletWidth) {
        font-size: 8vw;
        margin: 0;
      }
    }
  }
}


.topLinkSection {
  margin-top: 0;
  padding-bottom: 2em;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    margin-top: 2em;
    padding-bottom: 4em;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    width: 1510px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
  }
}
.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}

