@import '../../variables';

.linkBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  position: absolute;
  z-index: 99;
  top: 50px;
  left: 10px;
  @media screen and (min-width: $tabletWidth) {
    top: 60px;
  }
  @media screen and (min-width: $desktopWidth) {
    top: 170px;
    left: 120px;
  }
}

.arrowIcon {
  background-image: url("../../assets/arrow_for_bubbles.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: scale(-1, 1);
  width: 44px;
  height: 24px;
  @media screen and (min-width: $tabletWidth) {
    width: 48px;
    height: 30px;
    margin-right: 14px;
    margin-left: 40px;
  }
}

.linkText {
  text-transform: uppercase;
  border-bottom: 2px solid $crayonblue;
  font-family: "Leftover Crayon", sans-serif;
  color: $crayonblue;
  font-size: 28px;
  line-height: 20px;
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}
