@import "../../../../variables";

.score {
  font-size: 1.2em;
}

@keyframes balloonAnimation {
  0% {
    z-index: 999;
  }
  99% {
    transform: translate3d(0, -300vh, 0);
  }
  100% {
    display: none;
  }
}

.balloon {
  z-index: -1;
  width: 77px;
  animation-name: balloonAnimation;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  position: absolute;
  bottom: -60vh;
  @media screen and (min-width: $tabletWidth) {
    width: 18vmin;
    max-width: 240px;
  }
}

.balloonYellow {
  @extend .balloon;
  right: 10%;
  animation-delay: .6s;
}

.balloonPink {
  @extend .balloon;
  right: 0;
}

.balloonOrange {
  @extend .balloon;
  left: 40%;
  animation-delay: .9s;
}

.balloonRed {
  @extend .balloon;
  left: 0;
  animation-delay: .1s;
}

.balloonBlue {
  @extend .balloon;
  left: 10%;
  animation-delay: .4s;
}