@import '../../../../variables';

.pictureBox {
  width: 100%;
  position: relative;
  padding-top: 30px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.tape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
}

.tape1 {
  background: url('../../assets/tape-beige.svg');
  width: 114px;
  height: 26px;
  margin-top: 15px;
  @media screen and (min-width: $tabletWidth) {
    width: 224px;
    height: 50px;
    margin-top: 5px;
  }
}

.tape2 {
  background: url('../../assets/tape-blue.svg');
  width: 84px;
  height: 30px;
  margin-top: 10px;
  @media screen and (min-width: $tabletWidth) {
    width: 176px;
    height: 54px;
    margin-top: -7px;
  }
}

.tape3 {
  background: url('../../assets/tape-darkblue.svg');
  width: 128px;
  height: 26px;
  margin-top: 14px;
  @media screen and (min-width: $tabletWidth) {
    width: 272px;
    height: 50px;
    margin-top: -5px;
  }
}

.artElement {
  width: 306px;
  margin: 0 auto;
  display: block;
  @media screen and (min-width: $tabletWidth) {
    max-height: 650px;
    height: auto;
    max-width: 650px;
    width: auto;
  }
}

.artDescription {
  font-size: 14px;
  line-height: 18px;
  max-width: 306px;
  margin: 14px auto;
  padding-bottom: 10px;
  @media screen and (min-width: $tabletWidth) {
    font-size: 18px;
    line-height: 25px;
    max-width: 648px;
    padding-bottom: 40px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 0;
  }

  p {
    display: initial;
  }
}
