@import '../../../../variables';

.spritesheet {
  position: fixed;
  bottom: 44px;
  left: 0;
  width: 100vw;

  @media screen and (min-width: $tabletWidth + 1) {
    display: none;
  }
}

.spritesheetDesktop {
  position: fixed;
  bottom: 3vw;
  width: 20vw;
  right: 12vw;

  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.artPieces {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100vw;
  margin-top: 30px;
  padding: 22px;

  @media screen and (min-width: $tabletWidth) {
    padding: 2vw 0 0 5vw;
    margin: 0;
    flex-wrap: nowrap;
  }
}

.artPiece01,
.artPiece02,
.artPiece03,
.artPiece04 {
  flex: 0 1 40%;
  height: 44vw;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media screen and (min-width: $tabletWidth) {
    flex: 0 1 auto;
    height: 32vw;
  }
}

.artPiece01 {
  background-image: url('./assets/artpiece01.png');
  max-width: 214px;
  max-height: 270px;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/artpiece01.png');
    max-width: 436px;
    max-height: 641px;
    width: 22vw;
    margin-top: 5vw;
  }
}

.artPiece02 {
  margin: 15px 15px 30px 0;
  background-image: url('./assets/artpiece02.png');
  max-width: 246px;
  max-height: 176px;
  width: 25vw;
  height: 24vw;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/artpiece02.png');
    max-width: 395px;
    max-height: 603px;
    width: 25vw;
    height: 35vw;
    margin-top: 3vw;
  }
}

.artPiece03 {
  box-sizing: border-box;
  margin: 20px 0 0 0;
  background-image: url('./assets/artpiece03.png');
  max-width: 224px;
  max-height: 314px;
  height: 47vw;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/artpiece03.png');
    max-width: 445px;
    max-height: 526px;
    width: 24vw;
    height: 26vw;
    margin-top: 6vw;
  }
}

.artPiece04 {
  background-image: url('./assets/artpiece04.png');
  max-width: 270px;
  max-height: 398px;
  height: 59vw;
  flex: 0 1 47%;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/artpiece04.png');
    max-width: 348px;
    max-height: 663px;
    width: 19vw;
    height: 35vw;
    margin-top: 4vw;
    flex: 0 1 auto;
  }
}

.floor {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 25vw;
  max-height: 88px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('./assets/floor.png');

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/floor.png');
    height: 10vw;
    max-height: 237px;
  }
}

.posts {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('./assets/desktop/posts.png');
  width: 58vw;
  max-width: 1108px;
  height: 15vw;
  max-height: 283px;
  z-index: 1;
  position: fixed;
  bottom: 5vw;

  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.bubble { // TODO deduplicate code, possible create Bubble component?
  font-family: "Leftover Crayon", sans-serif;
  font-size: 18px;
  color: $crayondarkgrey;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: -3vw;
  bottom: 75vw;
  box-sizing: border-box;
  width: 77vw;
  max-width: 374px;
  height: 67vw;
  max-height: 320px;
  padding: 15px 30px 45px;
  background-image: url('./assets/bubble.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/bubble.png');
    max-width: 383px;
    max-height: 404px;
    width: 20vw;
    height: 22vw;
    box-sizing: border-box;
    right: 30vw;
    bottom: 45vh;
    font-size: 1.25vw;

    .bubbleScore {
      font-size: 1.75vw;
    }
  }
}

.gift {
  position: absolute;
  right: 40vw;
  bottom: 10vw;
  width: 15vw;
  min-width: 70px;
  height: auto;

  @media screen and (min-width: $tabletWidth) {
    right: 30vw;
    bottom: 4vw;
    width: 11vw;
    transform: none;
  }
}