@import '../../../../../../variables.scss';

@mixin animateAnswer {
  animation-name: appear;
  animation-duration: 140ms;
  animation-timing-function: linear;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.quizAnswer {
  font-family: 'Leftover Crayon', sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  margin-bottom: 4vw;
  padding: 7.5vw 6vw 6vw;
  text-align: center;
  text-transform: uppercase;
  color: $crayondarkgrey;
  z-index: 2;

  @media screen and (min-width: $tabletWidth) {
    width: 100%;
    font-size: 18px;
    line-height: 1.5vw;
    padding: 2vw 1vw 0.5vw;
    margin-bottom: 4vw;
    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: $desktopWidth) {
      font-size: 24px;
    }
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 90vw;
    height: 20.2vw; // this it to preserve aspect ratio
    content: "";
    transition: filter 140ms linear;
    transform: translate3d(-50%, 0, 0);
    background-image: url('./assets/button1_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (min-width: $tabletWidth) {
      width: 100%;
      height: 6vw;
      background-image: url('./assets/desktop/button1_bg.png');
    }
  }

  &.isChecked::before {
    background-image: url('./assets/button1_bg_red.png');
    @include animateAnswer;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/button1_bg_red.png');
    }
  }

  &.isChecked.correct::before {
    background-image: url('./assets/button1_bg_green.png');

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/button1_bg_green.png');
    }
  }

  &.correct::before {
    background-image: url('./assets/button1_bg_correct.png');
    @include animateAnswer;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('./assets/desktop/button1_bg_correct.png');
    }
  }

  &:nth-of-type(2) {
    &::before {
      background-image: url('./assets/button2_bg.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button2_bg.png');
      }
    }

    &.isChecked::before {
      background-image: url('./assets/button2_bg_red.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button2_bg_red.png');
      }
    }

    &.isChecked.correct::before {
      background-image: url('./assets/button2_bg_green.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button2_bg_green.png');
      }
    }

    &.correct::before {
      background-image: url('./assets/button2_bg_correct.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button2_bg_correct.png');
      }
    }
  }

  &:nth-of-type(3) {
    &::before {
      background-image: url('./assets/button3_bg.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button3_bg.png');
      }
    }

    &.isChecked::before {
      background-image: url('./assets/button3_bg_red.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button3_bg_red.png');
      }
    }

    &.isChecked.correct::before {
      background-image: url('./assets/button3_bg_green.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button3_bg_green.png');
      }
    }

    &.correct::before {
      background-image: url('./assets/button3_bg_correct.png');

      @media screen and (min-width: $tabletWidth) {
        background-image: url('./assets/desktop/button3_bg_correct.png');
      }
    }
  }
}