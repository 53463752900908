@import 'fonts/fonts.css';

@font-face{
  font-family:"Leftover Crayon";
  src:url("./assets/fonts/LeftoverCrayon-Regular.eot?#iefix");
  src:url("./assets/fonts/LeftoverCrayon-Regular.eot?#iefix") format("eot"),url("./assets/fonts/LeftoverCrayon-Regular.woff2") format("woff2"),url("./assets/fonts/LeftoverCrayon-Regular.woff") format("woff"),url("./assets/fonts/LeftoverCrayon-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
