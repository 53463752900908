@import "../../variables";

.wrapper {
  margin-top: 100px;
  background-image: url('../../assets/background.png');
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  padding: 0 10px;
  @media screen and (min-width: $desktopWidth) {
    padding: 0;
  }
  & section {
    width: 100%;
    @media screen and (min-width: $tabletWidth) {
      width: 50%;
    }
    @media screen and (min-width: $desktopWidth) {
      width: 32%;
    }

    @media screen and (min-width: 1500px) {
      width: 25%;
    }
  }
  @media screen and (min-width: $tabletWidth) {
    background-image: url('../../assets/bckg.png');
  }
  & section:first-of-type {
    & > a {
      margin-top: 0;
      @media screen and (min-width: $desktopWidth) {
        margin-top: -28px;
      }
    }
  }
  & section:nth-of-type(2) {
    margin-bottom: 12px;
    @media screen and (min-width: $tabletWidth) {
      margin-top: 92px;
    }
    @media screen and (min-width: $desktopWidth) {
      margin-top: initial;
    }
    & > a {
      margin-top: -92px;
      @media screen and (min-width: $desktopWidth) {
        margin-top: -28px;
      }
    }
  }
  & section:nth-of-type(3) {
    & > a {
      margin-top: -40px;
      @media screen and (min-width: $desktopWidth) {
        margin-top: -28px;
      }
    }
    & > p {
      padding-right: 20px;
      box-sizing: border-box;
    }
  }
  & section:nth-of-type(4) {
    @media screen and (min-width: $tabletWidth) {
      margin-top: 59px;
    }
    @media screen and (min-width: $desktopWidth) {
      margin-top: initial;
    }
    & > a {
      @media screen and (min-width: $desktopWidth) {
        margin-top: -28px;
      }
    }
    & > div {
      top: 27px;
    }
  }

  & section:nth-of-type(5) {
    @media screen and (min-width: $tabletWidth) {
      margin-top: 59px;
    }
    @media screen and (min-width: $desktopWidth) {
      margin-top: initial;
    }
    & > a {
      margin-top: -100px;
      @media screen and (min-width: $desktopWidth) {
        margin-top: -28px;
      }
    }
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.headingSubtext {
  display: none;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
  @media screen and (min-width: $tabletWidth) {
    padding-top: 130px;
    display: block;
  }
  @media screen and (min-width: $desktopWidth) {
    padding-top: 0;
    display: none;
  }
}

header {
  height: 100px;
  background-color: $lightbackground;
  width: 100%;
  display: flex;
  padding-top: 10px;
  @media screen and (max-width: $desktopWidth) {
    background-color: inherit;
    margin: 0 auto;
  }
  img {
    margin: 0 auto;
  }
}

.headingBox {
  width: 100%;
  margin: 0 0 76px;
  position: relative;
  &::before {
    background-image: url('components/assets/yellowBackgroundDesktop.png');
    content: "";
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    width: 280px;
    height: 120px;
    max-width: 920px;
    margin: 0 auto;
    @media screen and (min-width: $tabletWidth) {
      left: 22%;
      width: 60%;
      margin: 0;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      top: 0;
      margin: -10px auto 0;
      left: 0;
      right: 0;
    }
  }
  @media screen and (min-width: $tabletWidth) {
    margin-top: 50px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: $desktopWidth) {
    margin-top: 60px;
    margin-bottom: 120px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    margin-top: 80px;
    margin-bottom: 180px;
  }
}

.headingText {
  text-align: center;
  text-transform: uppercase;
  font-family: "Leftover Crayon", sans-serif;
  font-weight: normal;
  font-size: 28px;
  line-height: 31px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 280px;

  &::after {
    content: "";
    position: absolute;
    right: 30px;
    bottom: 0;
    background-image: url(./components/assets/gift.png);
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotate(45deg);
    width: 30px;
    height: 30px;

    @media screen and (min-width: $tabletWidth) {
      right: -60px;
      bottom: 0;
      width: 58px;
      height: 58px;
    }

    @media screen and (min-width: $desktopWidth) {
      right: -50px;
      bottom: 20px;
      width: 71px;
      height: 71px;
    }
  }

  @media screen and (min-width: $tabletWidth) {
    font-size: 30px;
    line-height: 33px;
    max-width: 406px;
    padding-top: 20px;
  }
  @media screen and (min-width: $desktopWidth) {
    padding-top: 0;
    font-size: 48px;
    line-height: 52px;
    max-width: 700px;
  }
}

.notebook {
  position: absolute;
  background-image: url('components/assets/notebook.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 26px;
  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.margeSign {
  border: 1px solid $margecolor;
  height: 100vh;
  position: absolute;
  left: 4.4%;
  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.highlight {
  content: '';
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 50px;
}

.informationWrapper {
  width: 100%;
  height: 120px;
  position: relative;

  @media screen and (min-width: $tabletWidth) {
    display: none;
  }

  .informationText {
    position: absolute;
    left: calc(50% + 35px);
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    max-width: 140px;

    &::before {
      content: "";
      background: url('./components/assets/laptop.png') no-repeat;
      background-size: 100% 100%;
      width: 53px;
      height: 37px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -50px;
    }

    &::after {
      content: "";
      background: url("./components/assets/highlight.png") no-repeat;
      background-size: 100% 100%;
      width: 243px;
      height: 63px;
      position: absolute;
      left: calc(50% - 30px);
      transform: translateX(-50%);
      top: -10px;
      z-index: -1;
    }
  }
}

.informationText {
  color: $black;
  font-size: 24px;
  text-transform: uppercase;
  font-family: "Leftover Crayon", sans-serif;
  text-align: center;
}

.cloudSmall {
  position: absolute;
  width: 116px;
  top: 14%;
  left: 28%;
  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.cloudMedium {
  position: absolute;
  width: 200px;
  top: 12%;
  right: 4%;
  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.cloudLarge {
  position: absolute;
  width: 160px;
  top: 15%;
  left: 5%;
  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.backLink {
  @media screen and (min-width: $desktopWidth) {
    top: 210px !important;
    left: 6% !important;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    top: 230px !important;
    left: 8% !important;
  }
}

.naviWhiteBackground {
  background-color: #fff;
}
