@import '../../../../variables.scss';

$desktopScoresHeight: 85px;
$tabletPointBoxesHeight: 88px;
$desktopPointBoxesHeight: 110px;

.gameArea {
  position: fixed;
  top: 0;
  bottom: 10px;
  width: 100vw;
  padding: 5px;
}

.logo {
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.blanketArea {
  @media screen and (min-width: $tabletWidth) {
    width: 90%;
    max-width: 1762px;
    //5vmin of overlap to accomodate the blank space in the image:
    height: calc(100% - #{$desktopScoresHeight} - #{$desktopPointBoxesHeight} + 5vmin);
    max-height: 80vw;
    bottom: 1vh;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 0;

    @media screen and (min-width: $desktopWidth) {
      height: calc(100% - #{$desktopScoresHeight} - #{$desktopPointBoxesHeight} + 5vmin);
    }

    @media screen and (max-aspect-ratio: 1 / 1) {
      bottom: calc(50% - 50px);
      transform: translate(-50%, 50%);
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url('assets/desktop/blanket.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .rhino {
    background-image: url('assets/desktop/rhino.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 24vw;
    max-width: 364px;
    height: 30vw;
    max-height: 456px;
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);

    @media screen and (max-width: $tabletWidth) {
      display: none;
    }
  }

  .bird {
    background-image: url('assets/desktop/bird.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20vw;
    max-width: 372px;
    height: 14vw;
    max-height: 250px;
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translateY(-50%);

    @media screen and (max-width: $tabletWidth) {
      display: none;
    }
  }
}

.margeSign {
  border: 1px solid $margecolor;
  height: 100vh;
  position: absolute;
  left: 4.4%;
  z-index: 0;
  top: 0;

  @media screen and (max-width: $desktopWidth) {
    display: none;
  }
}

.tiles {
  display: flex;
  align-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 500px;
  height: 72vh;
  margin-right: auto;
  margin-left: auto;
  perspective: 600px;

  @media screen and (min-width: $tabletWidth) {
    max-width: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;

    @media screen and (max-aspect-ratio: 20 / 14) {
      width: 40%;
    }

    @media screen and (min-aspect-ratio: 20 / 14) {
      width: 30%;
    }
  }

  .tile {
    backface-visibility: hidden;
    position: relative;
    flex: 0 0 20.8vw;
    max-width: 105px;
    height: 25.46666667vw;
    max-height: 158px;
    transition: transform .4s cubic-bezier(.455, .030, .515, .955);
    transform-style: preserve-3d;

    @media screen and (min-width: $tabletWidth) {
      flex: 0 0 23%;
      height: 23%;
      max-height: unset;
      max-width: unset;
    }

    &.chosen {
      transform: rotateX(180deg);

      .tileImg {
        visibility: visible;
      }
    }

    .tileUnflipped {
      position: absolute;
      z-index: 2;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      content: "";
      background-repeat: no-repeat;
      background-size: 100% 100%;
      backface-visibility: hidden;
    }

    .tileBlue {
      background-image: url('assets/tile01.png');
      @media screen and (min-width: $tabletWidth) {
        background-image: url('assets/desktop/tile01.png');
      }
    }

    .tileYellow {
      background-image: url('assets/tile02.png');
      @media screen and (min-width: $tabletWidth) {
        background-image: url('assets/desktop/tile02.png');
      }
    }

    .tileGreen {
      background-image: url('assets/tile03.png');
      @media screen and (min-width: $tabletWidth) {
        background-image: url('assets/desktop/tile03.png');
      }
    }

    .tileImg {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotateX(180deg);
      object-fit: cover;
      backface-visibility: hidden;
    }
  }
}

.pointBoxes {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  height: 54px;
  flex-wrap: wrap;

  @media screen and (min-width: $tabletWidth) {
    padding: 0 6vw;
    height: $tabletPointBoxesHeight;
  }

  @media screen and (min-width: $desktopWidth) {
    height: $desktopPointBoxesHeight;
  }

  .scoreLabel {
    font-weight: bold;
    font-size: 14px;
    margin-top: 8px;

    @media screen and (min-width: $tabletWidth) {
      font-size: 20px;
      margin: 0;
      flex: 0 1 calc(100% - 280px);
      display: flex;
    }

    @media screen and (min-width: $desktopWidth) {
      font-size: 24px;
      margin: 0;
      flex: 1 1 auto;
      display: flex;
    }

    span {
      display: block;
      max-width: 65px;
      text-align: center;

      @media screen and (min-width: $tabletWidth) {
        display: flex;
        max-width: unset;
        margin-right: auto;
        line-height: 90px;
        text-align: unset;
      }

      @media screen and (min-width: $desktopWidth) {
        line-height: 110px;
      }

      &.labelBird {
        max-width: 85px;

        @media screen and (min-width: $tabletWidth) {
          max-width: unset;
          margin-right: unset;
          margin-left: auto;
        }
      }
    }
  }
}

.scores {
  display: flex;
  justify-content: space-around;
  padding: 0 25px;
  height: 35px;
  flex-wrap: wrap;
  @media screen and (min-width: $tabletWidth) {
    justify-content: center;
    align-items: center;
    height: $desktopScoresHeight;
  }
}