@import '../../../../variables';

.title {
  display: flex;
  line-height: 1.25vw;
  margin: 0.5vw 0;
  cursor: pointer;

  h3 {
    max-width: 80%;
    text-align: justify;
  }

  @media screen and (max-width: $tabletWidth) {
    line-height: 6vw;
    margin: 3.5vw 0;

    h3 {
      max-width: unset;
      text-align: unset;
      padding-right: 7.5vw;
      font-size: 5.5vw;
    }
  }
 
  .expandButton {
    width: 1.25vw;
    max-width: 52px;
    height: 1.25vw;
    max-height: 52px;
    margin-left: 1vw;
    margin-top: .45vw;
    background-image: url('../../assets/arrow_left.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotate(-90deg);

    &.expanded {
      transform: rotate(90deg);
    }

    @media screen and (max-width: $tabletWidth) {
      width: 20px;
      height: 25px;
      position: absolute;
      right: 10vw;
    }
  }
}

.longDescription {
  display: none;
  margin: .5vw 0;

  &.expanded {
    display: block;
  }

  @media screen and (max-width: $tabletWidth) {
    margin: 3.5vw 0;
  }
}

.shortDescription {
    max-width: 80ex;
}