// Media queries

// Width
$mobileWidth: 320px;
$tabletWidth: 760px;
$desktopWidth: 1025px;
$bigDesktopWidth: 1679px;

// Colors
$lightgreen: #f6fbf4;
$black: #000;
$crayondarkgrey: #4a4a4a;
$crayonmidgrey: #666;
$crayonlightgray: #a0a0a0;
$crayonblue: #002e88;
$linkcolor: #10378e;
$halftransparentdark: rgba(0, 0, 0, 0.5);
$lightbackground: #fff;
$margecolor: #89618a;
$mediumBackColor: #f1f6f5;
$lightyellowbackground: #fdfdf9;
$yellowCardColor: #FFF5AD;
$lightbluebackground: #bde0e7;
$boxshadow: rgba(214, 214, 214, 1);
$dateColor: #032C89;

// Stickers
$stickerblue: $lightbluebackground;
$stickerred: #fedad7;
$stickerbeige: #d7b987;

// Navigation top bar
$navigtaionTopBarHeight: 100px;

// Headers
.headerBase {
  color: $black;
  font-family: 'Leftover Crayon', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

// H1
$desktopH1Size: 48px;
$tabletH1Size: 48px;
$mobileH1Size: 28px;

// H2
$desktopH2Size: 48px;
$tabletH2Size: 40px;
$mobileH2Size: 28px;

// H3
$desktopH3Size: 32px;
$desktopH3SizeDecreased: 24px;
$tabletH3Size: 26px;
$mobileH3Size: 22px;

// H4
$desktopH4Size: 22px;
$tabletH4Size: 22px;
$mobileH4Size: 18px;

// Layout

// flex
.displayFlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

// common elements

.linksBase {
  @extend .headerBase;
  cursor: pointer;
  font-size: 1.2em;
}

.listsBase {
  padding-inline-start: 0;
  list-style-position: inside;
  margin: 0.4em 0 0 0;
}
