@import '../../../../variables';

.frame {
  background-image: url('../../assets/box.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  width: 267px;
  height: 184px;
  padding: 11px 10px 10px 8px;
  margin: 0 auto;

  @media screen and (min-width: $desktopWidth) {
    width: 404px;
    height: 278px;
    padding: 17px 10px 10px 12px;
  }
}

.videoContainer {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../../assets/video.jpg');
  width: 250px;
  height: 166px;

  @media screen and (min-width: $desktopWidth) {
    width: 376px;
    height: 250px;
  }
}

.playSign {
  background-image: url('../../assets/playVideo.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 90px;
  height: 90px;
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  margin: 0 auto;
  @media screen and (min-width: $desktopWidth) {
    width: 148px;
    height: 148px;
    top: 65px;
  }
}

.tv {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../../assets/desktop/tv.png');
  width: 70px;
  height: 56px;
  position: absolute;
  right: -30px;
  bottom: -14px;
  @media screen and (min-width: $desktopWidth) {
    width: 104px;
    height: 80px;
  }
}
