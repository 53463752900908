@import '../../../../variables';

.detectiveOutro {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  // We use coarse to determine tablet here and use corrected viewport height for the browser's chrome
  @media screen and (max-width: $desktopWidth) and (pointer: coarse) {
    height: calc(100vh - 65px);
  }

  header {
    height: 100px;
    background-color: $lightbackground;
  }
}

.margeSign {
  border: 1px solid $margecolor;
  height: 100vh;
  position: absolute;
  left: 3%;
}

.spritesheet {
  width: 480px;
  position: absolute;
  margin: auto;
  left: 1%;
  bottom: 6%;
  z-index: 3;

  @media screen and (min-width: $desktopWidth) {
    width: 480px;
    bottom: 16%;
    left: 8%;
    height: auto;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 20%;
    width: 700px;
  }
}

.image {
  z-index: 1;
}

.cloudLarge {
  position: absolute;
  top: 16%;
  left: 4%;
  z-index: 0;
  width: 150px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.cloudSmall {
  position: absolute;
  top: 11%;
  left: 26%;
  width: 100px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.cloudTiny {
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 1;
  width: 120px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.lawn {
  background-color: #DBEEC8;
  position: absolute;
  bottom: 0;
  height: 40vh;
  width: 100%;
  z-index: -1;
}

.gift {
  position: absolute;
  bottom: 4%;
  right: calc(3% + 270px);
  width: 110px;
  z-index: 9;

  @media screen and (min-width: $desktopWidth) {
    width: 110px;
    bottom: 14%;
    right: calc(8% + 330px);
  }

  @media screen and (min-width: $bigDesktopWidth) {
    width: 110px;
    bottom: 15%;
    right: calc(16% + 330px);
  }
}

.grassSmall {
  position: absolute;
  bottom: 20%;
  left: 2%;
  width: 160px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.grassMedium {
  position: absolute;
  bottom: 24%;
  right: 0;
  width: 100px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.grassLarge {
  position: absolute;
  bottom: 2%;
  right: 2%;
  width: 110px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.flowerViolet {
  position: absolute;
  bottom: 16%;
  right: 4%;
  width: 50px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.flowerFieldPoppy {
  position: absolute;
  bottom: 10%;
  left: 12%;
  width: 40px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.flowerBig {
  position: absolute;
  bottom: -2%;
  left: 20%;
  transform: scale(-0.6, 0.6);
  width: 110px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.flowerDaisy {
  position: absolute;
  bottom: 4%;
  left: 46%;
  width: 50px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.flowerSowThistle {
  position: absolute;
  bottom: 4%;
  left: 4%;
  transform: scale(.7);
  width: 66px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.bunch {
  position: absolute;
  right: 3%;
  bottom: 6%;
  width: 500px;
  z-index: 2;

  @media screen and (min-width: $desktopWidth) {
    width: 600px;
    bottom: 16%;
    right: 8%;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.museum {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 35%;
  width: 530px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: auto;
  }
}

.bubble {
  background-image: url('../../../../assets/Bubble1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 222px;
  height: 242px;
  position: absolute;
  top: -100px;
  right: -80px;
  z-index: 9;
  @media screen and (min-width: $bigDesktopWidth) {
    top: -20px;
  }
}

.bubbleText {
  font-family: "Leftover Crayon", sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: $crayondarkgrey;
  font-size: 18px;
  max-width: 180px;
  position: absolute;
  left: 32px;
  top: 40px;
}

.modalCopy {
  margin-bottom: 10px;
}

:export {
  desktopWidth: $desktopWidth;
}