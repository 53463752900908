@import '../../variables';

.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: $halftransparentdark;
  z-index: 9999;
}

.modal {
  font-family: "Leftover Crayon", sans-serif;
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 75vw;
  max-width: 440px;
  padding: 10% 30px 110px;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  text-transform: uppercase;
  background-image: url('assets/box1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('assets/desktop/box1.png');
    max-width: 576px;
    padding: 5% 5% 10%;
    line-height: 1.55;
  }

  &.morePadding {
    padding: 10% 30px 160px;
  }

  &.long {
    background-image: url('assets/box3.png');
    padding: 12% 40px 110px;
    width: 78vw;

    &.morePadding {
      padding: 12% 40px 160px;

      @media screen and (min-width: $tabletWidth) {
        padding: 5% 5% 190px;
      }
    }

    @media screen and (min-width: $tabletWidth) {
      background-image: url('assets/desktop/box3.png');
      max-width: 576px;
      padding: 5% 5% 160px;
    }
  }

  &.short {
    background-image: url('assets/box2.png');
  }

  &.withImage {
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px 160px;
  }
}

.modal h1 {
  font-size: 30px;
  font-weight: normal;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  z-index: 1;
  @media screen and (min-width: $tabletWidth) {
    font-size: 40px;
    font-weight: 300;
  }
}

.modal p {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  @media screen and (min-width: $tabletWidth) {
    font-size: 22px;
  }
}

.button {
  position: absolute;
  right: -10px;
  bottom: 40px;
  left: -10px;
  width: 105%;
  @media screen and (min-width: $tabletWidth) {
    bottom: 10%;
  }

  &.morePadding {
    bottom: 90px;
    @media screen and (min-width: $tabletWidth) {
      bottom: 25%;
    }
  }
  &.alternate {
    bottom: 20px;
    @media screen and (min-width: $tabletWidth) {
      bottom: 5%;
    }
  }
}

.image {
  width: 60%;
  max-width: 350px;
  padding-top: 150px;
  padding-left: 50px;
}

.boxImage {
  width: 40%;
  display: inline-block;
  position: relative;

  .button {
    &.morePadding {
      @media screen and (min-width: $tabletWidth) {
        bottom: -38%;
      }
    }
    &.alternate {
      @media screen and (min-width: $tabletWidth) {
        bottom: -80%;
      }
    }
  }
}

.giftModal {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -10px;
  width: 87px;
  height: 89px;
  background-image: url('assets/gift.png');
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(45deg);
}