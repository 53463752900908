@import '../../../../../variables';

@mixin desktop-image($width, $height, $maxwidth, $maxheight, $left, $zindex: 1, $bottom: null, $top: null) {
  z-index: $zindex;
  width: $width;
  height: $height;
  max-width: $maxwidth;
  max-height: $maxheight;
  position: absolute;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $top {
    top: $top;
  }
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  z-index: 100;
}

.bubble1 {
  left: 0;
  bottom: 50%;
  z-index: 60;
  position: absolute;
  width: 60%;
  display: flex;
  align-items: center;

  @media (min-width: $tabletWidth) {
    bottom: 50%;
    left: 25%;
    width: 60% !important;
  }

  @media (min-width: 1200px) {
    bottom: 60%;
    left: 40%;
  }

  @media (min-width: 1800px) {
    bottom: 60%;
    left: 30%;
  }
}


.blanketArea {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 150%;

  @media screen and (min-width: $tabletWidth) {
    padding-bottom: 50%;
    height: auto;
    max-height: unset;
  }

  .blanket {
    background-repeat: no-repeat;
    background-image: url('../assets/bg.png');
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;

    @media screen and (min-width: $tabletWidth) {
      background-image: url('../assets/desktop/bg.png');
      background-position: center center;
      background-size: cover;
      margin: 0;
      display: flex;
      bottom: 0;
      z-index: 10;
    }
  }


  &.Intro {
    .rhino {
      width: 50%;
      bottom: 10%;
      left: 0;
      position: absolute;
      z-index: 30;
      max-width: 500px;

      @media (min-width: $tabletWidth) {
        left: 0;
        width: 33%;
        bottom: 10%;
      }

      @media (min-width: 1200px) {
        left: 10%;
      }
    }

    .puppy {
      width: 35%;
      bottom: 15%;
      left: 72%;
      position: absolute;
      z-index: 30;
      max-width: 400px;

      @media(min-width: $tabletWidth) {
        left: 82%;
        width: 20%;
        top: 37%;
        z-index: 30;
      }
    }

    .crane {
      width: 90%;
      position: absolute;
      left: 7%;
      bottom: 40%;
      z-index: 1;

      @media(min-width: $tabletWidth) {
        left: 30%;
        width: 40%;
        bottom: 40%;
      }
    }
  }
}


.hideOnMobile {
  display: none;
  @media screen and (min-width: $tabletWidth) {
    display: block;
  }
}

