@import '../../../../../variables';

@keyframes hide-layer {
  0% {
    opacity: 0;
    z-index: 999;
  }
  100% {
    opacity: .9;
  }
}

@keyframes move-to-right {
  0% {
    left: 0;
  }
  100% {
    left: 50%;
  }
}

.animatedSpritesheet {
  left: 50%;
  animation: move-to-right 4s;
}

.spritesheet {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 10vh;
  width: 700px;
  height: 350px;
  margin: auto;
  @media screen and (min-width: $bigDesktopWidth) {
    bottom: 18vh;

    left: -260px;
    & div {
      width: 980px;

      & div {
        width: 800px !important;
      }
    }
  }
}

.bubble { // TODO use Bubble component
  font-family: "Leftover Crayon", sans-serif;
  font-size: 20px;
  line-height: 22px;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 94px;
  width: 200px;
  padding: 16px 30px 0;
  transform: translateY(-50%);
  text-align: center;
  text-transform: uppercase;
  color: $crayondarkgrey;

  @media screen and (min-width: $desktopWidth) {
    font-size: 22px;
    width: 240px;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    top: 30px;
    right: -30px;
  }
}

.bubble::before {
  position: absolute;
  z-index: -1;
  top: -45px;
  right: -40px;
  bottom: -70px;
  left: -50px;
  content: "";
  background-image: url(../../../../../assets/Bubble1.png); // TODO should use only one file for it (I copied it for now)
  background-size: 100% 100%;
}

.layerTextDark {
  font-family: "Leftover Crayon", sans-serif;
  position: absolute;
  font-size: 28px;
  top: 26%;
  left: 10%;
  text-transform: uppercase;
  color: black;

  @media screen and (min-width: $desktopWidth) {
    font-size: 44px;
  }
}

.floor {
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 20vh;
  background-image: url('../assets/floor.png');
  background-repeat: repeat-x;
}

.frameLeft {
  position: absolute;
  top: 40%;
  left: 10%;
  width: 180px;
  @media screen and (min-width: $bigDesktopWidth) {
    top: 40%;
    width: 260px;
  }
}

.frameRight {
  position: absolute;
  top: 16%;
  right: 5%;
  width: 240px;
  @media screen and (min-width: $bigDesktopWidth) {
    width: 400px;
  }
}