@import '../../../../variables';

.mapComponent {
  margin-top: 70px;
  width: 330px;
  height: 330px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    margin-left: 80px;
  }
}

.mapMuseum {
  background-image: url('../../assets/Card_map_1.svg');
}

.mapArea {
  background-image: url('../../assets/Card_map_2.svg');
}

.mapHeader {
  margin-bottom: 20px;
}

.mapContent {
  margin-left: 30px;
  cursor: pointer;
  transform: rotate(4deg);
  width: 260px;
  height: 170px;
  background-color: $lightbackground;
  display: block;
  position: relative;
  background-size: cover;
}

.minimap {
  content: '';
  width: 260px;
  height: 170px;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  transform: rotate(-4deg);
}
