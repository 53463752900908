@import '../../variables';

.wrapper {
  background-color: $mediumBackColor;
  padding-top: 60px;
  // it is to prevent collapse data at footer
  border: 1px solid transparent;
}

.exhibitionBox {
  max-width: 1462px;
  margin: 0 auto;
  &:nth-child(even) {
    .movieBox {
      flex-direction: row-reverse;
    }
  }

  @media screen and (min-width: $bigDesktopWidth) {
    padding-bottom: 50px;
    &:nth-child(even) {
      .eventBox {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

.smallElement {
  padding-bottom: 50px;
}

.eventBox {
  @media screen and (min-width: $bigDesktopWidth) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.sectionTitle {
  font-size: 28px;
  line-height: 31px;
  text-transform: uppercase;
  font-family: "Leftover Crayon", sans-serif;
  font-weight: normal;
  opacity: .75;
  padding: 20px 25px 0;
  text-align: center;
  @media screen and (min-width: $tabletWidth) {
    font-size: 40px;
    line-height: 44px;
    padding-top: 70px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    font-size: 64px;
    line-height: 69px;
  }
}

.topPostit {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('./assets/mobile/postitTop.svg');
  width: 305px;
  height: 384px;
  font-size: 16px;
  line-height: 25px;
  margin: 0 auto;
  padding: 50px 30px 0 40px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/postitTop.svg');
    width: 744px;
    height: 296px;
    font-size: 18px;
    line-height: 27px;
    padding: 70px 40px 0 60px;
  }
  & a {
    width: 104px;
    height: 40px;
    padding: 9px 0;
    box-sizing: border-box;
    @media screen and (min-width: $tabletWidth) {
      width: 175px;
      height: 50px;
      font-size: 20px;
      line-height: 21px;
      padding: 16px 0;
    }
  }
}

.scrollBox {
  width: 238px;
  position: absolute;
  bottom: 24px;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: $tabletWidth) {
    width: 472px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 36px;
  }
}

.cartTopSectionMiddle {
  background-image: url('./assets/mobile/NorbertTadeusz_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/NorbertTadeusz_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/NorbertTadeusz_card.svg');
  }
}

.cartMiddleSectionMiddle {
  background-image: url('./assets/mobile/PierreCardin_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/PierreCardin_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/PierreCardin_card.svg');
  }
}

.cartBottomSectionMiddle {
  background-image: url('./assets/mobile/WolfgangMattheuer_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/WolfgangMattheuer_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/WolfgangMattheuer_card.svg');
  }
}

.cartTopSectionBottom {
  background-image: url('./assets/mobile/AngelikaKauffmann_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/AngelikaKauffmann_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/AngelikaKauffmann_card.svg');
  }
}

.cartMiddleSectionBottom {
  background-image: url('./assets/mobile/LudwigWindstosser_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/LudwigWindstosser_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/LudwigWindstosser_card.svg');
  }
}

.cartBottomSectionBottom {
  background-image: url('./assets/mobile/PeterLindbergh_card.svg');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/PeterLindbergh_card.svg');
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/PeterLindbergh_card.svg');
  }
}

.introText {
  box-sizing: border-box;
  padding: 30px 10px 10px 25px;
  position: relative;
  line-height: 1.5em;
  text-align: center;
  background-color: transparent;
  background-image: url('./assets/mobile/bgd-mobile.svg');
  background-position: top center;
  background-repeat: no-repeat;
  width: 303px;
  height: 301px;
  margin: 0 auto 5.4em auto;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/bgd.svg');
    padding: 35px 20px 20px 60px;
    font-size: 18px;
    width: 746px;
    height: 230px;
  }
}

.subTitle {
  @extend .headerBase;
}

.topLinkSection {
  text-align: center;
  margin-top: 5em;
  padding-bottom: 0;
}

.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}

.movieBoxWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.movieBox {
  @media screen and (min-width: $desktopWidth) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.wrapperVideo {
  margin: 40px auto 0;
  position: relative;
  @media screen and (min-width: $tabletWidth) {

  }
  @media screen and (min-width: $desktopWidth) {
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.frame {
  background-image: url('assets/box.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  width: 267px;
  height: 184px;
  padding: 11px 10px 10px 8px;
  margin: 0 auto;
  @media screen and (min-width: $desktopWidth) {
    width: 404px;
    height: 278px;
    padding: 17px 10px 10px 12px;
  }
}

.videoContainer {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('assets/video.jpg');
  width: 250px;
  height: 166px;

  @media screen and (min-width: $desktopWidth) {
    width: 376px;
    height: 250px;
  }
}

.background100 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.videoTeaser {
  box-sizing: border-box;
  @extend .background100;
  width: 320px;
  height: auto;
  margin: 40px auto 20px;
  padding: 1px 20px 20px;
  font-family: 'Montserrat', sans-serif;
  background: #fdfdf9;
  transform: rotate(1deg);
  box-shadow: 4px 4px #cbccc9;

  &:before {
    content: '';
    background: #e6b3ca;
    width: 100px;
    height: 30px;
    position: absolute;
    transform: rotate(135deg);
    left: -26px;
    top: 13px;
    opacity: 0.8;
  }
  &:after {
    content: '';
    background: #e6b3ca;
    width: 100px;
    height: 30px;
    position: absolute;
    transform: rotate(135deg);
    right: -26px;
    bottom: 7px;
    opacity: 0.8;
  }

  @media screen and (min-width: $tabletWidth) {
    width: 500px;
    height: auto;
    margin-top: 20px;
  }

  @media screen and (min-width: $desktopWidth) {
    width: 630px;
    height: auto;
    font-size: 20px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 1px 30px 30px;
  }

  h3 {
    font-family: "Leftover Crayon", sans-serif;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 100;
    text-align: center;
    margin: 50px 0 0 0;
    @media screen and (min-width: $tabletWidth) {
      margin-top: 40px;
    }
    @media screen and (min-width: $desktopWidth) {
      font-size: 36px;
      margin-top: 60px;
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    padding: 0 20px;

    @media screen and (min-width: $desktopWidth) {
      font-size: 18px;
      line-height: 27px;
      padding: 0 20px;
    }
  }
}

.flip {
  flex-direction: row-reverse;
}
