@import "../../../variables.scss";

.quizGameBody {
  background-image: url('../../../assets/background.png');
  @media screen and (min-width: $tabletWidth) {
    background-image: url('../../../assets/background_desktop.png');
  }
}

.quizGame {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
}

.hideOnMobile {
  @media screen and (max-width: $tabletWidth) {
    display: none;
  }
}