@import '../../../../variables';

$cardBorder: 5px #CBCCC9 solid;
$cardBgColor: #FDFDF9;

.filterCard {
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 16vw;
  height: 16vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vw;
  box-sizing: border-box;

  .expandCardButton {
    display: none;
  }

  @media screen and (max-width: $tabletWidth) {
    background-image: none !important;
    background-color: $cardBgColor;
    width: 100%;
    height: auto;
    display: block;
    background-size: 100%;
    padding: 0 0 5vw;
    margin-top: 1.5vw;
    margin-bottom: 7.5vw;
    border-right: $cardBorder;

    &.expanded {
      padding: 0;
    }

    .expandCardButton {
      display: inline-block;
      position: absolute;
      margin-top: 4vw;
      right: 10vw;
      width: 20px;
      height: 15px;
      background-image: url('../../assets/mobile/arrow_down.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  h3 {
    font-weight: 100;
    font-size: calc(12px + 0.65vw);
    margin: 1.2vw 0;

    @media screen and (max-width: $tabletWidth) {
      display: inline-block;
      width: auto;
      font-size: 20px;
      padding: 3.5vw 5vw;
      margin: -5vw 0 0 5vw;
    }
  }

  .cardBody {
    display: flex;
    height: 100%;

    @media screen and (max-width: $tabletWidth) {
      background-color: $cardBgColor;
      border-bottom: $cardBorder;
      padding: 5vw;
      display: none;

      &.expanded {
        display: block;
      }
    }

    section {
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 0.25vw;
      }

      @media screen and (max-width: $tabletWidth) {
        flex: 1 0 100%;
        div {
          margin-bottom: 2.25vw;
        }
      }
    }
  }
}

.expandFilterButton {
  height: 12vw;
  line-height: 12vw;
  font-size: 6.5vw;
  padding-left: 12vw;
  margin-left: 5vw;
  margin-top: 5vw;
  outline: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../assets/filter.png');
  text-transform: uppercase;

  @media screen and (min-width: $tabletWidth) {
    display: none;
  }
}

.filterWrapper {
  padding: 0 5vw;
  justify-content: space-between;
  margin-top: 5vw;
  display: none;
  flex-direction: column;

  &.shown {
    display: flex;
  }

  @media screen and (min-width: $tabletWidth) {
    display: flex;
    flex-direction: row;
  }

  .ageCard {
    @extend .filterCard;
    background-image: url('../../assets/age_card.svg');
    @media screen and (max-width: $tabletWidth) {
      h3 {
        background-color: #BDE0E7;
      }
    }
  }

  .weekCard {
    @extend .filterCard;
    background-image: url('../../assets/week_card.svg');
    @media screen and (max-width: $tabletWidth) {
      h3 {
        background-color: #FFD245;
      }
    }
  }

  .eventTypeCard {
    @extend .filterCard;
    background-image: url('../../assets/event_type_card.svg');

    @media screen and (max-width: $tabletWidth) {
      h3 {
        background-color: #DAAB62;
      }
    }

    @media screen and (min-width: $tabletWidth + 1) {
      width: 30vw;
      height: 20vw;
      padding-top: 3vw;
      padding-left: 3.5vw;
      margin-top: -2vw;

      .cardBody {
        section {
          width: 50%;
          div {
            height: 2.1vw;
          }
        }
      }
    }
  }

  .dayTimeCard {
    @extend .filterCard;
    background-image: url('../../assets/day_time_card.svg');
    @media screen and (max-width: $tabletWidth) {
      h3 {
        background-color: #AB3F2F;
      }
    }
  }

  .familyChildrenCard {
    @extend .filterCard;
    background-image: url('../../assets/family_children_card.svg');
    @media screen and (max-width: $tabletWidth) {
      h3 {
        background-color: #F3C0BD;
      }
    }
  }

  .clearFilter {
    cursor: pointer;
    position: absolute;
    right: 8vw;
    top: 15vw;

    @media screen and (max-width: $tabletWidth) {
      display: none;
    }

    span {
      text-transform: uppercase;
      text-decoration: underline;
      color: $linkcolor;
      font-size: 1.5vw;

      @media screen and (max-width: $tabletWidth) {
        font-size: 8vw;
        margin: 0;
      }
    }
  }
}

.dateWrapper {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2vw;
  padding: 1vw 10vw 0;

  @media screen and (max-width: $tabletWidth) {
    padding: 0;
    margin-top: 2vw;
  }

  &:after {
    height: 0.5vw;
    flex: 1 0 100%;
    margin-top: 1.5vw;
    background-image: url('../../assets/thread.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';

    @media screen and (max-width: $tabletWidth) {
      background-image: url('../../assets/mobile/line.png');
      height: 1.5vw;
      margin: 3.5vw 5vw 0;
      flex: 1 0 90vw;
      order: 2;
    }
  }

  .currentMonth {
    text-transform: uppercase;
    font-size: 2.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 18vw;

    @media screen and (max-width: $tabletWidth) {
      order: 1;
      font-size: 12vw;
      flex: 1 0 90vw;
      margin: 0 5vw;
      width: unset;
    }

    .prevMonth, .nextMonth {
      width: 2vw;
      height: 2.65vw;
      cursor: pointer;
      outline: none;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      @media screen and (max-width: $tabletWidth) {
        height: 12vw;
        width: 9vw;
      }
    }

    .prevMonth {
      background-image: url('../../assets/arrow_left.png');
      margin-right: 1vw;
    }

    .nextMonth {
      background-image: url('../../assets/arrow_right.png');
      margin-left: 1vw;
    }
  }

  .datePicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2vw;
    font-family: Montserrat, sans-serif;
    font-size: 0.9vw;
    font-weight: bold;

    @media screen and (max-width: $desktopWidth) {
      font-size: 10px;
    }

    @media screen and (max-width: $tabletWidth) {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      width: 100%;
    }

    .datesWrapper {
      margin-left: 1vw;
      width: 14vw;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .currentDates {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 12vw;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }

        &:after {
          height: 0.5vw;
          width: 100%;
          margin-top: 0.25vw;
          background-image: url('../../assets/thread_small.svg');
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
        }
      }

      .clearDatesButton {
        background-image: url('../../assets/close.png');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 1vw;
        height: 1vw;
        margin-bottom: .5vw;
        cursor: pointer;
      }
    }

    .togglePicker {
      height: 2.75vw;
      line-height: 2.75vw;
      margin-left: 1vw;
      cursor: pointer;
      outline: none;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../../assets/calender_desktop.png');
      font-family: "Leftover Crayon", sans-serif;
      font-weight: normal;
      font-size: 1vw;
      text-transform: uppercase;
      padding-left: 3vw;

      @media screen and (max-width: $desktopWidth) {
        font-size: 14px;
      }

      @media screen and (max-width: $tabletWidth) {
        height: 12vw;
        line-height: 12vw;
        font-size: 6.5vw;
        padding-left: 12vw;
        margin-left: 5vw;
      }
    }

    .calendarWrapper {
      position: absolute;
      left: 31vw;
      top: 42.5vw;

      @media screen and (max-width: $tabletWidth) {
        position: static;
        justify-content: center;
        margin: 3vw auto;
        display: flex;
        left: -5vw;
        top: 0;
      }
    }
  }
}

.calendarButton {
  text-align: right;
  margin: 0 1vw 1vw 0;

  @media screen and (max-width: $tabletWidth) {
    margin: 0 3vw 3vw 0;
  }

  span {
    text-transform: uppercase;
    text-decoration: underline;
    color: #10378E;
    font-family: "Leftover Crayon", sans-serif;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;

    @media screen and (max-width: $tabletWidth) {
      font-size: 20px;
    }
  }
}

.prevent {
  pointer-events: none;
  opacity: 0.3;
}
