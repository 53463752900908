@import "../../../../variables";

.gameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.gameImage {
  position: relative;
  max-width: 320px;
  margin-right: auto;
  margin-left: auto;
}

.isBlocked {
  cursor: not-allowed;
  opacity: .5;
}

.ageField {
  width: 42px;
  height: 40px;
  position: absolute;
  top: -40px;
  left: calc(50% - 100px);
  background: url('../assets/ageCircle.png') no-repeat;
  background-size: 100% 100%;
  color: $black;
  font-size: 24px;
  line-height: 25px;
  padding-top: 8px;
  box-sizing: border-box;
}

.gameTitle {
  color: $crayonblue;
  font-family: "Leftover Crayon", sans-serif;
  font-size: 22px;
  position: absolute;
  margin: 0;
  text-align: center;
  width: 100%;
  bottom: 60px;
}

.white {
  background: white;
  padding:4px 10px;
  line-height: 1;
  border-radius: 3px;
}

.gameText {
  margin: -22px auto 0;
  max-width: 240px;
  font-size: 18px;
  line-height: 26px;
  @media screen and (min-width: $desktopWidth) {
    margin: -22px 20px 0 50px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    margin: 0 auto;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.spritesheet {
  & > div {
    transform: scale(.9)!important;
    position: absolute;
    left: -30px;
    max-width: 390px;
    @media screen and (max-width: $mobileWidth) {
      left:-13%;
    }
  }
}

@keyframes appear {
  from {
    background-color: inherit;
  }
  to {
    background-color: rgba(227,227,227,0.87);
  }
}

.blockedLayer {
  animation: appear .5s;
  width: 213px;
  height: 220px;
  position: absolute;
  bottom: 210px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: rgba(227,227,227,0.87);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    max-width: 150px;
  }
  @media screen and (min-width: $desktopWidth) {
    display: none;
  }
}
