@import '../../../../../../variables';

.detectivePeep {
  position: absolute;
  z-index: 8;
  top: 66%;
  right: 0;
  box-sizing: border-box;
  width: 235px;
  height: auto;
  transform: translateY(-50%);
  opacity: 1;
}

.bubble { // TODO bubble component based on this
  font-family: "Leftover Crayon", sans-serif;
  position: absolute;
  right: 202px;
  top: 70%;
  transform: translateY(-50%);
  width: 200px;
  text-align: center;
  text-transform: uppercase;
  color: $crayondarkgrey;
  z-index: 9;
  padding: 28px 64px 40px 0;
}

.bubble::before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: -40px;
  bottom: -60px;
  left: -50px;
  content: "";
  background-image: url(../assets/Bubble_detective.png); // TODO should use only one file for it (I copied it for now)
  background-size: 100% 100%;
}

.magnifier {
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: -10px;
}