@import '../../../../variables';

.eventSubPostit {
  width: 300px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  bottom: -180px;
  font-size: 14px;
  line-height: 19px;
  color: $dateColor;
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 10px;
  box-sizing: border-box;
  text-align: center;
  background-image: url('../../assets/mobile/infoBoxLong.svg');
  height: 220px;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('../../assets/tablet/infoBoxLong.svg');
    width: 668px;
    height: 186px;
    font-size: 16px;
    line-height: 23px;
    padding-top: 50px;
    padding-left: 56px;
    padding-right: 20px;
    bottom: -120px;
    right: 0;
    margin: 0 auto;
  }

  p {
    margin-top: 0;
  }
}

.small {
  background-image: url('../../assets/mobile/infoBoxShort.svg');
  height: 116px;
  bottom: -80px;
  @media screen and (min-width: $tabletWidth) {
    background-image: url('../../assets/tablet/infoBoxShort.svg');
    width: 606px;
    height: 110px;
    padding-top: 36px;
    padding-left: 44px;
    bottom: -60px;
  }
}
