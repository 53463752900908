@import '../../variables';

.logoBox {
  margin: 0 auto;
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.logo {
  width: 188px;
  max-width: 398px;
  object-fit: contain;
  background-image: url(./assets/logo_mobile.png);
  background-size: 100% 100%;
  height: 58px;
  margin: 0 auto;
  display: block;
  transition: all 0.3s;
  @media screen and (min-width: $tabletWidth) {
    width: 263px;
    height: 81px;
    margin: 14px auto 0;
    display: block;
    background-image: url(./assets/logo_desktop.png);
    max-width: 100%;
    max-height: 100%;
  }
}