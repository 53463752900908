@import "../../../../variables";

.pictureLeft {
  font-family: "Leftover Crayon", sans-serif;
  color: $crayonblue;
  text-transform: uppercase;
  font-size: 24px;
  display: none;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  top: 140px;
  left: 30px;
  cursor: pointer;
  z-index: 9;
  @media screen and (min-width: $tabletWidth) {
    top: 170px;
    left: 20px;
  }
  @media screen and (min-width: $desktopWidth) {
    display: flex;
    top: 180px;
    left: 20px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    left: 30px;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 34px;
    height: 21px;
    background-image: url('../assets/arrow_1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 16px;
  }

  span {
    display: none !important;

    @media screen and (min-width: $desktopWidth) {
      display: unset !important;
    }
  }
}