@import '../../../../variables';

.eventWrapper {
  display: flex;
  flex-direction: column;
  padding: 2vw 10vw;
  font-family: Montserrat, sans-serif;
  font-size: 1vw;

  @media screen and (max-width: $desktopWidth) {
    font-size: 13px;
  }
      
  @media screen and (max-width: $tabletWidth) {
    padding: 2vw 5vw;
  }

  .event {
    display: flex;
      
    @media screen and (max-width: $tabletWidth) {
      flex-direction: column;
    }

    section {
      padding-top: 0.5vw;
      flex: 0 1 15%;

      @media screen and (max-width: $tabletWidth) {
        flex: 1 0 100%;
        padding-top: 3.5vw;
      }

      &.left {
        font-size: 1.5vw;
        font-weight: bold;

        @media screen and (max-width: $tabletWidth) {
          font-size: 7.5vw;
        }

        p {
          padding: 0;
          margin: 0;
        }

        .dayOfWeek {
          font-weight: normal;
          text-transform: uppercase;
        }

        .dayAndMonth {
          margin-left: 0.5vw;
        }

        .hour {
          font-size: 1vw;

          @media screen and (max-width: $tabletWidth) {
            font-size: 5vw;
          }
        }
      }

      &.middle {
        flex: 1 1 70%;

        p {
          margin: 0;
        }

        h3 {
          margin: 0.5vw 0;
        }

        .description {
          margin: 0.5vw 0;
          display: block;
          max-width: 80ex;
          line-height: 1.4em;

          p {
            margin-bottom: 10px;
          }
        }

        .eventType {
          background-color: #E4E4E4;
          width: auto;
          padding: 0.25vw;

          &.kinderfuehrung { background-color: #DAAB62; }
          &.kinderworkshop { background-color: #BDE0E7; }
          &.kinderkurs { background-color: #604630; color: #FFF; }
          &.kinderferienkurs { background-color: #DD92C0; }
          &.familienfuehrung { background-color: #FFD245; }
          &.familienfuehrungworkshop { background-color: #00576A; color: #FFF; }
          &.kunstklub { background-color: #FAC9C7; }
          &.kunstmitbaby { background-color: #AB3F2F; color: #FFF; }
          &.elternhabenfrei { background-color: #026D4C; color: #FFF; }
        }
      }

      &.right {
        display: flex;

        .signonButton {
          background-image: url('../../assets/button_anmelden.svg');
          background-size: 100%;
          width: 12vw;
          height: 2.5vw;
          font-size: 1.25vw;
          padding: 0 .2vw;
          color: $black;
          cursor: pointer;
          outline: none;

          &.urlButton {
            background-image: url('../../assets/button_anmelden_link.svg');
          }

          @media screen and (max-width: $tabletWidth) {
            width: 50vw;
            height: 12vw;
            line-height: 12vw;
            margin: 0 auto;
            font-size: 5.25vw;
            padding: 0 .2vw;
            background-size: unset;
          }
        }
      }
    }
  }

  &:after {
    height: 0.5vw;
    width: 100%;
    margin-top: 1.5vw;
    background-image: url('../../assets/thread.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';

    @media screen and (max-width: $tabletWidth) {
      background-image: url('../../assets/mobile/line.png');
      height: 1.5vw;
    }
  }
}