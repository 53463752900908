@import '../../variables';

// commons
$wrapperPadding: 40px;
$maxMobileWidth: $tabletWidth - 1;

.sectionTitle {
  @extend .headerBase;
  box-sizing: border-box;
  font-size: $mobileH3Size;
  line-height: 1.2em;
  padding: 15px 30px;
  margin: 0 auto;
  width: 220px;

  @media screen and (min-width: $tabletWidth) {
    font-size: $tabletH3Size;
    position: relative;
    top: -30px;
  }

  @media screen and (min-width: $desktopWidth) {
    font-size: $desktopH3SizeDecreased;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top left;
    background-color: $lightyellowbackground;
    width: 140px;
    z-index: 50;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    font-size: $desktopH3Size;
    width: 210px;
    height: 247px;
  }
}

.withShadow {
  @media screen and (min-width: $desktopWidth) {
    -webkit-box-shadow: 13px 17px 0px -3px $boxshadow;
    -moz-box-shadow: 13px 17px 0px -3px $boxshadow;
    box-shadow: 13px 17px 0px -3px $boxshadow;
  }
}

@mixin pins($pinLeftImg, $pinRightImg) {
  @media screen and (min-width: $desktopWidth) {
    &:before {
      content: '';
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: block;
      position: absolute;
      top: 2em;
      left: 3vw;
      height: 39px;
      width: 28px;

      @if $pinLeftImg == none {
        background-image: none;
      } @else {
        background-image: url($pinLeftImg);
      }
    }
    &:after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: block;
      position: absolute;
      top: 2em;
      right: 3vw;
      height: 31px;
      width: 28px;

      @if $pinRightImg == none {
        background-image: none;
      } @else {
        background-image: url($pinRightImg);
      }
    }
  }
}

// html elements
.outerContainer {
  background-color: $mediumBackColor;
  margin-top: $navigtaionTopBarHeight;
}

.wrapper {
  box-sizing: border-box;
  background-color: $mediumBackColor;
  font-family: 'Montserrat', sans-serif;
  line-height: 19px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $desktopWidth) {
    margin-top: 3em;
  }
}

.container {
  background-color: $lightyellowbackground;
}

ol {
  padding-inline-start: 0;
  list-style-position: inside;
  margin: 0;

  & li {
    padding-bottom: 1.5em;

    &:last-child {
      padding-bottom: 0;
    }
  }
}


.introText {
  box-sizing: border-box;
  padding: $wrapperPadding;
  position: relative;
  line-height: 1.8em;
  background-color: $lightyellowbackground;

  &:before {
    content: '';
    display: block;
    background-color: $stickerred;
    position: absolute;
    top: -15px;
    left: 3vw;
    height: 30px;
    width: 100px;
    opacity: 0.7;
  }

  @media screen and (min-width: $tabletWidth) {
    background-color: $mediumBackColor;
    background-image: url('./assets/tablet/Card_tablet.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top left;
    width: 727px;
    height: 417px;
    padding: 50px 20px 20px 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    font-size: 14px;

    &:before {
      display: none;
    }
  }

  @media screen and (min-width: $desktopWidth) {
    background-image: url('./assets/desktop/Card_desktop.svg');
    width: 1025px;
    height: 635px;
    padding: 120px 80px 20px 100px;
    font-size: 16px;
  }
}

.copyrights {
  @media screen and (max-width: $maxMobileWidth) {
    background-color: $lightyellowbackground;
  }
}

.subTitle {
  @extend .headerBase;
  background-color: $lightyellowbackground;
  font-size: $mobileH2Size;
  margin: 0;
  padding: 0.8em 0;

  @media screen and (min-width: $tabletWidth) {
    background-color: $mediumBackColor;
    font-size: $tabletH2Size;
    padding-bottom: 60px;
    padding-top: 0;
  }
}
.copyrights__content {
  padding: 0 $wrapperPadding;

  @media screen and (min-width: $tabletWidth) {
    padding: 0;
  }
}

.kuratorSection,
.kunstSection {
  @extend .displayFlex;
  background-color: $lightyellowbackground;
  margin-bottom: 3em;

  @media screen and (min-width: $tabletWidth) {
    padding-left: $wrapperPadding * 4;
    padding-right: $wrapperPadding * 4;
    padding-bottom: 3em;
    margin-bottom: 5em;
  }
  @media screen and (min-width: $desktopWidth) {
    background-color: transparent;
    flex-direction: row;
    padding-left: $wrapperPadding;
    padding-right: $wrapperPadding;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.singlePaged {
  @extend .displayFlex;

  @media screen and (min-width: $desktopWidth) {
    flex-direction: row;
    padding-left: $wrapperPadding;
    padding-right: $wrapperPadding;
  }
}

.singlePaged .photos,
.singlePaged .detective {
  @extend .withShadow;

  background-color: $lightyellowbackground;
  margin-bottom: 5em;
  position: relative;

  @media screen and (min-width: $tabletWidth) {
    padding-left: $wrapperPadding * 4;
    padding-right: $wrapperPadding * 4;
    padding-bottom: 3em;
  }
  @media screen and (min-width: $desktopWidth) {
    flex-direction: row;
    background-color: $lightyellowbackground;
    flex-basis: 36%;
    font-size: 14px;
    padding: 0 6% 2em 6%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.singlePaged .detective {
  @media screen and (min-width: $desktopWidth) {
    &::before {
      content: '';
      height: 40px;
      width: 150px;
      background-color: $stickerbeige;
      position: absolute;
      top: 1em;
      left: -3em;
      -moz-transform: rotate(134deg);
      -webkit-transform: rotate(134deg);
      -o-transform: rotate(134deg);
      -ms-transform: rotate(134deg);
      transform: rotate(134deg);
      opacity: 0.8;
    }
  }
}

.kuratorSection > div,
.kunstSection > div {
  @extend .withShadow;
  position: relative;

  @media screen and (min-width: $desktopWidth) {
    background-color: $lightyellowbackground;
    flex-basis: 36%;
    font-size: 14px;
    padding: 0 6% 2em 6%;
    margin-bottom: 3em;
  }
}

.kuratorHeader {
  @extend .sectionTitle;
  background-color: $stickerblue;

  @media screen and (min-width: $desktopWidth) {
    box-sizing: border-box;
    background-image: url('./assets/desktop/Card_mini.svg');
    background-color: transparent;
    padding: 20px 20px 5px 20px;
    top: 3em;
    right: 44vw;
    width: 148px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/desktop/red-sticker-header.svg');
    background-color: transparent;
    padding: 100px 40px 10px 30px;
    top: 3em;
    left: 43%;
    right: auto;
    width: 220px;
  }
}

.kunstHeader {
  @extend .sectionTitle;
  background-color: $stickerred;

  @media screen and (min-width: $desktopWidth) {
    box-sizing: border-box;
    background-image: url('./assets/desktop/Card_mini.svg');
    background-color: transparent;
    padding: 20px 20px 5px 20px;
    top: 69px;
    left: 43vw;
    width: 148px;
    height: 92px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    box-sizing: border-box;
    background-image: url('./assets/desktop/blue-sticker-header.svg');
    background-color: transparent;
    padding: 70px 40px 10px 40px;
    width: 240px;
    height: 220px;
  }
}

.photosHeader {
  @extend .sectionTitle;
  background-color: $stickerblue;
  width: 48vw;

  @media screen and (min-width: $tabletWidth) {
    width: 310px;
  }
  @media screen and (min-width: $desktopWidth) {
    box-sizing: border-box;
    background-image: url('./assets/desktop/Card_mini.svg');
    background-color: transparent;
    padding: 20px 20px 5px 20px;
    top: -8px;
    left: 32vw;
    width: 148px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/desktop/blue-sticker-header.svg');
    background-color: transparent;
    font-size: $desktopH3SizeDecreased;
    padding: 40px 35px 10px 35px;
    top: 4.3em;
    left: 83%;
    right: auto;
    width: 190px;
    height: 130px;
  }
}

.detectiveHeader {
  @extend .sectionTitle;
  background-color: $stickerbeige;

  @media screen and (min-width: $desktopWidth) {
    box-sizing: border-box;
    background-image: url('./assets/desktop/Card_mini.svg');
    background-color: transparent;
    padding: 20px 20px 5px 20px;
    top: -35px;
    left: auto;
    right: 2vw;
    width: 130px;
    word-break: break-word;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    background-image: url('./assets/desktop/darkBlue-sticker-header.svg');
    background-color: transparent;
    font-size: $desktopH3SizeDecreased;
    padding: 95px 40px 10px 30px;
    top: 9em;
    left: -8%;
    right: auto;
    width: 160px;
    word-break: break-word;
  }
}

.collections {
  position: relative;
  @include pins(
    '../../assets/pins/pin_yellow.png',
    '../../assets/pins/pin_green.png'
  );
}

.quizSection {
  position: relative;
  @include pins(
    '../../assets/pins/pin_red.png',
    '../../assets/pins/pin_red.png'
  );
}

.round1 {
  @include pins('../../assets/pins/pin_green.png', none);
}

.round2 {
  @include pins('../../assets/pins/pin_yellow.png', none);
}
.round3 {
  @include pins('../../assets/pins/pin_red.png', none);
}
.round4 {
  @media screen and (min-width: $desktopWidth) {
    &:before {
      content: '';
      height: 40px;
      width: 160px;
      background-color: $stickerblue;
      position: absolute;
      top: 2em;
      left: -3em;
      -moz-transform: rotate(134deg);
      -webkit-transform: rotate(134deg);
      -o-transform: rotate(134deg);
      -ms-transform: rotate(134deg);
      transform: rotate(134deg);
      opacity: 0.8;
    }
  }
}
.round5 {
  @include pins(none, '../../assets/pins/pin_yellow.png');
}
.round6 {
  @include pins(none, '../../assets/pins/pin_green.png');
}

.photos {
  @include pins('../../assets/pins/pin_red.png', none);
}

h4 {
  @extend .headerBase;
  font-size: $mobileH4Size;
  margin-top: 2em;

  @media screen and (min-width: $tabletWidth) {
    font-size: $tabletH4Size;
  }
}

h5 {
  @extend .headerBase;
  font-size: $mobileH4Size;
  margin-top: 2em;
  text-align: left;

  @media screen and (min-width: $tabletWidth) {
    font-size: $tabletH4Size;
  }
}

.listing {
  p {
    display: inline;
  }
}

.no_heading{
  margin-top: 2em;
}

.topLinkSection {
  text-align: center;
  margin-top: 5em;
  padding-bottom: 0;
}

.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}
