@import '../../../../variables';

.wrapper {
  margin: 40px auto 0;
  position: relative;

  @media screen and (min-width: $desktopWidth) {
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.isOpen {
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  background-color: $halftransparentdark;
  &>div>div>div>div {
    padding-bottom: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  & iframe {
    display: block;
      width: 1200px;
      height: 700px;
  }
  & button {
    position: fixed;
    top: 10%;
    right: 10%;
    width: 30px;
    height: 30px;
    background-image: url('./../../assets/close_white.png');
    background-color: inherit;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    cursor: pointer;
  }
}
