@import '../../variables';

// commons
$h2Size: 36px;
$maxTabletWidth: $desktopWidth - 1;
$mobileContentWidth: 303px;
$tabletContentWIdth: 706px;
$sectionMobileWidth: 1510px;

.images {
  width: $mobileContentWidth;
  height: auto;

  @media screen and (min-width: $tabletWidth) {
    width: $tabletContentWIdth;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    width: 626px;
  }
}

@mixin sectionTexts($mobileBackgroundImgUrl, $tabletBackgroundImgUrl) {
  position: relative;
  background-image: url($mobileBackgroundImgUrl);
  background-size: 294px auto;
  background-repeat: no-repeat;
  line-height: 1.5em;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    background-image: url($tabletBackgroundImgUrl);
    font-size: 18px;
    line-height: 1.8em;
  }
}

// html elements
.container {
  background-color: $lightyellowbackground;
}

.subTitle {
  @extend .headerBase;
}

.appDownloadLink {
  @extend .linksBase;
}

.offerAlwaysThere ul {
  @extend .listsBase;

  p {
    display: inline;
  }
}

p {
  a {
    cursor: pointer;
    font-size: 1.2em;
    color: #000;
    font-family: Leftover Crayon,sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
  }
}

.introText {
  @extend .displayFlex;
  margin-bottom: 3em;

  @media screen and (min-width: $tabletWidth) {
    margin-bottom: 2.4em;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row;
    width: $sectionMobileWidth;
    margin-left: auto;
    margin-right: auto;
  }
}

.postit {
  box-sizing: border-box;
  padding: 30px 25px 36px 30px;
  position: relative;
  line-height: 1.5em;
  text-align: center;
  background-color: transparent;
  background-image: url('./assets/mobile/intro-card-mobile.svg');
  background-position: top center;
  background-repeat: no-repeat;
  width: 303px;
  height: auto;
  margin: 0 auto 0.5em auto;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/tablet/intro-card.svg');
    padding: 120px;
    font-size: 18px;
    width: $tabletContentWIdth;
    height: auto;
    margin-bottom: 1.4em;

    & > p {
      margin-bottom: 2em;
    }
  }

  & .subTitle {
    @extend .headerBase;
    margin-top: 1.4em;

    @media screen and (min-width: $tabletWidth) {
      font-size: 36px;
    }
  }
}

.introImgs {
  @extend .displayFlex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.girl_pic {
  @extend .images;
  margin-left: 14px;
  margin-bottom: 10px;

  @media screen and (min-width: $bigDesktopWidth) {
    margin-left: 0;
  }
}

.items {
  @extend .images;
  margin-left: -8px;
  width: 313px;

  @media screen and (min-width: $tabletWidth) {
    margin-left: -37px;
    width: 728px;
  }

  @media screen and (min-width: $bigDesktopWidth) {
    margin-left: -44px;
    width: 644px;
  }
}

.digitalSection {
  @extend .displayFlex;
  box-sizing: border-box;
  width: 294px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;

  @media screen and (min-width: $tabletWidth) {
    flex-direction: column-reverse;
    width: 638px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row;
    width: $sectionMobileWidth;
  }
}
.kids_pic {
  width: 92%;
  margin-left: 8%;

  @media screen and (min-width: $tabletWidth) {
    position: relative;
    top: -100px;
    margin-left: 10%;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 548px;
    top: 7em;
    width: auto;
    margin-left: 11em;
  }
}
.drawings_pic {
  width: 100%;

  @media screen and (min-width: $tabletWidth) {
    width: 90%;
    margin-left: 20px;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 548px;
    position: relative;
    top: 7em;
    left: -150px;
    width: auto;
    margin-left: 0;
  }
}
.recorgingStudio_pic {
  width: 85%;

  @media screen and (min-width: $tabletWidth) {
    width: 62%;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 424px;
    position: relative;
    top: 0em;
    left: -17.5em;
    width: auto;
    margin-left: 0;
  }
}

.digitalText {
  @include sectionTexts(
    './assets/mobile/digital-mobile-bgd.svg',
    './assets/tablet/digital-bgd.svg'
  );
  top: -50px;
  padding: 40px 30px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 638px auto;
    margin-top: 3em;
    margin-left: -14px;
    padding-top: 140px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 80px;

    & .digitalLink {
      margin-top: 2em;
    }
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 622px;
    left: -140px;
    top: 0;
    margin-top: 0;
    margin-left: 0;
    padding: 140px;
    padding-left: 60px;
    padding-right: 0;
    padding-bottom: 80px;

    & > p {
      margin-left: 1em;
      margin-right: 8em;
    }
    & > div {
      margin-left: 1em;
      margin-right: 8em;
    }
  }

  & h2 {
    margin-top: 10px;
    padding: 0 2em;
    line-height: 1.1em;

    @media screen and (min-width: $tabletWidth) {
      font-size: $h2Size;
      margin-top: 0;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      margin-right: 4em;
    }
  }

  & p:last-child {
    margin-bottom: 2em;
  }
}

.friendsSection,
.kidGuideSection {
  @extend .displayFlex;
  margin-left: auto;
  margin-right: auto;
  width: $mobileContentWidth;

  @media screen and (min-width: $tabletWidth) {
    margin-top: -2em;
    width: $tabletContentWIdth;
  }
}

.friendsSection {
  margin-top: -3em;

  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row-reverse;
    width: $sectionMobileWidth;
    margin-top: 1em;
  }
}

.kidGuideSection {
  align-items: center;
  @media screen and (min-width: $bigDesktopWidth) {
    flex-direction: row-reverse;
    width: $sectionMobileWidth;
    margin-top: 2em;
  }
}

.friendsText {
  @include sectionTexts(
    './assets/mobile/friends-bgd-mobile.svg',
    './assets/tablet/friend-bgd.svg'
  );
  top: -25px;
  padding: 50px 30px 71px 30px;

  @media screen and (min-width: $tabletWidth) {
    background-size: 660px auto;
    padding-top: 96px;
    padding-left: 44px;
    padding-right: 120px;
    padding-bottom: 89px;
    top: -90px;
    left: 25px;

    & strong {
      display: inline-block;
      margin-top: 15px;
    }
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 622px;
    left: 6em;
    top: 0;
    margin-top: 0;
    padding-top: 76px;
    padding-left: 50px;
    padding-right: 0;
    padding-bottom: 118px;

    & > div,
    & > p {
      margin-left: 1em;
      margin-right: 5em;
    }
  }

  & h2 {
    margin-top: 10px;

    @media screen and (min-width: $tabletWidth) {
      font-size: $h2Size;
    }
  }
}

.kinderGuideText {
  @include sectionTexts(
    './assets/mobile/kinder-bgd-mobile.svg',
    './assets/tablet/kinder-bgd.svg'
  );
  top: -25px;
  padding: 40px 30px 30px 30px;

  @media screen and (min-width: $tabletWidth) {
    background-position: top right;
    background-size: 580px auto;
    padding-top: 80px;
    padding-left: 200px;
    padding-right: 80px;
    padding-bottom: 50px;
    top: -5em;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    flex-basis: 622px;
    left: 9.5em;
    top: 1em;
    margin-top: 0;
    padding-top: 65px;
    padding-left: 50px;
    padding-right: 0;
    padding-bottom: 70px;

    & > p {
      margin-left: 7em;
      margin-right: 4em;
    }
  }

  & > h2 {
    @media screen and (min-width: $tabletWidth) {
      font-size: $h2Size;
    }
    @media screen and (min-width: $bigDesktopWidth) {
      margin-left: 2em;
      margin-right: 1em;
    }
  }
}

.topLinkSection {
  margin-top: 0;
  padding-bottom: 2em;
  text-align: center;

  @media screen and (min-width: $tabletWidth) {
    margin-top: -40px;
    padding-bottom: 4em;
  }
  @media screen and (min-width: $bigDesktopWidth) {
    margin-top: 4em;
  }
}
.topLink {
  position: relative;
  padding: 10px 60px;
  font-size: 1.2em;
}

.wrapperVideo {
  @media screen and (min-width: $tabletWidth) {
    left: 80px;
  }
  @media screen and (min-width: $desktopWidth) {
    left: 0;
  }
}

.video {
  width: 592px;
  height: 394px;
  & img {
    width: 550px;
    height: 350px;
    margin-left: 5px;
    margin-top: 8px;
    @media screen and (max-width: $desktopWidth) {
      margin-top: 16px;
      margin-left: 12px;
    }
    @media screen and (max-width: $tabletWidth) {
      width: 280px;
      height: 180px;
      margin-top: 2px;
      margin-left: 1px;
    }
  }
  & span {
    top: 130px;
    @media screen and (max-width: $tabletWidth) {
      top: 64px;
    }
  }
  @media screen and (max-width: $bigDesktopWidth) {
    margin-bottom: 50px;
    margin-right: -320px;
  }
  @media screen and (max-width: $desktopWidth) {
    margin-right: 0;
    margin-bottom: 100px;
  }
  @media screen and (max-width: $tabletWidth) {
    width: 300px;
    height: 200px;
    margin-bottom: 30px;
  }
}

.tvClass {
  width: 164px;
  height: 122px;
  bottom: -44px;
  right: -60px;
  @media screen and (max-width: $tabletWidth) {
    width: 62px;
    height: 46px;
    bottom: -10px;
    right: -10px;
  }
}

@import "../AgainAndAgain/Postits.module";

section {
  p {
    a {
      line-height: 27px;
      cursor: pointer;
      font-size: 1.2em;
      color: #000;
      font-family: Leftover Crayon,sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
