@import '../../../../../variables';

.carouselElement {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  width: 290px;
  height: 600px;
  @media screen and (min-width: $tabletWidth) {
    padding: 56px 80px;
    height: 460px;
    width: 90%;
  }
  @media screen and (min-width: $desktopWidth) {
    height: 540px;
    width: 100%;
    padding: 80px 100px;
  }
  .title {
    padding: 44px 0 0;
    margin-top: 22px;
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 28px;
    @media screen and (min-width: $tabletWidth) {
      font-size: 36px;
      padding: 0;
      margin-bottom: 16px;
    }
    @media screen and (min-width: $desktopWidth) {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
  & .elementPic {
    display: none;
    @media screen and (min-width: $tabletWidth) {
      display: inline-block;
    }
  }
}

.frame {
  width: 195px;
  height: 125px;
  display: block;
  margin: 0 auto;
  position: relative;
  background-image: url('../assets/square.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: $tabletWidth) {
    display: inline-block;
    margin: 0;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 328px;
    height: 220px;
  }
  .elementPic {
    position: absolute;
    bottom: -40px;
    left: -36px;
    width: 83px;
    height: 72px;
    @media screen and (min-width: $desktopWidth) {
      width: 131px;
      height: 113px;
    }
  }
}

.picture {
  width: 94%;
  height: 89%;
  margin-left: -10px;
  padding-top: 7px;
  padding-bottom: 8px;
  padding-left: 16px;
  @media screen and (min-width: $tabletWidth) {
    width: 93%;
    height: 89%;
    margin-left: 0;
    padding-top: 8px;
    padding-left: 6px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 306px;
    padding-top: 14px;
    padding-left: 10px;
    height: 88%;
  }
}

.text {
  font-size: 14px;
  line-height: 22px;
  width: 77%;
  display: block;
  position: absolute;
  padding-left: 4%;
  padding-top: 20px;
  @media screen and (min-width: $tabletWidth) {
    padding-top: 0;
    padding-left: 30px;
    width: 48%;
    display: inline-block;
  }
  @media screen and (min-width: $desktopWidth) {
    font-size: 18px;
    line-height: 27px;
    width: 44%;
  }
  p {
    font-size: inherit !important;
    text-transform: inherit !important;
    line-height: inherit !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: inherit !important;
  }
}

.subtext {
  font-weight: bold;
  display: block;
  padding-top: 6px;
  @media screen and (min-width: $tabletWidth) {
    text-align: left;
    padding-top: 10px;
  }
  @media screen and (min-width: $desktopWidth) {
    padding-top: 20px;
  }
}

.corouselSign {
  background-image: url('../../../assets/Sign.svg');
  background-repeat: no-repeat;
  width: 200px;
  height: 37px;
  font-size: 22px;
  line-height: 24px;
  font-family: 'Leftover Crayon', sans-serif;
  position: absolute;
  bottom: 32px;
  right: 50px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: $tabletWidth) {
    bottom: 134px;
    right: 30px;
    font-size: 24px;
    width: 200px;
  }
  @media screen and (min-width: $desktopWidth) {
    width: 239px;
    height: 63px;
    font-size: 28px;
    bottom: 100px;
    right: 110px;
  }
}
