@import '../../variables';

.title {
  color: $black;
  margin: 0 auto 10px;
  padding-top: 9px;
  text-align: center;
  font-size: 28px;
  line-height: 30px;
  font-family: 'Leftover Crayon', sans-serif;
  text-transform: uppercase;
  width: 100%;
  font-weight: normal;
  @media screen and (min-width: $tabletWidth) {
    max-width: 537px;
    margin: 0 auto 0;
    padding-top: 62px;
    font-size: 40px;
    line-height: 43px;
  }
  @media screen and (min-width: $desktopWidth) {
    max-width: 920px;
    margin: 0 auto 30px;
    padding-top: 70px;
    font-size: 48px;
    line-height: 50px;
  }
}