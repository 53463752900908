@import '../../../../variables';

.slider {
  margin: 0 auto;
  position: relative;
  width: 270px;
  height: 640px;
  @media screen and (min-width: $tabletWidth) {
    width: 733px;
    height: 404px;
    margin: 0 auto;
  }
  @media screen and (min-width: $desktopWidth) {
    height: 540px;
    width: 1000px;
  }
  & > button {
    cursor: pointer;
  }
  & > button:first-of-type ,
  & > button:last-of-type {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: none!important;
    @media screen and (min-width: $tabletWidth) {
      width: 33px;
      height: 32px;
      display: block!important;
    }
    @media screen and (min-width: $desktopWidth) {
      width: 53px;
      height: 47px;
    }
  }
  & > button:first-of-type {
    background-image: url('../../assets/arrow_left.png');
    margin-left: 70px;
    z-index: 6;
    @media screen and (min-width: $desktopWidth) {
      margin-left: 60px;
    }
    &::before {
      opacity: 0;
    }
  }
  & > button:last-of-type {
    background-image: url('../../assets/arrow_right.png');
    margin-right: 70px;
    @media screen and (min-width: $tabletWidth) {
      margin-right: 100px;
    }
    @media screen and (min-width: $desktopWidth) {
      margin-right: 60px;
    }
  }
  & ul {
    bottom: 54px;
    width: 68%;
    left: 22%;
    @media screen and (min-width: $tabletWidth) {
      bottom: 59px;
      left: 28%;
      width: 45%;
    }
    @media screen and (min-width: $desktopWidth) {
      bottom: 120px;
    }
  }
  & li button::before {
    background-color: inherit;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    font-size: 6px;
    @media screen and (min-width: $tabletWidth) {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
  }
}
