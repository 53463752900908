@import "../../../variables.scss";

.memoryGameBody {
  background-image: url('../../../assets/background.png');

  @media screen and (min-width: $tabletWidth) {
    position: relative;
    background-image: none;

    &::before {
      content: "";
      background-image: url('../../../assets/bckg_pattern.png');
      background-repeat: repeat;
      background-size: 37px 67px;
      position: absolute;
      top: $navigtaionTopBarHeight - 15px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.memoryGame {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: transparent;
  overflow: hidden;

  // We use coarse to determine tablet here and use corrected viewport height for the browser's chrome
  @media screen and (max-width: $desktopWidth) and (pointer: coarse) {
    height: calc(100vh - 65px);
  }
}