@import '../../variables';

.navigationWrapper {
  width: 100vw;
  z-index: 999;
  background-color: $mediumBackColor;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: absolute;
  height: $navigtaionTopBarHeight;
  transition: all 0.3s;
  @media screen and (min-width: $desktopWidth) {
    position: fixed;
  }
  &.afterScroll {
    border-bottom: 2px solid #647378;
    transition: all 0.3s;
  }
}
.mobileLogo {
  width: 188px;
  height: 58px;
  padding: 0;
  margin-top: 16px;
  @media screen and (min-width: $tabletWidth) {
    width: 263px;
    height: 81px;
  }
  @media screen and (min-width: $desktopWidth) {
    display: none;
  }
}
.linkElement {
  text-decoration: none;
  color: $crayondarkgrey;
  text-transform: uppercase;
}
.navigationButton {
  background-image: url("assets/burger_menu_mobile.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 21px;
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 37px;
  z-index: 3;
  @media screen and (min-width: $tabletWidth) {
    background-image: url("assets/burger_menu.png");
    width: 56px;
    height: 46px;
    right: 40px;
    top: 36px;
  }
  @media screen and (min-width: $desktopWidth) {
    display: none;
  }
}

.listElement {
  list-style-type: none;
  text-align: center;
  margin: 0 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s;
  &::after {
    position: absolute;
    height: 10px;
    background-image: url("./assets/line.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    opacity: 0;
    transition: all 0.3s;
    bottom: 0;
    transform: translateY(10px);
    width: 70%;
    left: 15%;
  }
  &:hover::after {
    opacity: 1;
    transform: translateY(0px);
  }
  a {
    width: 150px;
  }
  div {
    width: 100%;
    height: 54px;
    margin: 16px auto 0;
    padding: 0;
  }
  &:nth-of-type(1) {
    &::after {
      width: 85%;
      left: 7.5%;
    }
  }
  &:nth-of-type(1),
  &:nth-of-type(2),
  &:nth-of-type(3) {
    width: calc((50% - 132px) / 3);
  }
  &:nth-of-type(4) {
    width: 264px;
    height: 80px;
    margin-top: 10px;
    & img {
      width: 264px;
      padding: 0;
      height: 80px;
    }
    div {
      margin-top: 0;
      width: 100%;
    }
    &::after {
      display: none;
    }
  }
  &:nth-of-type(5),
  &:nth-of-type(6),
  &:nth-of-type(7) {
    width: calc((50% - 132px) / 3);
  }
}

.currentPage::after {
  position: absolute;
  height: 10px;
  background-image: url("./assets/line.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  opacity: 1;
  transform: translateY(0px);
  width: 70%;
  left: 15%;
}

.navigationList {
  display: none;
  font-family: "Leftover Crayon", sans-serif;
  padding: 10px 0;
  @media screen and (min-width: $desktopWidth) {
    text-transform: uppercase;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    .closeButton {
      display: none;
    }
  }
  &.mobileNavigation {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 400px;
    height: 100%;
    z-index: 12 !important;
    margin-top: 0;
    box-sizing: border-box;
    background-image: url("./assets/card_menu.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20%;
    padding-left: 50px;
    .listElement {
      list-style-type: none;
      font-size: 22px;
      line-height: 24px;
      padding-left: 0;
      width: 80%;
      text-align: left;
      &:nth-of-type(4) {
        display: none;
      }
      &::after {
        left: 0 !important;
        width: 80% !important;
        max-width: 200px;
      }
    }
  }
}

.backLink {
  z-index: 9 !important;
  position: absolute;
  top: 36px !important;

  @media screen and (min-width: $tabletWidth) {
    top: 40px !important;
  }

  @media screen and (min-width: $desktopWidth) {
    display: none !important;
  }

  span {
    display: none;
  }
}

.closeNavigationButton {
  background-image: url("../../assets/close.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #fdfdf9;
  border: none;
  width: 24px;
  height: 23px;
  transform: scaleX(-1);
  padding: 0;
  position: absolute;
  right: 30px;
  top: 9%;
  z-index: 99;
}
