@import '../../../../variables.scss';

.quizCore {
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: $tabletWidth) {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 0 100px;
  }
}

.rightSide {
  flex: 0 0 30vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.quizAnswers {
  margin-top: 20px;

  @media screen and (min-width: $tabletWidth) {
    width: 25vw;
    margin: 0;
  }
}

.nextQuestionWrapper {
  width: 100%;
  text-align: right;
}

.nextQuestion {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 5vw;
  align-self: flex-end;
  background-image: url('./assets/next_button.png');
  max-width: 208px;
  max-height: 63px;
  color: $black;
  font-size: 1.3rem;
  line-height: 63px;
  outline: none;

  @media screen and (min-width: $tabletWidth) {
    background-image: url('./assets/desktop/next_button.png');
    width: 100%;
    max-width: unset;
    height: 75px;
    max-height: unset;
    line-height: 75px;
    box-sizing: border-box;
    background-size: 100% 100%;
    margin: 0 auto;
    font-size: 24px;
  }

  @media screen and (min-width: $desktopWidth) {
    line-height: 85px;
    height: 85px;
    font-size: 28px;
  }
}

.question {
  @media screen and (min-width: $tabletWidth) {
    transform: translateY(-40px);
    font-size: 18px;
    width: 27vw;
  }

  @media screen and (min-width: $desktopWidth) {
    font-size: 24px;
  }
}